import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import { geo } from '../../pages/Campaigns/const';

const Geos = ({ classes }) => {
  const { setFieldValue, values } = useContext(FormikContext);

  const handleGeo = (e) => {
    const { value, checked } = e.target;
    const geos = [...values.geos];
    if (checked) {
      if (geos.includes('ALL')) {
        return false;
      }
      return setFieldValue('geos', value !== 'ALL' ? [...geos, value] : [value]);
    }
    const index = geos.indexOf(value);
    if (index > -1) {
      geos.splice(index, 1);
    }
    return setFieldValue('geos', geos);
  };

  return (
    <>
      <Grid container className={classes.inputSection} item md={12}>
        <Typography variant="h5">Geographic Targeting</Typography>
      </Grid>
      <Grid container className={classes.inputSection} item md={12}>
        {geo.map((props, i) => (
          <Grid key={`${props.value}${i}`} container item md={3}>
            <FormControlLabel
              {...props}
              checked={values.geos.includes(props.value)}
              classes={{ label: classes.checkBoxLabel }}
              control={<Checkbox color="primary" />}
              onChange={handleGeo}
              labelPlacement="end"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Geos;
