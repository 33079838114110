import React, { useEffect, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import AddPodcast from '../../AddPodcast/AddPodcast';
import CompanyDetails from '../steps/CompanyDetails/CompanyDetails';
import Download from '../steps/Download';
import UserDetails from '../steps/UserDetails/UserDetails';
import { validationSchemaAddPodcast } from './validationSchemaAddPodcast';
import { initialValuesAddPodcast } from '../../AddPodcast/initialValues';
import { validationSchemaCompanyDetails } from '../steps/CompanyDetails/validationSchema';
import { initialValuesCompanyDetails } from '../steps/CompanyDetails/initialValues';
import { validationSchemaUserDetails } from '../steps/UserDetails/validationSchema';
import { initialValuesUserDetails } from '../steps/UserDetails/initialValues';
import { signUp } from '../../../api/auth';
import { registerFields } from './registerFields';
import { removeFeed } from '../../../redux/slices/rssSlice';
import BillingDetails from '../steps/BillingDetails';
import { initialValuesBillingDetails } from '../steps/BillingDetails/initialValuesBillingDetails';
import { validationSchemaBillingDetails } from '../steps/BillingDetails/validationSchemaBillingDetails';

const useStyles = makeStyles(() => ({
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
  noMaxWidth: {
    backgroundColor: '#2a2a2ae8',
    maxWidth: '55vw',
  },
}));

export default function RegisterForm({ sendDataToParent, parentStep }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const renderError = (text) => (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        modifiers: [{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: 'window',
          },
        }],
      }}
      placement="top-end"
      classes={{ tooltip: classes.noMaxWidth }}
      open={!!text}
      title={
        <Typography variant="caption" className={classes.error}>
          {text}
        </Typography>
      }
    >
      <div className="error-icon">!</div>
    </Tooltip>
  );
  return (
    <FormikStepper
      sendDataToParent={sendDataToParent}
      parentStep={parentStep}
      setLoading={setLoading}
      loading={loading}
    >
      <>
        <CompanyDetails renderError={renderError} />
      </>
      <>
        <UserDetails renderError={renderError} />
      </>
      <>
        <BillingDetails renderError={renderError} />
      </>
      <>
        <AddPodcast renderError={renderError} />
      </>
      <>
        <Download />
      </>
    </FormikStepper>
  );
}

function FormikStepper({ children, sendDataToParent, parentStep, loading, setLoading }) {
  const history = useHistory();
  const childrenArray = React.Children.toArray(children);
  const currentChild = childrenArray[parentStep];
  const [flag, setFlag] = useState(null);
  const queryParams = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...initialValuesUserDetails,
      ...initialValuesAddPodcast,
      ...initialValuesCompanyDetails,
      ...initialValuesBillingDetails,
    },
    validationSchema: validationSchemaAddPodcast
      .concat(validationSchemaCompanyDetails)
      .concat(validationSchemaUserDetails)
      .concat(validationSchemaBillingDetails),
    validateOnChange: false,
    onSubmit: () => {},
  });

  const handlePrevious = (event) => {
    event.preventDefault();
    sendDataToParent(parentStep - 1);
  };

  useEffect(
    () => () => {
      formik.resetForm();
      dispatch(removeFeed());
    },
    []
  );

  const sendData = () => {
    const formData = new FormData();
    if (queryParams.has('salesperson')) {
      formData.append('salesperson', queryParams.get('salesperson'));
    }
    Object.keys(formik.values).forEach((item) => {
      if (item === 'podcastFiles') {
        formik.values[item].forEach((file, i) => formData.append(`file${i}`, file));
        return;
      }
      formData.append(item, formik.values[item]);
    });
    return signUp(formData);
  };

  const handleNext = async () => {
    setLoading(true);
    const res = await formik.validateForm();

    if (!registerFields[parentStep].some((item) => res[item])) {
      if (parentStep === 3) {
        return sendData()
          .then(() => {
            setLoading(false);
            sendDataToParent(parentStep + 1);
          })
          .catch((err) => {
            setLoading(false);
            const errors = {};
            let step = null;
            if (err.response.data) {
              err.response.data.forEach((e) => {
                registerFields.forEach((item, i) => {
                  if (item.some((field) => e.param === field)) {
                    if (step === null || step > i) {
                      step = i;
                    }
                  }
                });
                errors[e.param] = e.msg;
              });
              formik.setErrors(errors);
              sendDataToParent(step);
            }
            console.error(err);
          });
      }
      setLoading(false);
      formik.setErrors({});
      return sendDataToParent(parentStep + 1);
    }
    setLoading(false);
    return null;
  };

  useEffect(() => {
    if (flag !== null) {
      handleNext();
    }
  }, [flag]);

  const handlerSkipPodcast = () => {
    formik.setFieldValue('skipPodcast', true).then(() => {
      setFlag(!flag);
    });
  };

  const handlerSubmite = () => {
    formik.setFieldValue('skipPodcast', false).then(() => {
      setFlag(!flag);
    });
  };

  const isNotFirstStep = parentStep <= 3 && parentStep > 0;

  return (
    <FormikProvider value={formik}>
      <Grid container item md={12} alignItems="center">
        <Button onClick={(e) => (isNotFirstStep ? handlePrevious(e) : history.push('/login'))}>
          <ArrowBackIcon style={{ color: '#fff' }} fontSize="small" />
          <Typography display="block" style={{ marginLeft: '10px' }} align="right">
            {isNotFirstStep ? 'Back' : 'Login'}
          </Typography>
        </Button>
      </Grid>
      <form onSubmit={formik.handleSubmit} autoComplete="off" className="register-form">
        <div className="register-form-container">{currentChild}</div>
        {parentStep < 4 && (
          <>
            <div className="register-form-buttons-row">
              <div className="register-form-buttons-block left">
                {parentStep <= 3 && parentStep > 0 && (
                  <button className="button register-form-button gray" type="button" onClick={handlePrevious}>
                    Previous
                  </button>
                )}
              </div>
              <div className="register-form-buttons-block right">
                {parentStep === 3 && (
                  <>
                    <button
                      className="button register-form-button gray"
                      type="button"
                      onClick={handlerSkipPodcast}
                    >
                      SKIP FOR NOW
                    </button>
                    <button
                      disabled={loading}
                      className="button register-form-button"
                      type="button"
                      onClick={handlerSubmite}
                    >
                      {loading ? <CircularProgress color="info" size={20} /> : 'Create Account'}
                    </button>
                  </>
                )}
                {parentStep < 3 && (
                  <button
                    disabled={loading}
                    className="button register-form-button"
                    type="button"
                    onClick={handleNext}
                  >
                    {loading ? <CircularProgress color="info" size={20} /> : 'Next'}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </FormikProvider>
  );
}
