import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    backgroundColor: '#0000003b',
    maxWidth: 'none',
  },
  wrapper: {
    marginBottom: 20,
    padding: 20,
    backgroundColor: '#1c1b1b',
    borderRadius: 18,
  },
  podcastForm: {
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px',
    },
  },
  btnSection: {
    padding: '0 0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 25px',
    },
  },
  adIds: {
    paddingBottom: 20,
  },
  button: {
    cursor: 'pointer',
    width: 150,
    height: 50,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 14,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  gray: {
    background: (props) => `url("${props.bgBtnGray}") no-repeat`,
  },
  label: {
    width: '100%',
    marginBottom: 15,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
    color: '#fff',
  },
  inputSection: {
    position: 'relative',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px',
    },
  },
  inputSectionGroupName: {
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  title: {
    fontSize: 26,
    lineHeight: '30px',
    fontWeight: '600',
    color: '#fff',
    marginBottom: 30,
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid #fff',
    },
    '&:after': {
      borderBottom: '1px solid #fff',
    },
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
    position: 'absolute',
    right: 20,
    bottom: 70,
    [theme.breakpoints.down('sm')]: {
      right: 0,
    },
  },
  errorIcon: {
    position: 'absolute',
    top: 52,
    right: 30,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      right: 10,
    },
  },
  budgetErrorIcon: {
    right: 10,
  },
  budgetError: {
    right: 0,
  },
  select: {
    color: '#000',
    width: '100%',
  },
}));
