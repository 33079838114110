import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { login } from '../../api/auth';
import { initialValues } from './initialValues';
import Logo from '../../assets/images/logo.svg';
import HSLogo from '../../assets/images/HS.png';

import { useStyles } from './styles';
import { isHS } from '../../utils/utils';
import { getLoginedUser, setLoginedUser } from '../../redux/slices/loginedUserSlice';

export default function LoginForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(getLoginedUser());
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      login(values)
        .then((res) => {
          dispatch(setLoginedUser(res.data.user));
        })
        .catch((e) => {
          console.error('error: ', e);
          setError(e.response.data.message);
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} autoComplete="off" className="login-form">
        <div className="login-form-row centered">
          <div className={cx(classes.blockLogo, 'login-form-block')}>
            <img src={Logo} alt="mowpod-logo" className="login-form-logo" />
            {isHS() && <img className={classes.hsLogo} src={HSLogo} alt="hs-logo" />}
          </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-block">
            <div className="login-form-title">
              Log In
            </div>
          </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-block">
            <label htmlFor="email">E-mail address:</label>
            <Field type="email" placeholder="email@example.com" name="email" className="login-form-input" required />
          </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-block">
            <label htmlFor="password">Password:</label>
            <NavLink to="/forgot-password" className="login-form-link forgot-password">Forgot password?</NavLink>
            <Field type="password" placeholder="•••••••••••••••" name="password" className="login-form-input" required />
          </div>
        </div>
        <div className="login-form-row">
          {error && (<Typography className={classes.error}>{error}</Typography>)}
        </div>
        <div className="login-form-row centered">
          <div className="login-form-block">
            <label className="login-form-checkbox">
              Keep me logged in
              <input type="checkbox" />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="login-form-row centered">
          <div className="login-form-block">
            <button className="button" type="submit">
              Log In
            </button>
          </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-block">
            <div className="not-registered">
              You are not registered?
              {' '}
              <br />
              <NavLink to="/register" className="login-form-link not-registered">Sign Up</NavLink>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
}
