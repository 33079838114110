import * as yup from 'yup';

const rePhoneNumber =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{3,4})(( x| ext)\d{1,5}){0,1}$/;
export const validationSchemaBillingDetails = yup.object().shape({
  billingEmail: yup.string().email('Value must be a valid email').required('Required'),
  emailForInvoices: yup.string().email('Value must be a valid email').required('Required'),
  billingPhone: yup
    .string()
    .required('Required')
    .matches(rePhoneNumber, 'Phone number is not valid'),
  billingName: yup.string().min(3, 'The name must contain at least 3 characters').required('Required'),
});
