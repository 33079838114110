import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {},
  card: {
    backgroundColor: '#1c1b1b',
    borderRadius: 18,
    padding: '20px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 25px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '40px 40px',
    },
    overflow: 'hidden'
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 24,
  },
  text: {
    fontWeight: 'bold',
  },
  fieldSection: {
    height: (props) => props.heightDataSection || 'auto',
    marginBottom: 25,
  },
  button: {
    width: 125,
    fontWeight: 'bold',
    color: '#fff',
  },
  setupLink: {
    color: 'red',

    '& img': {
      width: 15,
    },
  },
}));
