/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

export default function Content() {
  return (
    <>
    <div>
      <p>This mowMedia Partner Network Advertiser Agreement ("Advertiser
      Agreement") governs the relationship between mowMedia Partner Network ("mowMedia
      Partner Network" or "we") and the entity accepting these terms ("Advertiser"),
      whereby Advertiser may obtain access to registered third party partners
      ("Partners") and publishers ("Publishers"), and related technology and
      software ("Ad Server"), to market customized advertisements and links provided
      by Advertiser and/or mowMedia Partner Network ("Ads," as further defined below).
      The mowMedia Partner Network, as well as the services provided by mowMedia Partner
      Network in connection therewith (collectively "Services"), are further described
      in the Insertion Order ("IO") incorporated herein by reference (the IO, together
      with this Advertiser Agreement, the "Agreement"). The terms of the IO shall
      supersede all contrary terms set forth in this Advertiser Agreement, unless
      expressly set forth to the contrary. In any instance where Advertiser is an
      agency entering into the Agreement on behalf of a client, any reference to
      "Advertiser" shall refer jointly to Advertiser as well as the applicable
      underlying client.
      </p>

      <h4>mowMedia Partner Network/Services</h4>
      <p>In connection with the Services set forth in the
      Agreement, mowMedia Partner Network shall undertake marketing campaigns
      with Advertiser (each an "Ad Campaign") whereby mowMedia Partner Network
      will distribute Advertiser's proprietary advertising materials
      including, without limitation, banners, buttons, text-links, clicks,
      co-registrations, pop-ups, pop-unders, e-mail, graphic files and similar
      online media (collectively, "Advertiser Ads") and/or, where applicable,
      mowMedia Partner Network Ads (as defined below) through the mowMedia Partner
      Network either:
      </p>
      <ol><li><p>on Publisher websites via the Ad Server for
      impressions-based Ad Campaigns ("CPM"); or
              </p>
          </li>
        <li><p>by Partners via e-mail based marketing, search
        engine marketing, website based marketing and/or other online
        marketing means. In connection with such Ad Campaigns, Advertiser
        shall pay mowMedia Partner Network commissions depending on the number
        of valid clicks, impressions, sales/actions ("CPA"), applications
        and leads ("Leads"), and/or such other compensable activities
        generated on behalf of Advertiser as set forth in the subject IO
        (collectively, "Actions"). The applicable Actions, the fees due to
        mowMedia Partner Network for each Action and other applicable terms
        and conditions of the Ad Campaigns entered into hereunder shall be
        specified in each IO. mowMedia Partner Network shall not be held
        liable or responsible for any actions or inactions of its Publishers
        or Partners.
            </p>
        </li>
      </ol><h4>Account</h4>
      <p>Upon the execution of the Agreement, Advertiser must
      register on the mowMedia Partner Network website and create a unique,
      password-protected account ("Account"). Advertiser will be responsible
      for safeguarding and maintaining the confidentiality of its Account and
      associated password. Advertiser shall remain fully and solely
      responsibility for any and all actions taken under Advertiser's Account
      , whether authorized by Advertiser or not. Advertiser must immediately
      notify mowMedia Partner Network of any unauthorized use of Advertiser's
      Account. Advertiser is responsible for keeping its Account information
      current, complete and accurate, and Advertiser acknowledges and agrees
      that mowMedia Partner Network will have no responsibility or liability,
      directly or indirectly, for failure to deliver notices as a result of
      inaccurate Account information.
      </p>

      <h4>Ads</h4>
      <p>Unless stated otherwise in an IO, Advertiser shall
      develop all aspects of the Advertiser Ads, other than where the parties
      agree that mowMedia Partner Network shall assist in the development of Ads
      . The parties understand and agree that Advertiser is the sole owner of
      any and all intellectual property rights associated with any Advertiser
      Ads; other than those portions that mowMedia Partner Network prepares on
      Advertiser's behalf (such portions hereinafter referred to as, the
      "mowMedia Partner Network Ads" and together with the Advertiser Ads, the
      "Ads"). The parties understand and agree that mowMedia Partner Network is
      the sole owner of any and all intellectual property rights associated
      with the mowMedia Partner Network Ads, other than Advertiser's trademarks
      , logos, copyrights and other pre-existing Advertiser intellectual
      property incorporated in the mowMedia Partner Network Ads.
      </p>

      <p>Under no circumstances shall mowMedia Partner Network be
      authorized to use the Ads other than in connection with Advertiser's Ad
      Campaigns as set forth in the IO(s). Advertiser shall submit all
      Advertiser Ads to mowMedia Partner Network for approval prior to the
      commencement of the subject Ad Campaign set forth in the applicable IO.
      After the applicable Ad has been approved by mowMedia Partner Network,
      Advertiser shall not alter, modify or otherwise change the Ads, or any
      other Ads-related feature, in any manner whatsoever, without obtaining
      mowMedia Partner Network's prior express written consent. Notwithstanding
      the foregoing, mowMedia Partner Network shall have sole discretion with
      respect to the creation of the "subject" and "from" lines used in its
      e-mailing of any Ads.
      </p>

      <p>mowMedia Partner Network reserves the right, in its sole
      discretion and without liability, to:
      </p><ol><li><p>change any of its Ad Guidelines at any time;
      and
                  </p>
              </li>
        <li><p>reject, omit, exclude or terminate any Ad for
        any reason at any time, with subsequent notice to the Advertiser,
        whether or not such Ad was previously acknowledged, accepted or
        published by mowMedia Partner Network. Such reasons for rejection,
        omission or exclusion of Ads include, but are not limited to, mowMedia
        Partner Network's determination, in its sole discretion, that the
        Ads, including the applicable products and/or services promoted by
        such Ads ("Advertiser Products"), and any website linked to from
        such Ads, are in violation of any applicable law, rule, regulation
        or other judicial or administrative order or where the content
        thereof may tend to bring disparagement, ridicule or scorn upon mowMedia
        Partner Network or any of its Publishers and/or Partners.
            </p>
        </li>
          </ol><p>Advertiser reserves the right to reject, omit, exclude,
      terminate or request a change to the Ads at any time and mowMedia Partner
      Network shall, subject to the provisions set forth herein, comply with
      such request as soon as practical but in no event later than three (3)
      business days after its receipt thereof. Advertiser may cancel or
      suspend a CPM-based Ad Campaign, or an Ad associated with such a
      CPM-based Ad Campaign, effective within approximately twenty-four (24)
      business hours of mowMedia Partner Network's receipt of Advertiser's
      cancellation notice, which Advertiser can deliver by logging into its
      Account and following the instructions on the applicable menu.
               </p>

      <h4>Placement</h4>
      <p>The positioning, placement, frequency and other
      editorial decisions related to Ads shall be made by mowMedia Partner
      Network and/or its Partners and Publishers, as applicable, in their
      respective sole discretion. The applicable IO may set forth the
      particular place(s) where Ads may appear and/or be distributed.
      Advertiser agrees that in a case where no points of placement or
      distributions are set forth in the applicable IO or, in cases where
      "Run of Partner Network" or similar designation is specified in the
      applicable IO, the Ads may appear at any point of placement and/or
      distribution that mowMedia Partner Network and/or its Partners and
      Publishers may determine, in their respective sole discretion.
      </p>

      <h4>Ad Codes</h4>
      <p>Unless otherwise stated in writing by mowMedia Partner
      Network, each Ad used by mowMedia Partner Network in connection with an
      Ad Campaign must include, in unaltered form, the special transaction
      tracking computer code provided by mowMedia Partner Network ("Ad Codes").
      Advertiser will not knowingly modify, circumvent, impair, disable or
      otherwise interfere with any Ad Codes and/or other technology and/or
      methodology required or made available by mowMedia Partner Network to be
      used in connection with any and all Ads. All determinations made by mowMedia
      Partner Network in connection with the Ads, Actions and any associated
      fees invoiced to Advertiser shall be final and binding on Advertiser.
      Notwithstanding the foregoing, mowMedia Partner Network's Services do not
      involve investigating or resolving any claim or dispute involving
      Advertiser and any Publisher, Partner or other third party.
      </p>

      <h4>E-mail Marketing</h4>
      <p>The following terms apply to all Ad Campaigns
      transmitted via e-mail by mowMedia Partner Network's Partners on behalf
      of Advertiser. Advertiser will ensure that any and all e-mail based Ads:
      </p>
      <ol><li><p>shall comply with all applicable federal and
      state laws including, but not limited to, the CAN-SPAM Act of 2003
      ("CAN-SPAM") and any and all Federal Trade Commission implementing
      regulations;
              </p>
          </li>
        <li><p>must not infringe, misappropriate or otherwise
        violate any copyright, patent, trademark, trade secret or other
        similar intellectual property right, or otherwise violate or breach
        any duty toward, or rights of, any person or entity including,
        without limitation, rights of privacy and publicity; and
            </p>
        </li>
        <li><p>must not result in any consumer fraud, product
        liability or breach of contract to which Advertiser is a party or
        cause injury to any third party. Advertiser shall cause a valid
        physical postal address for Advertiser to appear in each e-mail Ad,
        along with a functioning unsubscribe link (such unsubscribe link
        must remain active for at least thirty (30) days after e-mail
        delivery).
            </p>
        </li>
      </ol><h4>Payments</h4>
      <p>The rates for Actions shall be set forth in the
      applicable IO(s). Unless otherwise set forth in the applicable IO, (i)
      mowMedia Partner Network will invoice Advertiser monthly, and (ii) payment
      will be due to mowMedia Partner Network within fourteen (14) days of the
      date appearing on each invoice. If payment is not made in a timely
      manner, mowMedia Partner Network may, at its option, immediately terminate
      the Agreement and/or any applicable IO(s). Interest will accrue on any
      past due amounts at the rate equal to the lesser of one and one half
      percent (1.5%) per year or the maximum amount permitted by law. In
      addition, Advertiser shall be liable to mowMedia Partner Network for all
      attorneys' fees and other costs of collection incurred in collecting
      such unpaid amounts. Advertiser agrees and acknowledges that it shall be
      fully responsible for any and all taxes, whether state or local, and
      related fees, costs and penalties incurred by mowMedia Partner Network
      and/or any of its Publishers or Partners.
      </p>

      <h4>Leads/CPA/Unaccepted Actions</h4>
      <p>In connection with Leads and CPA-based Ad Campaigns,
      Advertiser will pay mowMedia Partner Network for all Actions generated;
      provided, however, that Advertiser shall have no obligation to pay for
      any Lead/CPA-based Action that:
      </p>
      <ol><li><p>it rejects within five (5) days of its receipt
      thereof; and
              </p>
          </li><li><p>both parties determine is not a Valid Action
      (as defined below). Where mowMedia Partner Network determines that
      such Action is a Valid Action, Advertiser must pay for same. A
      "Valid Action" means an individual person that:
                   </p><ol><li><p>is not a computer generated user, such as a
          robot, spider, computer script or other automated, artificial or
          fraudulent method designed to appear like an individual, real
          live person;
                               </p>
                           </li>
            <li><p>in the case of CPA-based Campaign, is a
            valid sale that is not fraudulent, cancelled, charged back or
            otherwise nullified; and
                </p>
            </li>
            <li><p>in the case of Leads-based Campaigns, has
            submitted information that meets all of Advertiser's criteria
            as set forth in the applicable IO. The data associated with any
            and all Lead/CPA-based Actions ("Action Data") that are not both
            accepted and paid for by Advertiser shall be deemed the
            Confidential Information of mowMedia Partner Network, subject to
            any and all restrictions set forth herein ("Unaccepted Action
            Data"). Upon Advertiser's acceptance of a Lead/CPA-based Action
            (and payment to mowMedia Partner Network therefor in accordance
            with payment terms set forth herein and in the applicable IO),
            mowMedia Partner Network shall grant to Advertiser joint
            ownership and the full right to use such Action Data. Where
            Advertiser does not accept Leads/CPA-based Actions, where
            Advertiser fails to make payments for same in accordance with
            the payment terms herein and in the applicable IO and/or where
            such Leads/CPA-based Actions are later determined not to be
            Valid Actions, Advertiser shall have no rights in and to such
            Action Data, and such Action Data shall be considered and
            treated as Unaccepted Action Data.
                </p>
            </li>
                       </ol>
               </li>
      </ol><br /><p>Without limiting the generality of the confidentiality
      obligations set forth herein, Advertiser agrees that it:
                 </p><ol><li><p>will not transfer, export, display, forward or
      otherwise share information contained in the Unaccepted Action Data
      to/with any third party;
                             </p>
                         </li>
        <li><p>will not use the information contained in the
        Unaccepted Action Data on its own behalf in any manner not expressly
        authorized by mowMedia Partner Network;
            </p>
        </li>
        <li><p>will not use the information contained in the
        Unaccepted Action Data to create any derivative product;
            </p>
        </li>
        <li><p>will not publicly display the information
        contained in the Unaccepted Action Data on the Internet; and
            </p>
        </li>
        <li><p>will notify mowMedia Partner Network as soon as it
        learns of any actual or suspected unauthorized use of or access to
        the information contained in the Unaccepted Action Data and provide
        reasonable assistance to mowMedia Partner Network in the investigation
        and prosecution of any such unauthorized use or disclosure.
            </p>
        </li>
                     </ol><h4>Term/Termination</h4>
      <p>The Agreement shall continue until terminated.  Each IO
      shall continue for the term set forth the IO. Either party may terminate
      the Agreement and/or any IO at any time with five (5) business days'
      prior written notice. Upon termination or expiration of the Agreement
      for any reason:
      </p>
      <ol><li><p>Advertiser will pay mowMedia Partner Network all
      amounts then due and owing as of the termination date within thirty
      (30) days as set forth in Section 7 hereinabove;
              </p>
          </li>
        <li><p>any and all licenses and rights granted to
        either party in connection with the Agreement shall immediately
        cease and terminate; and
            </p>
        </li>
        <li><p>any and all Confidential Information or
        proprietary information of either party that is in the other
        party's possession or control must be immediately returned or
        destroyed.
            </p>
        </li>
      </ol><p>Notwithstanding any termination of the Agreement, any
      provisions of the Agreement that may reasonably be expected to survive
      termination of the Agreement, shall survive and remain in effect in
      accordance with their terms.
           </p>

      <h4>Warranty/Limitation of Liability</h4>
      <p>THE MOWMEDIA AFFILIATE NETWORK, SERVICES, MOWMEDIA AFFILIATE
      NETWORK ADS, ACTIONS AND AD CODES PROVIDED BY MOWMEDIA AFFILIATE NETWORK
      UNDER THE AGREEMENT AND/OR ANY APPLICABLE IO ARE SUPPLIED ON AN "AS IS"
      AND "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT OF THE LAW, MOWMEDIA
      AFFILIATE NETWORK MAKES NO WARRANTIES (INCLUDING IMPLIED WARRANTIES OF
      PURPOSE AND NON-INFRINGEMENT), GUARANTEES, REPRESENTATIONS, EXPRESS,
      IMPLIED, ORAL OR OTHERWISE. WITHOUT LIMITING THE GENERALITY OF THE
      FOREGOING, MOWMEDIA AFFILIATE NETWORK DOES NOT WARRANT OR GUARANTEE ACTIONS,
      CONVERSION RATES AND/OR RESPONSE RATES. THE MOWMEDIA AFFILIATE NETWORK,
      SERVICES, MOWMEDIA AFFILIATE NETWORK ADS, ACTIONS AND/OR AD CODES MAY
      CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. MOWMEDIA AFFILIATE
      NETWORK HAS NO LIABILITY, WHATSOEVER TO ADVERTISER OR ANY THIRD PARTY
      FOR ANY OTHER PARTY'S SECURITY METHODS AND PRIVACY PROTECTION
      PROCEDURES, AND MOWMEDIA AFFILIATE NETWORK DISCLAIMS ANY AND ALL WARRANTIES,
      EXPRESS AND IMPLIED, THAT ANY OTHER PARTY'S SECURITY METHODS AND
      PRIVACY PROTECTION PROCEDURES WILL BE UNINTERRUPTED OR ERROR-FREE.
      MOWMEDIA AFFILIATE NETWORK MAKES NO GUARANTEES, AND ACCEPTS NO RESULTING
      LIABILITY, FOR FAILURE TO MEET SCHEDULED DELIVERY DATES. IN NO EVENT
      SHALL MOWMEDIA AFFILIATE NETWORK BE RESPONSIBLE FOR ANY CONSEQUENTIAL,
      SPECIAL, PUNITIVE OR OTHER INDIRECT DAMAGES INCLUDING, WITHOUT
      LIMITATION, LOST REVENUE OR PROFITS, EVEN IF MOWMEDIA AFFILIATE NETWORK HAS
      BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MOWMEDIA AFFILIATE NETWORK
      WILL NOT BE LIABLE, OR CONSIDERED IN BREACH OF THE AGREEMENT, ON ACCOUNT
      OF A DELAY OR FAILURE TO PERFORM UNDER THE AGREEMENT AND/OR ANY IO AS A
      RESULT OF CAUSES OR CONDITIONS THAT ARE BEYOND MOWMEDIA AFFILIATE NETWORK'S
      CONTROL. NOTWITHSTANDING ANYTHING CONTAINED HEREIN TO THE CONTRARY, MOWMEDIA
      AFFILIATE NETWORK'S LIABILITY UNDER ANY CAUSE OF ACTION SHALL BE
      LIMITED TO THE AMOUNTS PAID TO MOWMEDIA AFFILIATE NETWORK BY ADVERTISER
      DURING THE PRIOR SIX (6) MONTH PERIOD PURSUANT TO THE AGREEMENT. MOWMEDIA
      AFFILIATE NETWORK SHALL NOT BE HELD LIABLE OR RESPONSIBLE FOR ANY
      ACTIONS OR INACTIONS OF PUBLISHERS AND/OR AFFILIATES.
      </p>

      <h4>Representation and Warranties</h4>
      <p>The parties agree to the terms in the General Data
      Protection Regulation Data Processing Addendum, which is incorporated
      into this Agreement.
      </p>
      <p>Advertiser represents and warrants that:</p>
      <ol><li><p>it has the power and authority to enter into and
      perform its obligations under the Agreement;
              </p>
          </li>
        <li><p>at all times, the Ads (and their transmission),
        the Advertiser Products, any Advertiser website linked to from the
        Ads (collectively, the “Advertiser Materials”) and Advertiser itself
        will comply with all applicable foreign, federal, state or local
        laws, rules, regulations and ordinances including, without
        limitation, the Gramm-Leach Bliley Act, the Fair Credit Reporting
        Act, the Federal Trade Commission Act, CAN-SPAM, the Telephone
        Consumer Protection Act, the Fair Debt Collection Practices Act, the
        Federal Communications Act, and all rules and regulations
        promulgated under any of the foregoing, as well as all applicable
        state laws including, without limitation, the California Financial
        Privacy Act and the Vermont Consumer Protection Act, and all rules
        and regulations promulgated under such state laws (collectively,
        "Laws");
            </p>
        </li>
        <li><p>it owns and/or has any and all rights to permit
        the use of the Advertiser Ads and, where approved, mowMedia Partner
        Network Ads, by mowMedia Partner Network, its Publishers and
        Partners, as contemplated by the Agreement;
            </p>
        </li>
        <li><p>at all times, the Advertiser Materials and
        Advertiser itself will not violate any applicable rights of any
        third party including, but not limited to, infringement or
        misappropriation of any copyright, patent, trademark, trade secret
        or other proprietary, property or other intellectual property
        right;
            </p>
        </li>
        <li><p>it will not disable "back" browser functionality
        to prohibit end-users from returning to the website from which the
        Ad was selected, if applicable;
            </p>
        </li>
        <li><p>Advertiser has a reasonable basis for any and
        all claims made within the Ads and possesses appropriate
        documentation to substantiate such claims;
            </p>
        </li>
        <li><p>for CPA and Leads Campaigns, the Ads, and/or the
        landing page from each Ad where an Action is completed (for example,
        Advertiser's website page where an end-user is directed when such
        end-user clicks on the Ad, fills in a registration form or takes a
        similar action in connection with the Ad) contains a prominent link
        to Advertiser's privacy policy, which policy provides, at a
        minimum, adequate notice, disclosure and choices to end users
        regarding Advertiser's use, collection and disclosure of their
        personal information;
            </p>
        </li>
        <li><p>Advertiser shall fulfill all commitments made in
        the Ads;
            </p>
        </li>
        <li><p>no Ad is targeted to end-users under the age of
        eighteen (18);
            </p>
        </li>
        <li><p>prior to loading any computer program onto an
        individual's computer including, without limitation, programs
        commonly referred to as adware and/or spyware, and cookies,
        Advertiser shall provide clear and conspicuous notice to, and shall
        obtain the express consent of, such individual to install such
        computer program and/or cookies;
            </p>
        </li>
        <li><p>the Ads, Advertiser Products, any Advertiser
        website linked to from the Ads do not and will not:
            </p><ol><li><p>contain any misrepresentations or content
          that is defamatory;
                        </p>
                    </li>
            <li><p>contain content that is violent, obscene,
            offensive, including content that contains nudity or implied
            nudity or content that is morally or ethically offensive or
            sexually suggestive;
                </p>
            </li>
            <li><p>promote or support gambling or sweepstakes
            or contests; or
                </p>
            </li>
            <li><p>contain any "worm," "virus" or other device
            that could impair or injure any person or entity;
                </p>
            </li>
                </ol>
        </li>
        <li><p>Advertiser is not, nor is Advertiser acting on
        behalf of any person or entity that is, prohibited from engaging in
        transactions with U.S. citizens, nationals or entities under
        applicable U.S. law and regulation including, but not limited to,
        regulations issued by the U.S. Office of Foreign Assets Control
        ("OFAC"); and
            </p>
        </li>
        <li><p>Advertiser is not, nor is Advertiser acting on
        behalf of any person or entity that is, a Specially Designated
        National ("SDN"), as OFAC may so designate from time to time.
            </p>
        </li>
      </ol><h4>Indemnification</h4>
      <p>Advertiser shall irrevocably defend, indemnify and hold
      mowMedia Partner Network, its Publishers, Partners and each of their
      respective employees, officers, directors, members, managers,
      shareholders, contractors and agents harmless from and against any and
      all liability, loss, damage or expense (including, without limitation,
      reasonable attorneys' fees, costs and expenses) arising out of or
      related to any allegation, claim or cause of action, involving:
      </p>
      <ol><li><p>Advertiser's breach of the Agreement, any and
      all applicable IO(s) or any representation or warranty contained
      therein;
              </p>
          </li>
        <li><p>the Ads, Advertiser Products and/or Advertiser
        websites; and/or
            </p>
        </li>
        <li><p>any claim that mowMedia Partner Network is
        obligated to pay any taxes in connection with Advertiser's
        participation hereunder.
            </p>
        </li>
      </ol><h4>Confidentiality</h4>
      <p>For purposes of the Agreement, "Confidential
      Information" shall mean all data and information, of a confidential
      nature or otherwise, disclosed during the term of the Agreement by one
      party ("Disclosing Party") to the other party ("Receiving Party"), as
      well as information that the Receiving Party knows or should know that
      the Disclosing Party regards as confidential including, but not limited
      to:
      </p>
      <ol><li><p>a party's business plans, strategies, know how,
      marketing plans, suppliers, sources of materials, finances, business
      relationships, personally identifiable end-user information, pricing
      , technology, employees, trade secrets and other non-public or
      proprietary information whether written, oral, recorded on tapes or
      in any other media or format;
              </p>
          </li>
        <li><p>the material terms of the Agreement and/or any
        associated IO(s);
            </p>
        </li>
        <li><p>with respect to mowMedia Partner Network, the
        Unaccepted Action Data and suppression lists; and
            </p>
        </li>
        <li><p>any information marked or designated by the
        Disclosing Party as confidential.
            </p>
        </li>
      </ol><br /><p>The Receiving Party agrees to hold all Confidential
      Information in trust and confidence and, except as may be authorized by
      the Disclosing Party in writing, shall not use such Confidential
      Information for any purpose other than as expressly set forth in the
      Agreement or disclose any Confidential Information to any person,
      company or entity, except to those of its employees and professional
      advisers:
                 </p>
      <ol><li><p>who need to know such information in order for
      the Receiving Party to perform its obligations hereunder; and
              </p>
          </li>
        <li><p>who have entered into a confidentiality
        agreement with the Receiving Party with terms at least as
        restrictive as those set forth herein.
            </p>
        </li>
      </ol><p>Confidential information shall not include any
      information that the Receiving Party can verify with substantial proof
      that:
           </p>
      <ol><li><p>is generally available to or known to the public
      through no wrongful act of the receiving party;
              </p>
          </li>
        <li><p>was independently developed by the Receiving
        Party without the use of Confidential Information; or
            </p>
        </li>
        <li><p>was disclosed to the Receiving Party by a third
        party legally in possession of such Confidential Information and
        under no obligation of confidentiality to the Disclosing Party.
            </p>
        </li>
      </ol><br /><p>The Receiving Party agrees that monetary damages for
      breach of confidentiality may not be adequate and that the disclosing
      party shall be further entitled to injunctive relief, without the
      requirement to post bond.
                 </p>

      <h4>Non-Circumvention</h4>
      <p>Advertiser recognizes that mowMedia Partner Network has
      proprietary relationships with its Publishers and Partners. Advertiser
      agrees not to circumvent mowMedia Partner Network's relationship with such
      Publishers and Partners, or to otherwise solicit, purchase, contract for or
      obtain services similar to the Services performed by mowMedia Partner Network
      hereunder from any Publisher and/or Partner that is known, or should
      reasonably be known, by Advertiser to have such a relationship with mowMedia
      Partner Network, during the term of the Agreement. Notwithstanding the
      foregoing, to the extent that Advertiser can show that any such Publishers and
      Partners already provided such services to Advertiser prior to the date of the
      first IO executed by the parties, then Advertiser shall not be prohibited from
      continuing such relationship. Advertiser agrees that monetary damages for its
      breach, or threatened breach, of this Section 14 will not be adequate and that
      mowMedia Partner Network shall be entitled to:
      </p>
      <ol><li><p>injunctive relief (including temporary and preliminary
      relief) without the requirement to post a bond; and
              </p>
          </li>
        <li><p>any and all other remedies available to mowMedia Partner
        Network at law or in equity.
            </p>
        </li>
      </ol><h4>Force Majeure</h4>
      <p>Other than with respect to payment obligations arising hereunder
      , neither party will be liable, or be considered to be in breach of this
      Agreement, on account of such party's delay or failure to perform as required
      under the terms of this Agreement as a result of any causes or conditions that
      are beyond such party's reasonable control and that such party is unable to
      overcome through the exercise of commercially reasonable diligence (a "Force
      Majeure Event"). If any such Force Majeure Event occurs including, without
      limitation, acts of God, fires, explosions, telecommunications, Internet or
      Partner Network failure, results of vandalism or computer hacking, storm or
      other natural occurrences, national emergencies, acts of terrorism,
      insurrections, riots, wars, strikes or other labor difficulties, or any act or
      omission of any other person or entity, the affected party will give the other
      party notice and will use commercially reasonable efforts to minimize the impact
      of any such event.
      </p>

      <h4>Miscellaneous</h4>
      <ol><li><p>Assignment. Advertiser may not assign, transfer or
      delegate any of its rights or obligations under the Agreement or any IO
      without the prior written consent of mowMedia Partner Network, and any
      attempts to do so shall be null and void; provided, however, that either
      party may assign the Agreement, any IO or any portion hereof/thereof, to:
              </p><ol><li><p>an acquirer of all or substantially all of such
        party's equity, business or assets;
                          </p>
                      </li>
          <li><p>a successor in interest whether by merger,
          reorganization or otherwise; or
              </p>
          </li>
          <li><p>any entity controlling or under common control with
          such party.
              </p>
          </li>
                  </ol>
          </li>
        <li><p>Choice of Law/Venue. The Agreement shall be construed in
        accordance with and governed by the laws of the State of Connecticut. In the
        event that any suit, action or other legal proceeding shall be instituted
        against either party in connection with the Agreement, each hereby submits
        to a court of competent jurisdiction located in Fairfield County, Connecticut, and
        further agrees to comply with all the requirements necessary to give such
        court jurisdiction.
            </p>
        </li>
        <li><p>Modification. The Agreement, any exhibits attached
        hereto and any and all applicable IO(s) represent the complete and entire
        expression of the agreement between the parties, and shall supersede any and
        all other agreements, whether written or oral, between the parties. The
        Agreement, any exhibits attached hereto and any and all applicable IO(s) may
        be amended only by a written agreement executed by an authorized
        representative of each party. To the extent that anything in or associated
        with any IO is in conflict or inconsistent with the Agreement, the IO shall
        take precedence. Notwithstanding the foregoing mowMedia Partner Network may
        modify any of the terms and conditions of this Agreement at any time by
        providing Advertiser with a notification by email. The changes will become
        effective ten (10) business days after such notice. If the modifications are
        unacceptable to Advertiser, Advertiser may terminate this Agreement without
        penalty within such ten (10) business day period. Advertiser's continued
        use if the Services ten (10) business days after a change notice has been
        posted will constitute Advertiser's acceptance of such change.
            </p>
        </li>
        <li><p>Non-Waiver/Severability. No waiver of any breach of any
        provision of the Agreement shall constitute a waiver of any prior,
        concurrent or subsequent breach of the same or any other provisions hereof,
        and no waiver shall be effective unless made in writing and signed by an
        authorized representative of the waiving party. If any provision contained
        in the Agreement is determined to be invalid, illegal or unenforceable in
        any respect under any applicable law, then such provision will be severed
        and replaced with a new provision that most closely reflects the real
        intention of the parties, and the remaining provisions of the Agreement will
        remain in full force and effect.
            </p>
        </li>
        <li><p>Relationship of the Parties. The parties hereto are
        independent contractors. There is no relationship of partnership, agency,
        employment, franchise or joint venture between the parties. Neither party
        has the authority to bind the other, or incur any obligation on its behalf;
        provided, however, that mowMedia Partner Network acts as a limited agent of
        Advertiser for the sole purpose of performing the Services set forth in
        applicable IO(s).
            </p>
        </li>
      </ol><p><br />IN WITNESS WHEREOF, mowMedia Partner Network and Advertiser
          have caused this Advertiser Agreement to be executed by their duly authorized
representatives.<br /><h4>GDPR Data Processing Addendum</h4>
        <p>This General Data Protection Regulation Data Processing Addendum
        ("GDPR Addendum") is incorporated by reference into the Partner Network
        Advertiser Agreement by and between You (“Advertiser”), and Us (“Network” or
        Processor”), (collectively, the "Agreement"). This GDPR Addendum is entered
        into as of the date of the Advertiser Program Operating Agreement.
        </p>

        <p>This GDPR Addendum sets out the terms that apply when Personal
        Data, as defined in the Data Protection Legislation, is processed by Network
        under the Agreement. The purpose of the GDPR Addendum is to ensure such
        processing is conducted in accordance with applicable laws, including EU Data
        Protection Legislation, and with due respect for the rights and freedoms of
        individuals whose Personal Data are processed.
        </p>

        <h4>DEFINITIONS</h4>
        <p>Capitalized terms used but not defined in this GDPR Addendum
        have the same meanings as set out in the Agreement.
        </p>
        <p><strong>Data Protection Legislation: </strong>
(i) unless and until the GDPR is no longer directly applicable in
the UK, the General Data Protection Regulation ((EU) 2016/679) and any national
implementing laws, regulations and secondary legislation, as amended or updated
from time to time, in the UK and then (ii) any successor legislation to the GDPR
or the Data Protection Act 1998.
        </p>

        <h4>Applicability</h4>
        <p>Applicability. This GDPR Addendum shall only apply to the extent
        Advertiser is established within the European Union (“EU”) or Switzerland or the
        United Kingdom and/or to the extent Network processes Personal Data of Data
        Subjects located in the EU or Switzerland or the United Kingdom on behalf of
        Advertiser.
        </p>

        <h4>Data Protection</h4>
        <p>Both parties will comply with all applicable requirements of the
        Data Protection Legislation. This Section 1 is in addition to, and does not
        relieve, remove or replace, a party's obligations under the Data Protection
        Legislation.
        </p>

        <p>The parties acknowledge that for the purposes of the Data
        Protection Legislation, the Advertiser is the data controller and Network is the
        data processor (where Data Controller and Data Processor have the meanings as
        defined in the Data Protection Legislation).
        </p>

        <p>Without prejudice to the generality of clause 1.1, the
        Advertiser, as Controller, shall be responsible for ensuring that, in connection
        with Advertiser Personal Data and the Services, (i) it has complied, and will
        continue to comply, with all applicable laws relating to privacy and data
        protection, including EU Data Protection Legislation; and (ii) it has, and will
        continue to have, the right to transfer, or provide access to, the Personal Data
        to Network for processing in accordance with the terms of the Agreement and this
GDPR Addendum.
        </p>

        <p>Without prejudice to the generality of clause 1.1, Network shall
        , in relation to any Personal Data processed in connection with the performance
        by Network of its obligations under this agreement:
        </p><ol><li><p>
          process that Personal Data only for the purposes set forth in
          the Agreement and Schedule 1 and only in accordance with the lawful,
          documented instructions of Advertiser, except where otherwise required by
          applicable law. Any processing required outside of the scope of these
          instructions (inclusive of the rights and obligations set forth under the
          Agreement) will require prior written agreement of the parties. Where
          Network is relying on laws of a member of the EU or EU law as the basis for
          processing Personal Data, Network shall promptly notify the Advertiser of
          this before performing the processing required by the Applicable Laws unless
          those Applicable Laws prohibit Network from so notifying the Advertiser;
                    </p>
                </li>
          <li><p>
            ensure that it has in place appropriate technical and
            organizational measures, available for review and approval by the Advertiser
            , to protect against unauthorized or unlawful processing of Personal Data
            and against accidental loss or destruction of, or damage to, Personal Data,
            appropriate to the harm that might result from the unauthorized or unlawful
            processing or accidental loss, destruction or damage and the nature of the
            data to be protected, having regard to the state of technological
            development and the cost of implementing any measures (those measures may
            include, where appropriate, pseudonymising and encrypting Personal Data,
            ensuring confidentiality, integrity, availability and resilience of its
            systems and services, ensuring that availability of and access to Personal
            Data can be restored in a timely manner after an incident, and regularly
            assessing and evaluating the effectiveness of the technical and
            organizational measures adopted by it);
              </p>
          </li>
          <li><p>
            ensure that all personnel who have access to and/or process
            Personal Data are obliged to keep the Personal Data confidential; and
            Network complies with its obligations under the Data Protection Legislation
            by providing an adequate level of protection to any Personal Data that is
            transferred;
              </p>
          </li>
          <li><p>
            assist the Advertiser, at the Advertiser's cost, in responding
            to any request from a Data Subject and in ensuring compliance with its
            obligations under the Data Protection Legislation with respect to security,
            breach notifications, impact assessments and consultations with supervisory
            authorities or regulators. For the avoidance of doubt, Advertiser is
            responsible for responding to Data Subject request for access, correction,
            restriction, objection, erasure or data portability of that Data Subject's
            Personal Data;
              </p>
          </li>
          <li><p>
            notify the Advertiser without undue delay on becoming aware of
            a Personal Data breach;
              </p>
          </li>
          <li><p>
            upon termination or expiration of the Agreement, in accordance
            with the terms of the Agreement and within a reasonable amount of time,
            delete or make available to Advertiser for retrieval all relevant Personal
            Data in Network's possession; except to the extent that Network is required
            by any applicable law to retain some or all of such data. Network shall
            extend the protections of the Agreement and this GDPR Addendum to any such
            Personal Data and limit any further processing of such Personal Data to only
            those limited purposes that require the retention; and
              </p>
          </li>
          <li><p>
            maintain complete and accurate records and information to
            demonstrate its compliance with this Section 2.4.
              </p>
          </li>
            </ol><p>The Advertiser consents to Network appointing third-party
        processors of Personal Data under this agreement, including TUNE
        (“Sub-processors”).  Network confirms that it has entered or (as the case may
        be) will enter with the third-party processor into a written agreement
        substantially similar to those set out in this Agreement. As between the
        Advertiser and Network, Network shall remain fully liable for all acts or
        omissions of any Sub-processor appointed by it pursuant to this Section 2.5.
                 </p>
        <p>Network may, at any time on not less than 30 days' notice with
        email sufficing, add or make changes to the Sub-processors. Advertiser may
        object in writing to Network's appointment of a new Sub-processor within five
        (5) business days of such notice, provided that such objection is based on
        reasonable grounds relating to data protection. In such event, the parties will
        discuss such concerns in good faith with a view to achieving resolution. If
        Network cannot provide an alternative Sub-processor, or the parties are not
        otherwise able to achieve resolution as provided in the preceding sentence,
        Advertiser, as its sole and exclusive remedy, may terminate the Agreement.
        </p>

        <h4>Miscellaneous</h4>
        <p>Except as stated in this GDPR Addendum, the Agreement will
        remain in full force and effect. If there is a conflict between the Agreement
        and this GDPR Addendum, the terms of this GDPR Addendum will control.
        </p>
        <p>Any claims brought under this GDPR Addendum shall be subject to
        the terms and conditions, including by not limited to, the exclusion and
        limitations set forth in the Agreement.
        </p>

        <h4>Schedule 1  Processing, Personal Data and Data Subjects</h4>
        <p>
          <strong>Details of Data Processing</strong>
        </p>
        <ol><li><p>
          <strong><span style={{ textDecoration: 'underline' }}>
            Subject Matter:
                  </span>
          </strong>
The subject matter of the data processing under this GDPR
Addendum is the Advertiser Personal Data.
                </p>
            </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Duration:
                    </span>
            </strong>
As between Network and Advertiser, the duration of the data
processing under this GDPR Addendum is until the termination of the
Agreement in accordance with its terms.
              </p>
          </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Purpose:
                    </span>
            </strong>
The purpose of the data processing under this GDPR Addendum is
the provision of the Services to the Advertiser and the performance of
Network's obligations under the Agreement (including this GDPR Addendum) or
as otherwise agreed by the parties in mutually executed written form.
              </p>
          </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Nature of the processing:
                    </span>
            </strong>
Network provides performance marketing solutions and such other
Services as described in the Agreement, which process Advertiser Personal
Data upon the instruction of the Advertiser in accordance with the terms of
the Agreement.
              </p>
          </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Categories of data subjects:
                    </span>
            </strong>
Advertiser may submit Advertiser Personal Data to the Services,
the extent of which is determined and controlled by Advertiser in its sole
discretion, and which may include, but is not limited to, Personal Data
relating to the following categories of data subjects:
              </p><ol><li><p>
              Employees, agents, advisors, freelancers of Advertiser
              (who are natural persons); and/or
                          </p>
                      </li>
              <li><p>
                Advertiser's end-users authorized by Advertiser to use
                the Services.
                  </p>
              </li>
                  </ol>
          </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Types of Personal Data:
                    </span>
            </strong>
Advertiser may submit Advertiser Personal Data to the Services,
the extent of which is determined and controlled by Advertiser in its sole
discretion, and which may include, but is not limited to identification and
contact data; financial information; and/or certain information about
Advertiser's end users (such as IP address and device identifier).
              </p>
          </li>
          <li><p>
            <strong><span style={{ textDecoration: 'underline' }}>
              Sensitive Personal Data (if applicable):
                    </span>
            </strong>
Advertiser shall not send Network any Sensitive Personal Data
(as defined in the Data Protection Legislation).
              </p>
          </li>
        </ol>
           </p>
    </div>
    </>
  );
}
