/* eslint-disable prefer-template */
import React, { useContext, useState } from 'react';
import { Field, FormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectRssFeed } from '../../redux/slices/rssSlice';
import FieldError from '../FieldError';

const Episodes = ({ classes, setImageUrl }) => {
  const { setFieldValue, validateField, errors } = useContext(FormikContext);
  const { type } = useParams();
  const { feed } = useSelector(selectRssFeed);
  const [selectedEpisode, setSelectedEpisode] = useState('');

  const handleEpisode = (e) => {
    const { value: i } = e.target;
    setSelectedEpisode(i);
    const episode = { ...feed.items[i] };
    episode.itunes = { ...episode.itunes, image: episode.itunes.image || feed.image.url };
    setFieldValue('episode', episode);
    setFieldValue('title', episode.title || '');
    setFieldValue(
      'subtitle',
      episode.itunes.subtitle || episode.itunes.summary || episode.contentSnippet || ''
    );
    setImageUrl(episode.itunes.image);
  };

  return (
    !type && (
      <>
        <Typography variant="h5">Episodes</Typography>
        <Grid container item md={12} className={classes.inputSection}>
          <Field
            as="select"
            name="episode"
            value={selectedEpisode}
            className="register-form-input register-form-select"
            onBlur={() => validateField('episode')}
            onChange={handleEpisode}
          >
            <option value="" disabled>
              Choose from episode
            </option>
            {feed?.items?.map((item, i) => {
              let season = '';
              let episode = '';
              if (item.itunes.season) {
                season = 'S' + item.itunes.season;
              }
              if (item.itunes.episode) {
                episode = 'E' + item.itunes.episode;
              }
              const option = season.concat(' ', episode, ' ', item.title);
              return (
                <option key={i} value={i}>
                  {option}
                </option>
              );
            })}
          </Field>
          {errors.episode && <FieldError text={errors.episode} withPosition />}
        </Grid>
      </>
    )
  );
};

export default Episodes;
