import React, { useEffect, useState } from 'react';
import JotformEmbed from 'react-jotform-embed';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { usePodcasts } from '../contexts/PodcastsContext';
import { prepareJotformUrl } from '../utils/utils';
import { selectUsers } from '../redux/slices/usersSlice';

const fieldMapping = {
  email: 'email',
  name: ['userDetails.firstName', 'userDetails.lastName'],
  podcastName: 'podcastName',
  rssFeed: 'podcastUrl',
  totalBudget: 'podcastBudget',
  company: 'companyDetails.company',
  boost: (data) => data.pricing?.boost || 0.8,
  applesub: (data) => data.pricing?.appleSub || 5,
  youTube: (data) => data.pricing?.youTube || 0.1,
  boostPro: (data) => data.pricing?.boostPro || 2,
  coreg: (data) => data.pricing?.newsletter || 0.6,
  salesperson: (data) => data.salesperson || '',
};

export const AddCampaign = () => {
  const [data, setData] = useState();
  const { pickedUser } = useSelector(selectUsers);
  const { setShow } = usePodcasts();
  const { podcastId } = useParams();

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
      if (pickedUser) {
        const currPodcast = pickedUser.podcasts.find((item) => item._id === podcastId);
        setData({ ...pickedUser, ...currPodcast });
      }
  }, [pickedUser]);
  return (
    <>
      <Grid container item md={12}>
        {data && <JotformEmbed src={prepareJotformUrl('230597112810147', fieldMapping, data)} />}
      </Grid>
    </>
  );
};
