import * as yup from 'yup';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

export const validationSchema = yup.object().shape({
  email: yup.string().email('Value must be a valid email').required('Required'),
  firstName: yup.string()
    .matches(/^[aA-zZ\s]+$/, 'First Name must have only letters')
    .required('Required'),
  lastName: yup.string().required('Required'),
  title: yup.string(),
  salesperson: yup.string(),
  password: yup.string()
    .min(8, 'Must Contain 8 Characters')
    .max(32)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-@$!%*#?&])[A-Za-z\d-@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .comparePassToEmail(yup.ref('email'), 'Password should not contain part of your email'),
  confirmPassword: yup.string().equalTo(yup.ref('password'), 'Passwords do not match'),
});
