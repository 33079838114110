import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: (props) => `url("${props.scratches}")`,
    backgroundColor: '#343434',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
  },
  main: {
    position: 'relative',
    zIndex: 2,
    color: '#fff',
    width: '80%',
    minWidth: '300px',
    maxWidth: '1140px',
    margin: '25px auto',

    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
  },
  logo: {
    maxWidth: 200,
    width: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 30,
  },
  rainbow: {
    position: 'absolute',
    width: '100%',
    top: '30%',
  },
  airpod: {
    position: 'absolute',
    width: 150,
    zIndex: 1,
  },
  airpodLeft: {
    right: '5%',
    top: '20%',
  },
  airpodRight: {
    left: '5%',
    top: '60%',
  },
  fieldset: {
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    borderRadius: 15,
    padding: '10px 55px',
    margin: '20px 0',
    border: 0,

    [theme.breakpoints.down('md')]: {
      padding: '15px 25px',
    },
  },
  legendLabel: {
    color: '#f59a00',
    fontWeight: 600,
    fontSize: 22,
    paddingBottom: 5,
    width: '100%',
  },
  note: {
    fontStyle: 'italic',
    paddingLeft: 20,
  },
  inputWrapper: {
    padding: '10px 0',
  },
  textInput: {
    border: '1px solid rgba(250, 250, 250, 0.8)',
    borderRadius: 10,
    backgroundColor: 'rgba(37, 37, 37, 0.5)',
    width: '100%',
    height: 60,
    paddingLeft: 25,
    paddingRight: 58,
    lineHeight: 20,
    resize: 'none',
    margin: '10px 0',
    color: '#fff',
    fontSize: 20,
    '&::placeholder': {
      fontSize: 22,
      fontWeight: 400,
      fontStyle: 'italic',
    },
  },
  textArea: {
    color: '#fff',
    backgroundColor: 'rgba(37, 37, 37, 0.5)',
    borderRadius: '10px',
    border: '1px solid rgba(250, 250, 250, 0.8)',
    padding: '10px 25px',
    width: '100%',
    height: 'auto',
    resize: 'vertical',
    marginBottom: 5,
    '&::placeholder': {
      fontSize: 18,
      fontWeight: '400',
      fontStyle: 'italic',
      fontFamily: '"Montserrat", sans-serif',
    },
  },
  alertsBorder: {
    border: '1px solid',
    animation: '$blink 1s',
    animationIterationCount: 5,
    borderRadius: 15,
  },
  errMsg: {
    width: '100%',
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'end',
    color: '#ff0000',
  },
  '@keyframes blink': {
    '50%': {
      borderColor: '#ff0000',
    },
  },

  selectWrapper: {
    width: '100%',
    color: '#fff',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    borderRadius: 10,
    backgroundColor: '#222',
    '& .MuiSelect-icon': {
      scale: 2,
      transform: 'translateX(-5px)',
    },
  },
  select: {
    color: '#fff',
    backgroundColor: '#333',
    borderRadius: 10,
    border: '1px solid rgba(255, 255, 255, 0.5)',
    '&:focus': {
      backgroundColor: '#333',
    },
  },
  menuItem: {
    color: '#fff',
    backgroundColor: '#333',
    '&:hover': {
      backgroundColor: '#555',
    },
  },

  button: {
    cursor: 'pointer',
    width: 170,
    height: 70,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 10,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 18,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  footer: {
    margin: 20,
    '& p': {
      fontSize: 14,
    },
  },
}));
