import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
  },
  dataSection: {
    paddingLeft: 15,
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px',
      marginBottom: 20,
    },
  },
}));
