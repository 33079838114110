import * as yup from 'yup';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

export const validationSchema = yup.object().shape({
  episode: yup.mixed().when('showEpisodeSelect', {
    is: true,
    then: yup.mixed().required('Select an episode'),
    otherwise: yup.mixed().notRequired(),
  }),
  title: yup
    .string()
    .when('type', (type, schema) => {
      if (type) return schema.max(40, 'Title must be at most 40 characters');
      return schema.max(46, 'Title must be at most 46 characters');
    })
    .required('Title is required'),
  subtitle: yup
    .string()
    .min(5, 'The subtitle must contain at least 5 characters')
    .when('type', (type, schema) => {
      if (type) return schema.max(82, 'Subtitle must be at most 82 characters');
      return schema.max(120, 'Subtitle must be at most 120 characters');
    })
    .required('Subtitle is required'),
  files: yup.array().when('creatingImagesWay', {
    is: 'generate',
    then: yup.array(),
    otherwise: yup.array().min(3, 'At least 3 images should be selected'),
  }),
  customUrl: yup.string().when(['enterpriseBoost', 'autoGenerateLandingPage'], {
    is: false,
    then: yup.string().url('Custom URL must be a valid URL').required('Custom URL is required'),
    otherwise: yup.string(),
  }),
});
