import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  errorIcon: {
    position: 'absolute',
    top: 13,
    right: 10,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
    position: 'absolute',
    marginBottom: 3,
    right: 0,
    bottom: '100%',
    [theme.breakpoints.down('sm')]: {
      right: 0
    },
  },
  withPositionText: {
    right: 26,
    [theme.breakpoints.down('sm')]: {
      right: 0
    },
  },
  withPositionIcon: {
    right: 30,
    [theme.breakpoints.down('sm')]: {
      right: 15
    },
  },
  noMaxWidth: {
    backgroundColor: '#0000003b',
    maxWidth: 'none',
  },
}));
