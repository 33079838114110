import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/onboarding/logo-thank-you.svg';
import airpodRight from '../../assets/images/onboarding/airpod-right.png';
import cassette from '../../assets/images/onboarding/casette.png';
import raindbowRight from '../../assets/images/onboarding/rainbow-right.png';
import bgBtn from '../../assets/images/button.png';
import { useStyles } from './styles';

const ThankYou = () => {
  const classes = useStyles({ raindbowRight, bgBtn });
  return (
    <>
      <div className={classes.rainbow} />
      <img className={classes.casette} src={cassette} alt="" />
      <main className={classes.thankYou}>
        <img src={logo} alt="" className={classes.logo} />
        <Typography variant="h3" className={classes.title}>
          THANK YOU FOR COMPLETING ONBOARDING!
        </Typography>
        <Grid container className={classes.thankYouContent} justifyContent="center">
          <img className={classes.airpodRight} src={airpodRight} alt="" />
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" className={classes.text}>
              WE WILL NOW PROCESS YOUR REQUEST.
            </Typography>
            <Button variant="contained" type="submit" className={classes.button}>
              <NavLink to="/users" exact>
                BACK
              </NavLink>
            </Button>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default ThankYou;
