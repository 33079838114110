import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api/users';

export const getUsers = createAsyncThunk('get/users', async (params) => api.getUsers(params));
export const getUser = createAsyncThunk('get/users/id', async (id) => api.getUserById(id));

const usersSlice = createSlice({
  name: 'users',
  initialState: { data: [], error: null, pickedUser: null, loading: false },
  reducers: {
    setUser: (state, action) => {
      state.pickedUser = action.payload;
    },
    removeUser: (state) => {
      state.pickedUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.pickedUser = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});
export const selectUsers = (state) => state.users;
export const { setUser, removeUser } = usersSlice.actions;
export default usersSlice.reducer;
