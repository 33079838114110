import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  InputBase,
  TableSortLabel,
  Button,
  CircularProgress,
} from '@mui/material';
import cx from 'classnames';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { removeUser, selectUsers } from '../../redux/slices/usersSlice';
import { usePodcasts } from '../../contexts/PodcastsContext';
import '../style.css';
import { StyledTableCell, StyledTableRow, useStyles } from './styles';
import { TablePaginationActions } from '../../components/TablePaginationActions/TablePaginationActions';
import { useGettingData } from '../../hooks/useGettingData';
import { containsSearchValue, getComparator, stableSort } from '../../utils/table';

const headCells = [
  {
    id: 'hasActiveCampaigns',
    numeric: false,
    disablePadding: true,
    label: 'Active Campaigns',
    sortable: true,
  },
  {
    id: 'companyDetails.company',
    numeric: false,
    disablePadding: true,
    label: 'Company',
    sortable: true,
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
  {
    id: 'userDetails.firstName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
  },
  { id: 'totalPodcasts', numeric: true, disablePadding: false, label: 'Podcasts', sortable: true },
  { id: 'edit', numeric: true, disablePadding: false, label: 'Edit', sortable: false },
];

export default function Users() {
  const { data: users, loading } = useSelector(selectUsers);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [cachedUsers, setCachedUsers] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('hasActiveCampaigns');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { setPodcasts, setShow } = usePodcasts();
  const { handleGettingUsers } = useGettingData();

  useEffect(() => {
    setPodcasts([]);
    setShow(false);
    if (!users.length) return handleGettingUsers();
    setCachedUsers(() => users);
  }, [users]);

  const searchCampaignGroups = () => {
    const normalizedSearchValue = search.toLowerCase();
    const fields = [
      'email',
      'userDetails.firstName',
      'userDetails.lastName',
      'userDetails.title',
      'companyDetails.company',
    ];
    return users.filter((item) => containsSearchValue(item, fields, normalizedSearchValue));
  };

  useEffect(() => {
    const newUsers = search ? searchCampaignGroups() : users;
    setCachedUsers(newUsers);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const keyPress = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (e.keyCode === 27) {
      setSearch('');
    }
  };

  const goToUser = (user) => {
    dispatch(removeUser());
    history.push(`/users/${user._id}`);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(cachedUsers, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, cachedUsers]
  );

  return (
    <Grid className={classes.wrapper} container item md={12} justifyContent="center">
      <Grid className={classes.title} container direction="row" item md={12}>
        <Grid className={classes.title} container direction="row" item md={6}>
          <Typography variant="h4">Users</Typography>
        </Grid>
        <Grid className={classes.title} container item md={6} justifyContent="flex-end">
          <InputBase
            className={classes.input}
            placeholder="Search Users"
            value={search}
            onKeyDown={keyPress}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{ 'aria-label': 'search users' }}
          />
        </Grid>
      </Grid>
      <TableContainer className={classes.tTableContainer} component={Paper}>
        <Table className={classes.tTable}>
          <TableHead className={classes.tHead}>
            <TableRow style={{ border: 'none' }}>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  // padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  className={classes.tHeader}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                      classes={{ active: classes.tHeader }}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              <>
                {visibleRows.map((user) => (
                  <StyledTableRow key={user.email}>
                    <StyledTableCell component="td" scope="row" className={classes.tFirstItem}>
                      <div
                        className={cx(classes.marcking, {
                          [classes.redDot]: user.hasActiveCampaigns,
                        })}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row" className={classes.tBodyItem}>
                      <Button className={classes.link} onClick={() => goToUser(user)}>
                        {user.companyDetails.company}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row" className={classes.tBodyItem}>
                      <Button
                        className={cx(classes.link, classes.email)}
                        onClick={() => goToUser(user)}
                      >
                        {user.email}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell className={classes.tBodyItem}>
                      <Button className={classes.link} onClick={() => goToUser(user)}>
                        {`${user.userDetails.firstName}  ${user.userDetails.lastName}`}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right" className={classes.tBodyItem}>
                      {user.totalPodcasts}
                    </StyledTableCell>
                    <StyledTableCell align="right" className={classes.tBodyItem}>
                      <IconButton
                        onClick={() => history.push(`/users/${user._id}/info/edit`)}
                        className={classes.margin}
                        size="small"
                        style={{ color: '#fff' }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {!users.length && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6} className={classes.tHeader} align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={3}
                count={cachedUsers.length}
                className={classes.tTablePagination}
                rowsPerPage={rowsPerPage}
                page={page}
                classes={{
                  toolbar: classes.marginBottom,
                }}
                slotProps={{
                  select: {
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                  }
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}
