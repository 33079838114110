import { Grid, Typography } from '@mui/material';
import React from 'react';
import cx from 'classnames';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import impSvg from '../../assets/images/impessions.svg';
import clkSvg from '../../assets/images/clicks.svg';
import dwnSvg from '../../assets/images/downloads.svg';
import dwnSectionSvg from '../../assets/images/downloads.png';
import { selectStats } from '../../redux/slices/statsSlice';

import { useStyles } from './styles';

export default function Chart() {
  const {
    totalStats: { impressions: imp = 0, clicks = 0, clickConversions: conv_click = 0 } = {},
    retargetingStats: { clickConversions: targetDwnlds = 0 } = {},
    sidecar,
  } = useSelector(selectStats);
  const classes = useStyles({
    impSvg,
    clkSvg,
    dwnSvg: targetDwnlds ? dwnSectionSvg : dwnSvg,
  });
  return (
    <Grid
      container
      item
      md={12}
      justifyContent="space-around"
      alignItems="flex-end"
    >
      {!sidecar && (
        <Grid
          className={cx(classes.impChartSection, classes.chartSection)}
          container
          direction="column"
          item
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography className={cx(classes.chartData, classes.chartDataImp)}>
            <CountUp end={imp} duration={2} separator="," />
          </Typography>
          <Typography className={classes.chartData} paragraph>
            Impressions
          </Typography>
        </Grid>
      )}
      <Grid
        className={cx(classes.clkChartSection, classes.chartSection)}
        container
        direction="column"
        item
        xs={4}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography className={cx(classes.chartData, classes.chartDataClk)}>
          <CountUp end={sidecar ? imp : clicks} duration={2} separator="," />
        </Typography>
        <Typography className={classes.chartData} paragraph>
          {sidecar ? 'Impressions' : 'Plays'}
        </Typography>
      </Grid>
      <Grid
        className={cx(classes.dwnldChartSection, classes.chartSection)}
        container
        direction="column"
        item
        xs={4}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid
          className={cx(classes.chartData, classes.targetDwnlds)}
          container
          justifyContent="center"
          alignItems="center"
        >
          {!!targetDwnlds && (
            <>
              <CountUp
                className={classes.repeatListener}
                end={targetDwnlds}
                duration={1}
                separator=","
              />
              <Typography className={classes.repeatListener}>&nbsp;repeat</Typography>
              <Typography className={classes.repeatListener}>&nbsp;listeners</Typography>
            </>
          )}
        </Grid>
        <Typography className={cx(classes.chartData, classes.chartDataDwn)}>
          <CountUp end={sidecar ? clicks : conv_click} duration={2} separator="," />
        </Typography>
        {!sidecar && (
          <>
            <Typography className={classes.chartData}>Total</Typography>
            <Typography className={classes.chartData}>IAB 2.0</Typography>
          </>
        )}

        <Typography
          className={classes.chartData}
          style={{ paddingTop: sidecar ? 35 : 0 }}
          paragraph
        >
          {sidecar ? 'Clicks' : 'Downloads'}
        </Typography>
      </Grid>
    </Grid>
  );
}
