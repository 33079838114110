import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CachedIcon from '@mui/icons-material/Cached';
import { selectSuggestion, setCompprestTextReload } from '../../redux/slices/suggestionSlice';

import { useStyles } from './styles';
import { useGettingData } from '../../hooks/useGettingData';
import { removeHTMLFromString } from '../../utils/utils';

export default function CompressText({ text, handleOption, originalText, type, sidecar }) {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState();
  const [options, setOptions] = useState([]);
  const { handleGettingSuggestions } = useGettingData();
  const { titles, subtitles, loading, error } = useSelector(selectSuggestion);
  const [loadingTitle, setLoadingTitle] = useState(false);
  const [loadingSubtitle, setLoadingSubtitle] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'title' && titles) {
      setOptions(() => [...titles]);
    }
    if (type === 'subtitle' && subtitles) {
      setOptions(() => [...subtitles]);
    }
  }, [titles, subtitles]);

  useEffect(() => {
    if (error) {
      setOptions([]);
    }
  }, [error]);

  useEffect(() => {
    setLoadingTitle(false);
    setLoadingSubtitle(false);
  }, [error, titles, subtitles]);

  const setOption = (i) => {
    handleOption(options[i]);
    setActiveButton(i + 1);
  };

  const setOriginalText = () => {
    handleOption(removeHTMLFromString(originalText || text || ''));
    setActiveButton(0);
  };

  const handleReload = (type) => {
    if (!text) return;

    if (type === 'title') {
      dispatch(setCompprestTextReload('title'));
      setLoadingTitle(true);
      setActiveButton(0);
    } else {
      dispatch(setCompprestTextReload());
      setLoadingSubtitle(true);
      setActiveButton(0);
    }

    handleGettingSuggestions({ searchQuery: text, type: sidecar });
  };

  const loadingByType = type === 'title' ? loadingTitle : loadingSubtitle;

  return (
    <>
      {loadingByType && (
        <IconButton
          onClick={handleReload}
          disabled={loadingByType}
          classes={{ label: classes.origBtnLabel }}
          className={classes.spinner}
          size="large"
        >
          <CircularProgress size={20} />
        </IconButton>
      )}
      <Button
        variant="contained"
        className={cx(classes.origBtn, classes.btn, { [classes.active]: activeButton === 0 })}
        disabled={loading}
        classes={{
          label: classes.origBtnLabel,
          contained: cx(classes.btn),
          disabled: classes.disabled,
        }}
        onClick={setOriginalText}
      >
        Original
      </Button>
      <Grid>
        <Box component="div">
          <IconButton
            size="small"
            disabled={loading || !text || !options[0]}
            onClick={() => setOption(0)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <LooksOneIcon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 1,
                [classes.disabledIconColor]: loading || !text || !options[0],
              })}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={loading || !text || !options[1]}
            onClick={() => setOption(1)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <LooksTwoIcon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 2,
                [classes.disabledIconColor]: loading || !text || !options[1],
              })}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={loading || !text || !options[2]}
            onClick={() => setOption(2)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <Looks3Icon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 3,
                [classes.disabledIconColor]: loading || !text || !options[2],
              })}
            />
          </IconButton>
          {options.length > 0 && (
            <Button onClick={() => handleReload(type)} className={classes.refreshButton}>
              <CachedIcon />
            </Button>
          )}
        </Box>
        <Typography variant="caption" className={classes.overwrite}>
          SUGGESTIONS{' '}
          <Tooltip
            title="Feel free to use your original description OR choose one of the 3 A.I. suggestions. You may use these as a starting point and edit any of the text!"
            placement="bottom"
          >
            <HelpOutlineIcon className={classes.question} />
          </Tooltip>
        </Typography>
      </Grid>
    </>
  );
}
