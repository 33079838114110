import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Field, FormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import FieldError from '../FieldError';

const PUBLISHER_ID = 1145;

const CustomUrl = ({ classes }) => {
  const { errors, validateField, values, setFieldValue } = useContext(FormikContext);
  const { type } = useParams();

  const handleCheckBox = (field, value) => setFieldValue(field, value);

  const renderCheckBox = () => {
    const field = type ? 'enterpriseBoost' : 'autoGenerateLandingPage';
    const callback = () => handleCheckBox(field, !values[field]);
    return (
      <FormControlLabel
        checked={values[field]}
        classes={{ label: classes.checkBoxLabel }}
        control={<Checkbox color="primary" />}
        onChange={callback}
        label="Create a Landing Page"
        labelPlacement="top"
      />
    );
  };

  const addParamsToUrl = () => {
    if (!type && values.episode?.title && values.customUrl && !errors.customUrl) {
      const url = new URL(values.customUrl);
      if (!url.searchParams.has('publisherid')) {
        url.searchParams.set('publisherid', PUBLISHER_ID);
      }
      if (!url.searchParams.has('episodeid')) {
        url.searchParams.set('episodeid', values.episode?.title);
      }
      setFieldValue('customUrl', url.href);
    }
  };

  useEffect(() => {
    addParamsToUrl();
  }, [errors.customUrl]);

  const handleBlur = () => {
    validateField('customUrl');
    addParamsToUrl();
  };

  useEffect(() => {
    if (!type && values.customUrl && !errors.customUrl) {
      const url = new URL(values.customUrl);
      url.searchParams.set('episodeid', values.episode?.title);
      setFieldValue('customUrl', url.href);
    }
  }, [values.episode]);

  return (
    <>
      <Grid container item md={12} alignItems="center">
        <Typography
          variant="h5"
          className={cx({
            [classes.paddingForError]: errors.customUrl,
          })}
        >
          Custom URL from Dev
        </Typography>
        <Grid container item md={12} alignItems="center">
          <Grid container item md={8} className={classes.inputSection}>
            <Field
              type="text"
              name="customUrl"
              onBlur={() => handleBlur()}
              disabled={values.enterpriseBoost || values.autoGenerateLandingPage}
              className="register-form-input"
            />
            {errors.customUrl && <FieldError text={errors.customUrl} withPosition />}
          </Grid>
          <Grid container item md={1} justifyContent="center" className={classes.inputSection}>
            <Typography>OR</Typography>
          </Grid>
          <Grid container item md={3} className={classes.inputSection} justifyContent="center">
            {renderCheckBox()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomUrl;
