import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  error: {
    color: 'red',
  },
  hsLogo: {
    width: '150px',
    marginTop: 10,
  },
  blockLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
