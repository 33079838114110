import { Grid, Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

export const BrandSafety = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper} container direction="column" item md={12} alignItems="flex-start">
      <Grid container direction="row" item md={12} justifyContent="center">
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>mowPod: Brand Safety</Typography>
      </Grid>
      <Typography variant="body2" paragraph>
        mowPod ensures brand safety by integrating a multi-step process of monitoring inventory traffic from exchanges as well as during the ad serving.
      </Typography>
      <Typography variant="body2" paragraph>
        We divide our brand safety efforts into two categories.
      </Typography>
      <Typography variant="h5" paragraph style={{ fontWeight: 'bold' }}>Quality assurance of inventory traffic from exchanges</Typography>
      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>IP Filter</Typography>
      <Typography variant="body2" paragraph>mowPod blocks IP addresses of known server and web hosting providers that send non-human traffic</Typography>

      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Fraud Prevention</Typography>
      <Typography variant="body2" paragraph>We partner with <a href="https://impact.com/" target="_blank" rel="noreferrer">Impact</a> to protect our clients against high-risk traffic sources. We monitor the real-time predictive quality scoring for each visitor that views an ad or completes an action</Typography>
      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Human Verification & Domain Whitelist</Typography>
      <Typography variant="body2" paragraph>mowPod&apos;s Platform Quality Partner manually examines the quality of domains to ensure a brand safe environment and purely operates off of whitelist solutions.</Typography>
      <Typography variant="body2" paragraph>The following are some of the criteria we follow when we manually review publishers and our inventory:</Typography>
      <ol className={classes.list}>
        <li><Typography variant="body2" paragraph>Content Category Compliance - website content is permitted within mowPod Content Guidelines (e.g.  no sexual content/copyrighted content/weapons & firearms)</Typography></li>
        <li><Typography variant="body2" paragraph>Content Quality - website legitimacy and verification of potentially computer generated/aggregated content (e.g. fake local news content)</Typography></li>
        <li><Typography variant="body2" paragraph>Historic Fraud Rate - <a href="https://www.moat.com/" target="_blank" rel="noreferrer">MOAT</a> Invalid Traffic Stats</Typography></li>
        <li><Typography variant="body2" paragraph>Ad Unit Rendering - review of ad units on site</Typography></li>
        <li><Typography variant="body2" paragraph>Brand Safe Content - safe for the majority of advertisers</Typography></li>
        <li><Typography variant="body2" paragraph>Known Fraudulent Domains - sites that are known to be involved in ad fraud schemes are removed from our inventory as soon as they are exposed or detected</Typography></li>
      </ol>
      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Machine Learning</Typography>
      <Typography variant="body2" paragraph>For whitelisted domains, mowPod utilizes machine learning techniques to detect patterns of suspicious behavior with regard to impressions, clicks, engagements and other events</Typography>
      <Typography variant="h5" paragraph style={{ fontWeight: 'bold' }}>Quality assurance of ad serving</Typography>
      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Ad Audit</Typography>
      <Typography variant="body2" paragraph>The Platform Quality Partner ensures creative assets and landing pages are compliant with mowPod and respective supply sources&apos; ad policies and the law</Typography>

      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Post Bid Analysis</Typography>
      <Typography variant="body2" paragraph>mowPod partners with <a href="https://www.moat.com/" target="_blank" rel="noreferrer">MOAT</a> to ensure each user action is authentic, each ad is viewable, fraud-free, and brand safe</Typography>
      <Typography variant="body1" paragraph style={{ fontWeight: 'bold' }}>Risk Analysis - Ad Verification</Typography>
      <Typography variant="body2" paragraph>mowPod partners with <a href="https://www.adlightning.com/" target="_blank" rel="noreferrer">Ad Lightning</a> to scan and monitor ad tags for malware and data leakage. Ad Lightning&apos;s partnership continuously scans ad tags both prelaunch and inflight. Through constant 24hr, the PQ team can consistently monitor the quality of each and every ad.</Typography>
    </Grid>
  );
};
