import { Typography } from '@mui/material';
import { Field, FormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pullOfferByName } from '../../api/tune';
import { selectRssFeed } from '../../redux/slices/rssSlice';

const OfferId = () => {
  const { setFieldValue, values } = useContext(FormikContext);
  const { feed } = useSelector(selectRssFeed);
  const [loading, setLoading] = useState(false);
  const [offerIds, setOfferIds] = useState([]);

  useEffect(() => {
    setLoading(true);
    pullOfferByName(feed?.title).then((data) => {
      setOfferIds(data);
      setLoading(false);
    });
  }, [feed]);

  const handleOfferId = (e) => {
    const { value } = e.target;
    setFieldValue('offerId', value.replace(/[^0-9]/g, ''));
  };

  return (
    <>
      <Typography variant="h5">Offer Id</Typography>
      {offerIds.length ? (
        <Field
          as="select"
          value={values.offerId}
          className="register-form-input register-form-select"
          disabled={loading}
          name="offerId"
          onChange={handleOfferId}
          placeholder="The Offer Id of the Campaign."
        >
          {offerIds.map((item, i) => (
            <option key={i} value={item.id}>
              {item.name} ({item.id})
            </option>
          ))}
        </Field>
      ) : (
        <Field
          type="text"
          disabled={loading}
          name="offerId"
          onChange={handleOfferId}
          className="register-form-input"
          placeholder="The Offer Id of the Campaign."
        />
      )}
    </>
  );
};

export default OfferId;
