import { configureStore } from '@reduxjs/toolkit';
import usersReduser from './slices/usersSlice';
import loginedUserReduser from './slices/loginedUserSlice';
import rootReduser from './slices/rootSlice';
import rssReduser from './slices/rssSlice';
import stackReduser from './slices/stackAdaptCampaignsSlice';
import suggestionReduser from './slices/suggestionSlice';
import statsReduser from './slices/statsSlice';

export const store = configureStore({
  reducer: {
    root: rootReduser,
    users: usersReduser,
    loginedUser: loginedUserReduser,
    rss: rssReduser,
    stack: stackReduser,
    suggestion: suggestionReduser,
    stats: statsReduser,
  },
});
