import React, { useState } from 'react';
import { useFormik, FormikProvider, Field } from 'formik';
import {
  Button,
  FormControl,
  Typography,
  CircularProgress,
  Grid,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import cx from 'classnames';
import { toast } from 'react-toastify';
import scratches from '../../assets/images/onboarding/scratches.png';
import airpodLeft from '../../assets/images/onboarding/airpod-left.png';
import airpodRight from '../../assets/images/onboarding/airpod-right.png';
import rainbow from '../../assets/images/onboarding/rainbow-hd.png';
import logo from '../../assets/images/onboarding/logo.svg';
import bgBtn from '../../assets/images/button.png';
import { initialValues } from './initialValues';
import { useStyles } from './styles';
import { validationSchema } from './validationSchema';
import Checkbox from '../../components/Checkbox/Checkbox';

import { saveOnboardingSurvey } from '../../api/surveyOnboarding';
import ThankYou from '../../components/ThankYou/ThankYou';

const GENRES = [
  'arts',
  'business',
  'comedy',
  'education',
  'health',
  'history',
  'hobbies',
  'music',
  'news',
  'religion',
  'science',
  'society',
  'sports',
  'technology',
  'true-crime',
  'other',
];

const Onboarding = () => {
  const classes = useStyles({ scratches, bgBtn });
  const [loading, setLoading] = useState(false);
  const [onboardingDone, setOnboardingDone] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      const values = { ...formik.values };

      setLoading(true);
      saveOnboardingSurvey(values)
        .then(() => {
          setOnboardingDone(true);
          setLoading(() => false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(err?.response?.data?.message);
        });
    },
  });

  const handleGenreChange = (event) => {
    formik.setFieldValue('genre', event.target.value);
  };

  const handleCheckboxChange = (event, field, value) => {
    if (event.target.checked) {
      formik.setFieldValue(field.name, value);
    } else {
      formik.setFieldValue(field.name, '');
    }

    setTimeout(() => {
      formik.validateField(field.name);
    }, 0);
  };

  return (
    <div className={classes.wrapper}>
      {!onboardingDone ? (
        <div>
          <img className={cx(classes.airpodRight, classes.airpod)} src={airpodLeft} alt="Airpod Right" />
          <img className={cx(classes.airpodLeft, classes.airpod)} src={airpodRight} alt="Airpod Left" />
          <img className={classes.rainbow} src={rainbow} alt="Rainbow" />
          <FormikProvider value={formik}>
            <Box component="main" className={classes.main}>
              <Grid container direction="row" justifyContent="space-between" sx={{ marginBottom: '50px' }}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography variant="h1" className={classes.title}>
                  Welcome to the onboarding form for mowPod Boost Pro! Please fill out the below. If you have
                  any questions, our operations team can go through this with you and/or modify with you after
                  you submit.
                </Typography>
              </Grid>
              <form onSubmit={formik.handleSubmit}>
                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Podcast Name:
                    </Typography>
                    <Field
                      type="text"
                      name="podcastName"
                      onBlur={() => formik.validateField('podcastName')}
                      className={classes.textInput}
                      placeholder="Podcast Name"
                    />
                    {formik.errors.podcastName && (
                      <div className={classes.errMsg}>{formik.errors.podcastName}</div>
                    )}
                  </Grid>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Episode Name:
                    </Typography>
                    <Field
                      type="text"
                      name="episodeName"
                      onBlur={() => formik.validateField('episodeName')}
                      className={classes.textInput}
                      placeholder="Episode Name"
                    />
                    {formik.errors.episodeName && (
                      <div className={classes.errMsg}>{formik.errors.episodeName}</div>
                    )}
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Apple Episode Link:{' '}
                    </Typography>
                    <Field
                      type="text"
                      name="appleEpisodeLink"
                      onBlur={() => formik.validateField('appleEpisodeLink')}
                      className={classes.textInput}
                      placeholder="Apple Episode Link"
                    />
                    {formik.errors.appleEpisodeLink && (
                      <div className={classes.errMsg}>{formik.errors.appleEpisodeLink}</div>
                    )}
                  </Grid>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Apple Main Link:{' '}
                    </Typography>
                    <Field
                      type="text"
                      name="appleMainLink"
                      onBlur={() => formik.validateField('appleMainLink')}
                      className={classes.textInput}
                      placeholder="Apple Main Link"
                    />
                    {formik.errors.appleMainLink && (
                      <div className={classes.errMsg}>{formik.errors.appleMainLink}</div>
                    )}
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Spotify Episode Link:{' '}
                    </Typography>
                    <Field
                      type="text"
                      name="spotifyEpisodeLink"
                      onBlur={() => formik.validateField('spotifyEpisodeLink')}
                      className={classes.textInput}
                      placeholder="Spotify Episode Link"
                    />
                    {formik.errors.spotifyEpisodeLink && (
                      <div className={classes.errMsg}>{formik.errors.spotifyEpisodeLink}</div>
                    )}
                  </Grid>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Spotify Main Link:{' '}
                    </Typography>
                    <Field
                      type="text"
                      name="spotifyMainLink"
                      onBlur={() => formik.validateField('spotifyMainLink')}
                      className={classes.textInput}
                      placeholder="Spotify Main Link"
                    />
                    {formik.errors.spotifyMainLink && (
                      <div className={classes.errMsg}>{formik.errors.spotifyMainLink}</div>
                    )}
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Push heavier towards Apple, Spotify or both equally?
                    </Typography>
                    <Grid container item md={12}>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="pushTowards">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              onBlur={field.onBlur}
                              checked={field.value === 'apple'}
                              onChange={(event) => handleCheckboxChange(event, field, 'apple')}
                              value="apple"
                              labelText="Apple"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="pushTowards">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'spotify'}
                              onChange={(event) => handleCheckboxChange(event, field, 'spotify')}
                              value="spotify"
                              labelText="Spotify"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="pushTowards">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'both'}
                              onChange={(event) => handleCheckboxChange(event, field, 'both')}
                              value="both"
                              labelText="Both"
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    {formik.errors.pushTowards && (
                      <div className={classes.errMsg}>{formik.errors.pushTowards}</div>
                    )}
                  </Grid>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Goal of this campaign?
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="campaignGoal">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'oneTime'}
                              onChange={(event) => handleCheckboxChange(event, field, 'oneTime')}
                              value="oneTime"
                              labelText="One time feedback for this episode"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="campaignGoal">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'ongoing'}
                              onChange={(event) => handleCheckboxChange(event, field, 'ongoing')}
                              value="ongoing"
                              labelText="Ongoing exposure with constant feedback/ New episode monthly"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="campaignGoal">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'forAdvertisers'}
                              onChange={(event) => handleCheckboxChange(event, field, 'forAdvertisers')}
                              value="forAdvertisers"
                              labelText="Exposure and feedback for our advertisers"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="campaignGoal">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'other'}
                              onChange={(event) => handleCheckboxChange(event, field, 'other')}
                              value="other"
                              labelText="Other"
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    {formik.errors.campaignGoal && (
                      <div className={classes.errMsg}>{formik.errors.campaignGoal}</div>
                    )}
                  </Grid>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Show Art:
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="showArt">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'episode'}
                              onChange={(event) => handleCheckboxChange(event, field, 'episode')}
                              value="episodeSpecific"
                              labelText="I have episode-specific Show Art"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="showArt">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'podcast'}
                              onChange={(event) => handleCheckboxChange(event, field, 'podcast')}
                              value="podcast"
                              labelText="Use Main Podcast Art"
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    {formik.errors.showArt && <div className={classes.errMsg}>{formik.errors.showArt}</div>}
                  </Grid>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Genre of Podcast:
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <FormControl fullWidth>
                          <Field
                            as={Select}
                            name="genre"
                            value={formik.values.genre}
                            className={classes.selectWrapper}
                            onBlur={() => formik.validateField('genre')}
                            onChange={handleGenreChange}
                          >
                            {GENRES.map((genre) => (
                              <MenuItem value={genre} className={classes.menuItem} key={genre}>
                                {genre.charAt(0).toUpperCase() + genre.slice(1).replace('-', ' ')}
                              </MenuItem>
                            ))}
                          </Field>
                          {formik.errors.genre && <div className={classes.errMsg}>{formik.errors.genre}</div>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Grid className={classes.inputWrapper}>
                      <Typography className={classes.legendLabel} variant="h6">
                        Survey Questions we ask:{' '}
                      </Typography>
                      <Typography className={classes.legendLabel} variant="h2">
                        1 - What did you like/dislike about the podcast
                      </Typography>
                      <Typography className={classes.legendLabel} variant="h2">
                        2 - What could be improved
                      </Typography>
                      <Typography className={classes.legendLabel} variant="h2">
                        3 - Would you listen to more episodes (Please be honest!)
                      </Typography>
                    </Grid>
                  </Grid>
                </fieldset>
                <Typography className={classes.legendLabel} variant="h6">
                  Enterprise Features:
                  <Typography variant="span" fontSize={14} style={{ display: 'block' }}>
                    please speak to your sales rep to discuss additional pricing for the below.
                  </Typography>
                </Typography>

                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Will you be adding user emails to your email list?
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="addUserEmails">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'yes'}
                              onChange={(event) => handleCheckboxChange(event, field, 'yes')}
                              value="yes"
                              labelText="Yes"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="addUserEmails">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'no'}
                              onChange={(event) => handleCheckboxChange(event, field, 'no')}
                              value="no"
                              labelText="No"
                            />
                          )}
                        </Field>
                      </Grid>
                      {formik.errors.addUserEmails && (
                        <div className={classes.errMsg}>{formik.errors.addUserEmails}</div>
                      )}
                      {formik.values.addUserEmails === 'yes' && (
                        <Grid container item md={2} className={classes.inputWrapper}>
                          <Field
                            type="text"
                            name="esp"
                            onBlur={() => formik.validateField('esp')}
                            className={classes.textInput}
                            placeholder="We need ESP API info"
                          />
                        </Grid>
                      )}
                      {formik.values.addUserEmails === 'yes' && formik.errors.esp && (
                        <div className={classes.errMsg}>{formik.errors.esp}</div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      If Yes, Do you have a privacy policy in place?
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="hasPrivacyPolicy">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'yes'}
                              onChange={(event) => handleCheckboxChange(event, field, 'yes')}
                              value="yes"
                              labelText="Yes"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container item md={2} className={classes.inputWrapper}>
                        <Field name="hasPrivacyPolicy">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value === 'no'}
                              onChange={(event) => handleCheckboxChange(event, field, 'no')}
                              value="no"
                              labelText="No"
                            />
                          )}
                        </Field>
                      </Grid>
                      {formik.errors.hasPrivacyPolicy && (
                        <div className={classes.errMsg}>{formik.errors.hasPrivacyPolicy}</div>
                      )}

                      {formik.values.hasPrivacyPolicy === 'yes' && (
                        <Grid container item md={2} className={classes.inputWrapper}>
                          <Field
                            type="text"
                            name="privacyPolicyLink"
                            className={classes.textInput}
                            onBlur={() => formik.validateField('privacyPolicyLink')}
                            placeholder="Privacy Policy Link?"
                          />
                        </Grid>
                      )}
                      {formik.values.hasPrivacyPolicy === 'yes' && formik.errors.privacyPolicyLink && (
                        <div className={classes.errMsg}>{formik.errors.privacyPolicyLink}</div>
                      )}
                    </Grid>
                  </Grid>
                </fieldset>
                <fieldset className={classes.fieldset}>
                  <Grid container item md={12} className={classes.inputWrapper}>
                    <Typography className={classes.legendLabel} variant="h6">
                      Additional Add-On Questions:
                    </Typography>
                    <Grid container item md={12} direction="column" justifyContent="space-between">
                      <Grid container item className={classes.inputWrapper}>
                        <Field
                          as="textarea"
                          name="likedHostDiscussion"
                          rows="6"
                          cols="40"
                          className={classes.textArea}
                          placeholder="Did you like the Host and Discussion?"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid container item className={classes.inputWrapper}>
                        <Field
                          as="textarea"
                          name="advertisersRelevance"
                          rows="6"
                          cols="40"
                          className={classes.textArea}
                          placeholder="Were the advertisers relevant and interesting?"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid container item className={classes.inputWrapper}>
                        <Field
                          as="textarea"
                          name="couponsInformation"
                          rows="6"
                          cols="40"
                          className={classes.textArea}
                          placeholder="Would you like emailed information with coupons for the advertisers?"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid container item className={classes.inputWrapper}>
                        <Field
                          as="textarea"
                          name="emailListSubscription"
                          rows="6"
                          cols="40"
                          className={classes.textArea}
                          placeholder="Would you like to join the email list for this podcast and be notified when we release new episodes or other shows like this?"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>
                <Grid container item justifyContent="center">
                  <Button variant="contained" type="submit" className={cx(classes.button)}>
                    {loading ? <CircularProgress color="info" size={20} /> : 'SUBMIT'}
                  </Button>
                </Grid>
              </form>
            </Box>
          </FormikProvider>
        </div>
      ) : (
        <ThankYou />
      )}
      <Grid container item justifyContent="center">
        <footer className={classes.footer}>
          <p>© 2019-2024 mowMedia LLC, All Rights Reserved.</p>
        </footer>
      </Grid>
    </div>
  );
};

export default Onboarding;
