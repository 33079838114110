import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: '#1c1b1b',
    borderRadius: 18,
    padding: '20px',
    marginBottom: 20,
    overflow: 'hidden'
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 24,
  },
  subTitle: {
    fontWeight: 800,
  }
}));
