import axios from 'axios';
import axiosInterceptor from './axiosInterceptor/index';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const getUsers = async (params) => {
  if (cancelTokenSource.getUsers) {
    cancelTokenSource.getUsers.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getUsers = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/users', {
    headers,
    params,
    cancelToken: cancelTokenSource.getUsers.token,
  });
  return res.data;
};

export const getUserById = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/users/${id}`, { headers });
  return res.data;
};

export const getPodcasts = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/users/${id}/podcasts`, { headers });
  return res.data;
};

export const addPodcast = async (data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.post('/api/users/podcast', data, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const editPodcast = async ({ userId, podcastId, data }) => {
  const headers = getHeaders();
  const endpoint = userId ? `/api/users/${userId}/podcast/${podcastId}` : `/api/users/podcast/${podcastId}`;
  const res = await axiosInterceptor.put(endpoint, data, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const updateUser = async (data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.patch('/api/users/', data, { headers });
  return res.data;
};

export const updateUserByAdmin = async (id, data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.patch(`/api/users/admin/${id}`, data, { headers });
  return res.data;
};

export const sendEmailForResetPassword = async (data) => {
  const res = await axiosInterceptor.post('api/users/submit-reset-pass-email', data);
  return res.data;
};

export const resetPassword = async (data) => {
  const res = await axiosInterceptor.post('api/users/reset-password', data);
  return res.data;
};

export const confirmEmail = async (token) => {
  const res = await axiosInterceptor.post('api/users/confirm-email', { token });
  return res.data;
};

export const sendVerifyEmail = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.post('api/users/send-verify-email', {}, { headers });
  return res.data;
};

export const setPricing = async (userId, data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.post(`/api/users/${userId}/pricing`, data, { headers });
  return res.data;
};
