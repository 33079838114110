import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';

export const PublicRoute = ({ component: Component, isPublic, ...rest }) => {
  const { user } = useSelector(selectLoginedUser);
  const isLoggedIn = !!user;
  return (
    <Route
      {...rest}
      render={(props) => (!isLoggedIn || isPublic
        ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))}
    />
  );
};
