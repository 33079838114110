import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TablePagination,
  InputBase,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { useGettingData } from '../../../hooks/useGettingData';
import { resetCampaignGroupsWithClickUrl, selectStack } from '../../../redux/slices/stackAdaptCampaignsSlice';
import { TablePaginationActions } from '../../../components/TablePaginationActions/TablePaginationActions';
import { EnhancedTableHead } from './EnhancedTableHead';
import { formatDateToYYYYMMDD } from '../../../utils/utils';
import { containsSearchValue, getComparator, stableSort } from '../../../utils/table';

export default function CampaignGroupList() {
  const classes = useStyles();
  const { handleGettingCampaignGroupsWithClickUrl } = useGettingData();
  const [isHidden, setIsHidden] = useState(false);

  const { advertiserId } = useParams();
  const { campaignGroupsWithClickUrl, loading } = useSelector(selectStack);

  const [campaignGroups, setCampaignGroups] = useState([]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState('');
  const [cachedCampaignGroups, setCachedCampaignGroups] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGettingCampaignGroupsWithClickUrl({ advertiserId });
    return () => dispatch(resetCampaignGroupsWithClickUrl());
  }, []);

  useEffect(() => {
    if (campaignGroupsWithClickUrl) {
      const filteredCampaignGroups = isHidden
        ? campaignGroupsWithClickUrl
        : campaignGroupsWithClickUrl.filter((item) => item.clickUrl);

      setCampaignGroups(filteredCampaignGroups);
      setCachedCampaignGroups(filteredCampaignGroups);
    }
  }, [campaignGroupsWithClickUrl, isHidden]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(campaignGroups, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, campaignGroups]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const keyPress = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (e.keyCode === 27) {
      setSearch('');
    }
  };

  const handleShowEmptyGroups = () => setIsHidden(!isHidden);

  const searchCampaignGroups = () => {
    const normalizedSearchValue = search.toLowerCase();
    const fields = ['id', 'name'];
    return cachedCampaignGroups.filter((item) => containsSearchValue(item, fields, normalizedSearchValue));
  };

  useEffect(() => {
    const newCampaignGroups = search ? searchCampaignGroups() : cachedCampaignGroups;
    setCampaignGroups(newCampaignGroups);
  }, [search, cachedCampaignGroups]);

  return (
    <Grid className={classes.wrapper} container item md={12} justifyContent="center">
      <Grid className={classes.title} container item md={12}>
        <Grid className={classes.title} container item md={6} alignItems="flex-end">
          <Typography variant="h4">Campaign Group List</Typography>
          <Box pl={1}>
            <Typography variant="caption" className={classes.showEmptyGroups} onClick={handleShowEmptyGroups}>
              ({isHidden ? 'hide' : 'show'} empty groups)
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.searchInputWrapper}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={search}
            onKeyDown={keyPress}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{ 'aria-label': 'search campaign groups' }}
          />
        </Grid>
      </Grid>
      <TableContainer className={classes.tTableContainer} component={Paper}>
        <Table aria-label="simple table" className={classes.tTable}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={campaignGroups.length}
          />
          <TableBody>
            {visibleRows.map((campaignGroup, i) => (
              <StyledTableRow key={campaignGroup.id}>
                <StyledTableCell scope="row" className={classes.tFirstItem}>
                  {i + 1}
                </StyledTableCell>
                <StyledTableCell scope="row">
                  {formatDateToYYYYMMDD(campaignGroup.flights?.nodes[0]?.startTime)}
                </StyledTableCell>
                <StyledTableCell scope="row">{campaignGroup.id}</StyledTableCell>
                <StyledTableCell scope="row">{campaignGroup.name}</StyledTableCell>
                <StyledTableCell align="right">{campaignGroup.campaigns?.length || 0}</StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  {campaignGroup.clickUrl ? (
                    <a href={campaignGroup.clickUrl} target="_blank" rel="noreferrer">
                      Link
                    </a>
                  ) : (
                    'none'
                  )}
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  {campaignGroup.campaignGroupStatus.state || 'paused'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {loading && (
              <TableRow>
                <TableCell colSpan={6} className={classes.tHeader} align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
          component="div"
          count={campaignGroups.length}
          rowsPerPage={rowsPerPage}
          slotProps={{
            select: {
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.tTablePagination}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </Grid>
  );
}
