import axios from "axios";

const cancelTokenSource = {};

export const getRssFeed = async (source, multiple) => {
  if (cancelTokenSource.getRssFeed) {
    cancelTokenSource.getRssFeed.cancel('canceled');
  }
  cancelTokenSource.getRssFeed = axios.CancelToken.source();
  const res = await axios.get('/api/rss/stream-link', {
    params: { source, withFeedHeader: true },
    cancelToken: multiple ? '' : cancelTokenSource.getRssFeed.token,
  });
  return res.data;
};
