import React, { useEffect } from 'react';
import { downloadW9 } from '../../../api/auth';

export default function Download() {
  const download = () => {
    downloadW9()
      .then((response) => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let a = document.createElement('a');
        a.href = url;
        a.download = 'w9-mowMedia_2020.pdf';
        a.click();
      });
  };

  useEffect(() => {
    setTimeout(() => {
      download();
    });
  }, []);

  return (
    <div className="download-container">
      <div className="download-row">
        <div className="download-block">
          <div className="download-title">
            Feel Free to Download Our W9
          </div>
          <div className="download-button">
            <button type="button" onClick={download} className="button">DOWNLOAD</button>
          </div>
          <div className="download-additional-info">
            If you need anything additional
            {' '}
            <br />
            please contact
            {' '}
            <a href="mailto:billing@mowmedia.com">billing@mowmedia.com</a>
          </div>
          <div className="download-additional-info">
            We will begin putting together your campaign and your account manager will be in touch shortly!
          </div>
        </div>
      </div>
    </div>
  );
}
