import { Box, Grid, Typography } from '@mui/material';
import { Field, FormikContext } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import FieldError from '../FieldError';
import CompressText from '../CompressText';
import { resize } from '../../utils/utils';

const Subtitle = ({ classes, getSubtitle }) => {
  const {
    values,
    errors,
    validateField,
    setFieldValue,
  } = useContext(FormikContext);
  const textAreaRef = useRef(null);
  const { type } = useParams();

  useEffect(() => {
    if (textAreaRef.current) {
      resize(textAreaRef.current);
    }
  }, [textAreaRef, values.subtitle]);

  return (
    <>
      <Grid
        container
        item
        md={12}
        alignItems="center"
        className={cx({ [classes.paddingForError]: errors.subtitle })}
      >
        <Typography variant="h5">Subtitle</Typography>
        <Box marginLeft={1}>
          <Typography
            variant="body2"
            className={cx({
              [classes.error]: values.subtitle.length > (type ? 82 : 120),
            })}
          >
            {values.subtitle.length}/{type ? 82 : 120}
          </Typography>
        </Box>
      </Grid>
      <Grid container item md={9} className={classes.inputSection}>
        <Field
          as="textarea"
          name="subtitle"
          innerRef={textAreaRef}
          onBlur={() => validateField('subtitle')}
          className={cx(classes.textarea, 'register-form-input')}
        />
        {errors.subtitle && <FieldError text={errors.subtitle} withPosition />}
      </Grid>
      <Grid
        container
        item
        md={3}
        alignItems="center"
        justifyContent="space-around"
        className={classes.inputSection}
      >
        <CompressText
          text={getSubtitle}
          sidecar={type || ''}
          handleOption={(subtitle) => setFieldValue('subtitle', subtitle)}
          type="subtitle"
        />
      </Grid>
    </>
  );
};

export default Subtitle;
