import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
  wrapper: {
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    },
  },
  titleSection: {
    paddingBottom: 25,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  dataSection: {
    backgroundColor: '#1c1b1b',
    padding: '15px 30px',
    borderRadius: 18,
  },
  fieldTitle: {
    marginTop: 15,
    marginBottom: 15,
    fontWeight: 'bold',
    fontSize: 16,
  },
  inputSection: {
    position: 'relative',
  },
  btnSection: {
    marginTop: 30,
  },
  btn: {
    color: '#fff',
  },
  errorIcon: {
    position: 'absolute',
    top: 13,
    right: 15,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
  noMaxWidth: {
    backgroundColor: '#0000003b',
    maxWidth: 'none',
  },
  select: {
    width: '100%',
    color: '#000',
  },
}));
