export const MAX_FILES = 3;
export const TEN_MB = 10485760;

export const geo = [
  { label: 'United States', value: '1242813' },
  { label: 'Canada', value: '1242619' },
  { label: 'United Kindom', value: '1242657' },
  { label: 'Mexico', value: '1242736' },
  { label: 'Australia', value: '1242596' },
  { label: 'New Zealand', value: '1242751' },
  { label: 'Germany', value: '1242636' },
  { label: 'France', value: '1242655' },
  { label: 'Africa', value: 'africa' },
  { label: 'South Africa', value: '1242827' },
  { label: 'Worldwide', value: 'ALL' },
];

export const age = [
  { value: '11398', label: '18-24' },
  { value: '11399', label: '25-34' },
  { value: '11400', label: '35-44' },
  { value: '11401', label: '45-54' },
  { value: '11402', label: '55-64' },
  { value: '11403', label: '65+' },
];

export const householdIncome = [
  { value: '11427', label: '0-25k' },
  { value: '11422', label: '25k-50k' },
  { value: '11423', label: '50k-75k' },
  { value: '11424', label: '75k-100k' },
  { value: '11421', label: '100k+' },
  { value: '11425', label: '150k-250k' },
  { value: '11426', label: '250k+' },
];

export const gender = [
  { value: '11395', label: 'Male' },
  { value: '11396', label: 'Female' },
];
