/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { Field, FormikContext } from 'formik';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import SelectFiles from '../SelectFiles';

import { useStyles } from './styles';
import { useGettingData } from '../../hooks/useGettingData';
import { removeFeed, selectRssFeed } from '../../redux/slices/rssSlice';
import { FeedData } from '../FeedData';
import FieldError from '../FieldError';

export default function AddPodcast() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleGettingRssFeed } = useGettingData();
  const { feed, error, loading } = useSelector(selectRssFeed);
  const formik = useContext(FormikContext);

  useEffect(() => () => dispatch(removeFeed()), []);

  useEffect(() => {
    if (error && error?.message !== 'canceled') {
      toast.error(`RSS Feed Link: ${error?.message}`);
    }
  }, [error]);

  useEffect(async () => {
    const {
      errors,
      values: { podcastUrl },
    } = formik;
    if (!errors.podcastUrl && podcastUrl) {
      formik.setFieldValue('podcastFiles', []);
      await handleGettingRssFeed(podcastUrl);
    }
  }, [formik.values.podcastUrl, formik.errors.podcastUrl]);

  useEffect(() => {
    if (!feed) {
      formik.setFieldValue('podcastFiles', []);
      return;
    }
    const { podcastFiles } = formik.values;
    const imageUrl = feed?.image?.url || feed?.itunes?.image;
    if (imageUrl && !podcastFiles.find((i) => i.Location === imageUrl)) {
      formik.setFieldValue('podcastFiles', [{ Location: imageUrl }, ...podcastFiles]);
    }
    const feedData = { image: imageUrl };
    if (feed?.itunes?.categories?.length) feedData.categories = feed?.itunes?.categories;
    if (feed?.itunes?.owner) feedData.owner = feed?.itunes?.owner;
    formik.setFieldValue('feedData', feedData);
    formik.setFieldValue('podcastName', feed.title);
  }, [feed]);

  const removeFile = (i) => {
    const { podcastFiles } = formik.values;
    const updatedPodcastFiles = podcastFiles.filter((_, index) => index !== i);
    formik.setFieldValue('podcastFiles', updatedPodcastFiles);
  };

  const getFileUrl = (file, i) => {
    const fr = new FileReader();
    fr.onload = () => {
      const { podcastFiles } = formik.values;
      podcastFiles[i].Location = fr.result;
      formik.setFieldValue('podcastFiles', podcastFiles);
    };
    fr.readAsDataURL(file);
  };

  const handleRss = async (e) => {
    const { value } = e.target;
    await formik.validateField('podcastUrl');
    formik.setFieldValue('podcastUrl', value);
  };

  return (
    <>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.podcastUrl })}>
          <div className="label">
            <label>Podcast RSS Feed</label>
          </div>
          <Grid className={classes.inputSection} container item md={12}>
            <Field
              type="text"
              name="podcastUrl"
              onChange={handleRss}
              className="register-form-input"
              placeholder="https://"
            />
            {formik.errors.podcastUrl && <FieldError text={formik.errors.podcastUrl} />}
          </Grid>
        </div>
      </div>
      {loading ? (
        <Grid container item md={11} justifyContent="center" margin={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <FeedData />
      )}
      <div className="register-form-row">
        <div className={cx('register-form-block')}>
          <SelectFiles />
        </div>
      </div>
      {formik.values.podcastFiles.map((item, i) => (
        <div key={`${item.name}-${i}`} className="register-form-row">
          <div className={cx('register-form-block')}>
            <div className={classes.filesList}>
              <div className="uploaded-file">
                <div>
                  {item.Location ? (
                    <img src={item.Location} alt={item.name} className={classes.fileImg} />
                  ) : (
                    getFileUrl(item, i)
                  )}
                </div>
                <div className="uploaded-file-name" id="uploaded-file-name">
                  {item.name}
                </div>
              </div>
              <div className="uploaded-file-remove" onClick={() => removeFile(i)} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
