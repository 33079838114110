import axios from 'axios';
import axiosInterceptor from './axiosInterceptor/index';
import { getHeaders } from '../utils/utils';

export const login = async (formData) => {
  const response = await axios.post('/api/auth/login', formData);
  localStorage.setItem('auth_token', response.data.token);
  return response;
};

export const signUp = async (data) => axios.post('/api/auth/registration', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const getUser = async () => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get('/api/user', { headers });
  return res.data;
};

export const downloadW9 = async () => {
  const headers = getHeaders();
  return axios.get('/api/download-w9', { headers, responseType: 'blob' });
};

export const logout = async () => {
  localStorage.removeItem('auth_token');
  await axios.post('/api/logout');
};

export const validateEmail = async (email) => axios.post('api/check-email', { email });

export const sendEmailForResetPassword = async (data) => {
  const res = await axios.post('api/users/submit-reset-pass-email', data);
  return res.data;
};

export const resetPassword = async (data) => {
  const res = await axios.post('api/users/reset-password', data);
  return res.data;
};

export const confirmEmail = async (token) => {
  const res = await axios.post('api/users/confirm-email', { token });
  return res.data;
};

export const sendVerifyEmail = async () => {
  const headers = getHeaders();
  const res = await axios.post('api/users/send-verify-email', {}, { headers });
  return res.data;
};
