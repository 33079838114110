import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 420,
    overflowY: 'scroll',
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        scale: '0.8'
      }
    }
  },
  img: {
    width: '100%',
    height: 'fit-content',
  },
  followText: {
    color: ({ mainTextColor }) => mainTextColor,
    textAlign: 'center',
    fontFamily: "'Caveat', cursive",
    paddingTop: 15,
    fontWeight: 600,
    fontSize: 24,
    transform: 'rotate(358deg)',
  },
  listenNow: {
    textAlign: 'center',
    fontFamily: "'Caveat', cursive",
    fontWeight: 600,
    fontSize: 24,
    transform: 'rotate(358deg)',
  },
  mainText: {
    fontSize: 14,
    color: ({ mainTextColor }) => mainTextColor,
    textAlign: 'center',
    fontWeight: 600,
  },
  latestEpisodes: {
    fontWeight: 800,
    textAlign: 'center',
  },
  episodeTitle: {
    fontSize: 12,
    color: ({ mainTextColor }) => mainTextColor,
    textAlign: 'center',
    fontWeight: 600,
  },
  followArrow: {
    left: -45,
    bottom: 30,
    position: 'absolute',
    maxWidth: 55,
  },
  follow: {
    position: 'relative',
  },
  listenArrow: {
    width: '100%',
    height: 48,
  },
  copyRight: {
    textShadow: '0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black',
    fontSize: 14,
  },
}));
