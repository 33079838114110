import { Button, Grid, Typography } from '@mui/material';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { updateUser, updateUserByAdmin } from '../../api/users';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

import { useStyle } from './styles';
import { usePodcasts } from '../../contexts/PodcastsContext';
import { TagInput } from '../TagInput';
import { useGettingData } from '../../hooks/useGettingData';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { selectUsers } from '../../redux/slices/usersSlice';

export default function UserInfo() {
  const classes = useStyle();
  // const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [advIds, setAdvIds] = useState([]);
  const { pickedUser } = useSelector(selectUsers);
  const { isAdmin, user } = useSelector(selectLoginedUser);
  const { setShow } = usePodcasts();
  const { id } = useParams();
  const { handleGettingUser } = useGettingData();

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: values => {
      if (isAdmin) {
        return updateUserByAdmin(id || user._id, values)
          .then(async () => {
            await handleGettingUser(id);
            history.push(`/users/${id || ''}`);
          })
          .catch(err => {
            const { data } = err.response;
            if (Array.isArray(data)) {
              data.forEach(item => toast.error(item.msg));
            }
          });
      }
      return updateUser(values).then(async () => {
        await handleGettingUser(user._id);
        history.push('/user');
      });
    },
  });

  useEffect(() => {
    if (pickedUser) {
      formik.setValues({
        email: pickedUser.email || '',
        firstName: pickedUser.userDetails.firstName || '',
        lastName: pickedUser.userDetails.lastName || '',
        title: pickedUser.userDetails.title || '',
        salesperson: pickedUser.salesperson || '',
        stackAdaptAdvertiser: pickedUser?.stackAdaptAdvertiser || [],
      });
      setAdvIds(pickedUser?.stackAdaptAdvertiser || []);
    }
  }, [pickedUser]);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    formik.setFieldValue('stackAdaptAdvertiser', advIds);
  }, [advIds]);

  const renderError = text => (
    <>
      <Typography variant="caption" className={classes.error}>
        {text}
      </Typography>
      <div className={classes.errorIcon}>!</div>
    </>
  );

  const marginValueForLongPasswordError = formik.errors?.password?.length > 30 ? 65 : 15;

  return (
    pickedUser && (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className="register-form-container">
          <Grid className={classes.titleSection} container item md={12}>
            <Typography className={classes.title}>User Details</Typography>
          </Grid>
          <Grid
            className={classes.dataSection}
            container
            item
            md={12}
            justifyContent="space-between"
          >
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Email Address
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="email"
                  name="email"
                  disabled={!isAdmin}
                  autoComplete="email"
                  onBlur={() => formik.validateField('email')}
                  className="register-form-input"
                  placeholder="Your email here"
                />
                {formik.errors.email && renderError(formik.errors.email)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  First Name
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="text"
                  name="firstName"
                  onBlur={() => formik.validateField('firstName')}
                  className="register-form-input"
                  placeholder="Joe"
                />
                {formik.errors.firstName && renderError(formik.errors.firstName)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Last Name
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="text"
                  name="lastName"
                  onBlur={() => formik.validateField('lastName')}
                  className="register-form-input"
                  placeholder="Doe"
                />
                {formik.errors.lastName && renderError(formik.errors.lastName)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Title
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} item md={10}>
                <Field
                  type="text"
                  name="title"
                  onBlur={() => formik.validateField('title')}
                  className="register-form-input"
                  placeholder="Your title here"
                />
                {formik.errors.title && renderError(formik.errors.title)}
              </Grid>
            </Grid>
            {isAdmin && (
              <>
                <Grid container item md={6}>
                  <Grid container item md={12}>
                    <Typography className={classes.fieldTitle} variant="h6">
                      Salesperson
                    </Typography>
                  </Grid>
                  <Grid className={classes.inputSection} item md={10}>
                    <Field
                      as="select"
                      name="salesperson"
                      onBlur={() => formik.validateField('salesperson')}
                      className="register-form-input register-form-select"
                    >
                      <option value="" disabled>
                        Choose from list
                      </option>
                      <option value="Mike">Mike</option>
                      <option value="Chase">Chase</option>
                      <option value="Marilyn">Marilyn</option>
                      <option value="James">James</option>
                      <option value="Jesse">Jesse</option>
                    </Field>
                    {formik.errors.salesperson && renderError(formik.errors.salesperson)}
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <Grid container item md={12}>
                    <Typography className={classes.fieldTitle} variant="h6">
                      MowDSP Advertisers
                    </Typography>
                  </Grid>
                  <Grid className={classes.inputSection} container item md={10}>
                    <TagInput advIds={advIds} setAdvIds={setAdvIds} />
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <Grid container item md={12}>
                    <Typography
                      className={classes.fieldTitle}
                      variant="h6"
                      style={{
                        marginBottom: formik.errors.password && marginValueForLongPasswordError,
                      }}
                    >
                      Password
                    </Typography>
                  </Grid>
                  <Grid className={classes.inputSection} container item md={10}>
                    <Field
                      type="text"
                      name="password"
                      onBlur={() => formik.validateField('password')}
                      className="register-form-input"
                      placeholder="**********"
                    />
                    {formik.errors.password && renderError(formik.errors.password)}
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <Grid container item md={12}>
                    <Typography className={classes.fieldTitle} variant="h6">
                      Confirm Password
                    </Typography>
                  </Grid>
                  <Grid className={classes.inputSection} container item md={10}>
                    <Field
                      type="text"
                      name="confirmPassword"
                      onBlur={() => formik.validateField('confirmPassword')}
                      className="register-form-input"
                      placeholder="**********"
                    />
                    {formik.errors.confirmPassword && renderError(formik.errors.confirmPassword)}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              className={classes.btnSection}
              container
              item
              md={11}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                className={cx('button register-form-button', classes.btn)}
                fullWidth
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    )
  );
}
