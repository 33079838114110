import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { selectStats } from '../../../redux/slices/statsSlice';
import { useGettingData } from '../../../hooks/useGettingData';

const useStyles = makeStyles({
  title: { color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 },
});

const options = {
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        color: '#fff',
      },
    },
  },
};

const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};

export default function TopOS({ startDate, endDate, stackAdaptId }) {
  const classes = useStyles();
  const chart = useRef(null);
  const { handleGettingTopOS } = useGettingData();
  const [chartData, setChartData] = useState({ ...initialState });
  const {
    topOS: {
      data: { records = [] },
      loading,
      error
    },
    sidecar,
  } = useSelector(selectStats);

  useEffect(() => {
    if (!stackAdaptId.length) return;
    handleGettingTopOS({ ids: stackAdaptId, limit: 10, startDate, endDate, sidecar: +sidecar, type: 'DEVICE_OS' });
  }, [stackAdaptId, startDate, endDate]);

  useEffect(() => {
    const currentData = structuredClone(initialState);
    records.forEach(([name, metrics]) => {
      const { clickConversions, clicks } = metrics;
      const clk = sidecar ? clicks : clickConversions;
      currentData.labels.push(name);
      currentData.datasets[0].data.push(clk);
      currentData.datasets[0].backgroundColor.push(
        `#${Math.floor(Math.random() * 16777215).toString(16)}`
      );
    });
    setChartData(currentData);
  }, [records]);

  const render = () => {
    if (error) {
      return (
        <Grid container item md={12} justifyContent="center">
          <Button
            onClick={() => handleGettingTopOS({ ids: stackAdaptId, limit: 10, startDate, endDate, sidecar: +sidecar, type: 'DEVICE_OS' })}
          >
            Try Again
          </Button>
        </Grid>
      );
    }
    if (!records.length) {
      return (
        <Grid container item md={12} justifyContent="center">
          <Typography variant="h6">No Data</Typography>
        </Grid>
      );
    }
    return (<Doughnut ref={chart} data={chartData} options={options} />);
  };

  return (
    <>
      <Typography className={classes.title}>
        TOP OS
      </Typography>
      <Grid container item xs={12} justifyContent="center">
        {!loading ? (
          render()
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </>
  );
}
