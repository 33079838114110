import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormikContext } from 'formik';
import { MIME_TYPES } from './mimeTypes';

const TEN_MB = 10485760;
const MAX_FILES = 10;

export default function SelectFiles() {
  const formik = useContext(FormikContext);

  const handlerFiles = (files) => {
    if (formik.values.podcastFiles.length >= MAX_FILES) {
      alert('Max 10 files');
      return;
    }
    const validFiles = [];
    let arrFiles = [...files];
    arrFiles.forEach((file) => {
      if (file.size > TEN_MB || !MIME_TYPES.includes(file.type)) {
        return;
      }
      validFiles.push(file);
    });

    const remainder = MAX_FILES - formik.values.podcastFiles.length;
    formik.setFieldValue('podcastFiles', [...validFiles.splice(0, remainder), ...formik.values.podcastFiles]);
  };

  const onDrop = useCallback(handlerFiles, [formik.values.podcastFiles.length]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="label">
        <label>Upload any assets related to your podcast we can use to create your campaign</label>
      </div>
      <div {...getRootProps()} className="file-input">
        <input
          {...getInputProps()}
          type="file"
          className="custom-file"
          multiple
          onChange={(e) => onDrop(e.currentTarget.files)}
          accept={MIME_TYPES}
        />
        <div className="file-input-block">
          <label className="file-label">
            {isDragActive
              ? 'Drop the files here'
              : 'Drop your images or click to Browse jpg, png, gif, svg, webp. Max 10 files. Max 10 MB'}
          </label>
        </div>
        <label htmlFor="file-input">
          <div className="button button-upload">
            Browse
          </div>
        </label>
      </div>
    </>
  );
}
