import { useFormikContext } from 'formik';
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useStyles } from './styles';
import followAppleImg from '../../assets/images/follow-apple.png';
import player from '../../assets/images/player.jpg';
import { selectRssFeed } from '../../redux/slices/rssSlice';
import { ReactComponent as ArrowImage } from '../../assets/images/arrow-image.svg';
import { ReactComponent as ListenArrowImage } from '../../assets/images/listen-arrow.svg';

const Preview = () => {
  const { feed } = useSelector(selectRssFeed);
  const formik = useFormikContext();

  const {
    enterpriseBoostSettings: { mainImage, bgColor, mainTextColor, mainText },
  } = formik.values;
  const classes = useStyles();

  const followApple = () => (
    <Grid container item md={12} justifyContent="center">
      <Grid container item md={4} className={classes.follow} justifyContent="center">
        <Typography className={classes.followText} style={{ color: mainTextColor }}>
          Follow {feed?.title} Now!
        </Typography>
        <ArrowImage className={classes.followArrow} fill={mainTextColor} />
        <img src={followAppleImg} alt="follow-apple" className={classes.img} />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      item
      md={12}
      justifyContent="center"
      alignContent="flex-start"
      className={classes.wrapper}
      style={{ background: bgColor }}
    >
      <Grid container item md={12} justifyContent="center">
        <Grid container item md={3}>
          <Box pt={1}>
            {mainImage && <img src={mainImage} alt={feed?.title} className={classes.img} />}
          </Box>
        </Grid>
      </Grid>
      {followApple()}
      <Grid container item md={12} justifyContent="center">
        <Grid container item md={12} justifyContent="center" alignContent="center">
          <Grid container item md={1} justifyContent="center" alignContent="center">
            <ListenArrowImage className={classes.listenArrow} fill={mainTextColor} />
          </Grid>
          <Grid container item md={2} justifyContent="center" alignContent="center">
            <Typography className={classes.listenNow} style={{ color: mainTextColor }}>
              Listen Now!
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={6} justifyContent="center">
          <Box pt={3} pb={3}>
            <img src={player} alt="player" className={classes.img} />
          </Box>
        </Grid>
      </Grid>
      <Grid container item md={12} justifyContent="center">
        <Grid container item md={8} justifyContent="center">
          <Typography className={classes.mainText} style={{ color: mainTextColor }}>
            {mainText}
          </Typography>
        </Grid>
      </Grid>
      <Box pt={3} pb={3}>
        <Grid container item md={12} justifyContent="center">
          <Typography
            variant="h5"
            className={classes.latestEpisodes}
            style={{ color: mainTextColor }}
          >
            LATEST EPISODES
          </Typography>
        </Grid>
        <Grid container item md={12} justifyContent="space-around">
          {feed?.items &&
            feed?.items.slice(0, 3).map((item) => (
              <Grid
                container
                key={item.title}
                item
                md={3}
                justifyContent="center"
                alignContent="flex-start"
              >
                <img className={classes.img} src={item.itunes.image} alt="" />
                <Typography className={classes.episodeTitle} style={{ color: mainTextColor }}>
                  {item.title}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box pt={3} pb={3}>
        {followApple()}
      </Box>
      <Box pt={3} pb={3}>
        <Typography className={classes.copyRight}>© 2019-{moment().year()} mowPod.com, All Rights Reserved</Typography>
      </Box>
    </Grid>
  );
};

export default Preview;
