import { Button, Grid, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { useStyle } from './styles';
import { initialValues } from './initialValues';
import { setPricing } from '../../api/users';
import { selectUsers } from '../../redux/slices/usersSlice';

export default function Pricing() {
  const classes = useStyle();
  const { id } = useParams();
  const { pickedUser } = useSelector(selectUsers);
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      await setPricing(id, values);
    },
  });

  useEffect(() => {
    if (pickedUser) formik.setValues(pickedUser?.pricing);
  }, [pickedUser]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="register-form-container">
        <Grid className={classes.dataSection} container item md={12} justifyContent="space-between">
          {Object.keys(formik.values).map((key) => (
            <Grid key={key} container direction="row" item md={6}>
              <Grid container direction="row" item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  {key}
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field type="number" name={key} className="register-form-input" />
              </Grid>
            </Grid>
          ))}
          <Grid className={classes.btnSection} container item md={11} justifyContent="center">
            <Button
              className={cx('button register-form-button', classes.btn)}
              fullWidth
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}
