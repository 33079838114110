import moment from 'moment-timezone';

export const initialValues = {
  title: '',
  subtitle: '',
  episode: '',
  customUrl: '',
  files: [],
  enterpriseBoost: false,
  start_date: moment.tz('America/New_York').startOf('day').format(),
  end_date: moment.tz('America/New_York').add(4, 'days').endOf('day').format(),
  offerId: '',
  creatingImagesWay: 'generate',
  geos: ['1242813'],
  interests: [],
  showEpisodeSelect: false,
  demos: [
    '11398',
    '11399',
    '11400',
    '11401',
    '11402',
    '11403',
    '11421',
    '11422',
    '11423',
    '11424',
    '11425',
    '11426',
    '11427',
    '11395',
    '11396',
  ],
  autoGenerateLandingPage: false,
  audience: [],
  lookalike: [],
  keywords: [],
  retargetingCampaign: false,
  enterpriseBoostSettings: {
    mainText: '',
    destinationURL: '',
    mainTextColor: 'rgba(255,255,255,1)',
    bgColor: 'rgba(0,0,0,1)',
    mainImage: '',
    rss: '',
    episode: null,
  },
};
