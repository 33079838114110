import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  select: {
    color: '#000',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
});
