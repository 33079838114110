import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: '#1c1b1b',
    padding: 25,
    marginRight: 30,
    borderRadius: 18,

    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
  }
}));
