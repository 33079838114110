import { TableCell, TableRow } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
  },
  tHead: {
    fontWeight: 'bold',
    backgroundColor: theme,
  },
  tTableContainer: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tHeader: {
    color: 'grey',
    border: 'none',
  },
  tTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  },
  tTablePagination: {
    color: '#fff',
    border: 'none',
  },
  tFirstItem: {
    border: 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  tBodyItem: {
    border: 'none',
  },
  tHeadSortLabel: {
    '&:hover': {
      color: 'grey !important',
    },
    '& svg': {
      fill: 'grey',
    },
    '&.MuiTableSortLabel-active': {
      color: 'white',
      '& svg': {
        fill: 'white',
      },
    },
  },
  tHeadCampaigns: {
    width: 80,
  },
  tHeadStatus: {
    width: 80,
  },
  wrapper: {
    paddingRight: 30,
    paddingBottom: 30,
  },
  title: {
    marginTop: 30,
  },
  iconButton: {
    color: '#fff',
  },
  actionMenu: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  dialog: {
    backgroundColor: '#232222',
  },
  campaign: {
    padding: '10px 0',
  },
  showEmptyGroups: {
    cursor: 'pointer',
  },
  input: {
    border: '1px solid #fff',
    padding: '5px 15px',
    width: '100%',
  },
  searchInputWrapper: {
    paddingTop: 20,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}))(TableRow);
