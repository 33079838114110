/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import cx from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { MIME_TYPES } from '../../components/SelectFiles/mimeTypes';
import { addCampaigns, getLineItemById } from '../../api/stats';
import { usePodcasts } from '../../contexts/PodcastsContext';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { MAX_FILES, TEN_MB } from './const';

import { useStyles } from './styles';
import { removeHTMLFromString, validURL } from '../../utils/utils';
import { useGettingData } from '../../hooks/useGettingData';
// Redux
import { selectUsers } from '../../redux/slices/usersSlice';
import { removeFeed, selectRssFeed } from '../../redux/slices/rssSlice';
import { selectStack, setCampaignGroup } from '../../redux/slices/stackAdaptCampaignsSlice';
import { resetSuggestions, selectSuggestion } from '../../redux/slices/suggestionSlice';
// Components
import FieldError from '../../components/FieldError';
import AdjustingLandingPage from '../../components/AdjustingLandingPage';
import { LoadingImage } from '../../components/LoadingImage';
import SummaryModal from '../../components/SummaryModal/SummaryModal';
import Episodes from '../../components/CampaignForm/Episodes';
import Title from '../../components/CampaignForm/Title';
import Subtitle from '../../components/CampaignForm/Subtitle';
import CustomUrl from '../../components/CampaignForm/CustomUrl';
import DateChooser from '../../components/CampaignForm/DateChooser';
import OfferId from '../../components/CampaignForm/OfferId';
import Geos from '../../components/CampaignForm/Geos';
import DemographicTargeting from '../../components/CampaignForm/DemographicTargeting';
import CustomSegments from '../../components/CampaignForm/CustomSegments';
import Interests from '../../components/CampaignForm/Interests';
import LookalikeAudience from '../../components/CampaignForm/LookalikeAudience';

const sizes = ['600x600', '800x600', '1200x627'];

export default function Campaign() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const dispatch = useDispatch();
  const { campaignGroup } = useSelector(selectStack);
  const { feed } = useSelector(selectRssFeed);
  const { pickedUser } = useSelector(selectUsers);
  const { audienceSuggestions, interestSuggestions } =
    useSelector(selectSuggestion);
  const history = useHistory();
  const { handleGettingRssFeed } = useGettingData();
  const { setShow } = usePodcasts();
  const { id, podcastId, advertiserId, campaignGroupId, type } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [podcast, setPodcast] = useState();
  const { handleGettingSuggestions } = useGettingData();
  const [isModalOpen, setModalOpen] = useState(false);
  const [clickUrl, setClickUrl] = useState('');
  const [advertiser, setAdvertiser] = useState({});

  const handleModalClose = () => {
    setModalOpen(false);
    history.push(`/users/${id}/advertiser/${advertiserId}/campaign-group/list`);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      const values = { ...formik.values };
      const formData = new FormData();
      Object.keys(values).forEach((item) => {
        if (item === 'files' && values.creatingImagesWay !== 'generate') {
          const files = values[item].reduce((accum, file, i) => {
            if (!file.url) {
              formData.append(`file${i}`, file);
              return accum;
            }
            accum.push(file);
            return accum;
          }, []);
          if (files.length) {
            formData.append('files', JSON.stringify(files));
          }
          return;
        }
        if (typeof values[item] === 'object') {
          formData.append(item, JSON.stringify(values[item]));
          return;
        }
        if (values[item] !== undefined) {
          formData.append(item, values[item]);
        }
      });
      formData.append('campaignGroupId', campaignGroupId);
      formData.append('userId', id);
      formData.append('podcastId', podcastId);
      formData.append('advertiserId', advertiserId);
      formData.append('podcastDescription', feed.description);
      formData.append(
        'audienceSuggestions',
        audienceSuggestions.correctList && JSON.stringify(audienceSuggestions?.correctList)
      );
      formData.append(
        'interestSuggestions',
        interestSuggestions.correctList && JSON.stringify(interestSuggestions?.correctList)
      );
      setLoading(true);
      addCampaigns(formData)
        .then((response) => {
          setClickUrl(() => response.click_url);
          setAdvertiser(() => response.advertiser);
          setLoading(() => false);
          setModalOpen(() => true);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(err?.response?.data?.message);
        });
    },
  });

  useEffect(() => () => {
    dispatch(resetSuggestions());
    dispatch(removeFeed());
  }, []);

  useEffect(() => {
    if (pickedUser?.podcasts.length) {
      const currentPodcast = pickedUser.podcasts.find((item) => item._id === podcastId);
      if (currentPodcast) {
        setPodcast(currentPodcast);
        formik.setFieldValue('enterpriseBoostSettings.rss', currentPodcast.podcastUrl);
        handleGettingRssFeed(currentPodcast.podcastUrl);
      }
    }
  }, [pickedUser]);

  useEffect(() => {
    if (feed && type) {
      const img = feed?.image?.url || feed?.itunes?.image;
      setImageUrl(img);
      formik.setFieldValue('enterpriseBoostSettings.mainImage', img);
      formik.setFieldValue('title', feed.title);
      formik.setFieldValue('subtitle', removeHTMLFromString(feed.description));
      handleGettingSuggestions({ searchQuery: feed.description, type });
    }
  }, [feed]);

  useEffect(() => {
    if (type) {
      formik.setFieldValue('creatingImagesWay', 'generate');
      formik.setFieldValue('type', type);
    }
    getLineItemById({ id: campaignGroupId })
      .then((response) => {
        dispatch(setCampaignGroup(response));
      })
      .catch((err) => {
        const { data } = err.response;
        if (Array.isArray(data)) {
          data.forEach((item) => toast.error(item.msg));
        }
      });
    setShow(true);
  }, []);

  const getSubtitle = type
    ? feed?.description
    : formik.values?.episode?.itunes?.subtitle ||
      formik.values?.episode?.itunes?.summary ||
      formik.values?.episode?.contentSnippet;

  useEffect(() => {
    dispatch(resetSuggestions());
    if (formik.values.episode.title) {
      handleGettingSuggestions({ searchQuery: getSubtitle, type });
    }
  }, [formik.values.episode]);

  const getImgUrl = (file) => (validURL(file.url) ? file.url : URL.createObjectURL(file));

  const handlerFiles = (files) => {
    if (formik.values.files.length >= MAX_FILES) {
      alert('Max 3 files');
      return;
    }
    const validFiles = [];
    let arrFiles = [...files];
    arrFiles.forEach((file) => {
      if (file.size > TEN_MB || !MIME_TYPES.includes(file.type)) {
        return;
      }
      validFiles.push(file);
    });
    const remainder = MAX_FILES - formik.values.files.length;
    formik.setFieldValue('files', [...validFiles.splice(0, remainder), ...formik.values.files]);
  };

  const onDrop = useCallback(handlerFiles, [formik.values.files.length]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const deleteFile = (i) => {
    const files = [...formik.values.files];
    files.splice(i, 1);
    formik.setFieldValue('files', files);
  };

  const handleClose = () => history.push(`/users/${id}/podcasts/${podcast._id}/edit`);

  const handleCheckBox = (field, value) => formik.setFieldValue(field, value);
  const { retargetingCampaign } = formik.values;

  const getTitle = type
    ? `Add Apple Sub Campaign for ${feed?.title}`
    : `Add a Campaign for ${campaignGroup?.name} | ${campaignGroup?.id}`;

  const renderImages = (hasCustomImages) =>
    sizes.map((size, i) => (
      <Grid
        key={size}
        container
        direction="column"
        className={classes.wrapper}
        item
        md={4}
        justifyContent="center"
        alignItems="center"
      >
        <Typography>{size}</Typography>
        <LoadingImage
          withCustom={hasCustomImages}
          key={size}
          width="100%"
          alt={size}
          type={type}
          title={formik.values.title}
          imageUrl={hasCustomImages ? URL.createObjectURL(formik.values.files[i]) : imageUrl}
          size={size}
        />
      </Grid>
    ));

  useEffect(() => {
    if (!type) {
      formik.setFieldValue('showEpisodeSelect', true);
    }
  }, []);

  const generatedImages = formik.values.creatingImagesWay === 'generate';

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={cx(classes.podcastForm)}>
          <Grid container item md={12} className={classes.wrapper} alignItems="center">
            <Grid className={classes.inputSection} container item md={12} alignItems="center">
              <Typography className={classes.title}>{getTitle}</Typography>
            </Grid>
            <Grid container item md={12} alignItems="center">
              <Episodes setImageUrl={setImageUrl} classes={classes} />
              <Title classes={classes} getSubtitle={getSubtitle} />
              <Subtitle classes={classes} getSubtitle={getSubtitle} />
              <CustomUrl classes={classes} />
              {type && <AdjustingLandingPage classes={classes} />}
              <Grid container item md={3} className={classes.inputSection} mt={2}>
                <DateChooser title="Start" field="start_date" classes={classes} />
              </Grid>
              <Grid container className={classes.inputSection} item md={3} mt={2}>
                <DateChooser title="End" field="end_date" classes={classes} />
              </Grid>
              <Grid container item md={6} className={classes.inputSection}>
                <OfferId />
              </Grid>
              <Geos classes={classes} />
            </Grid>
            <Grid container className={classes.inputSection} item md={12}>
              <Typography variant="h5">Demographic Targeting</Typography>
            </Grid>
            <DemographicTargeting classes={classes} title="Gender" dataType="gender" />
            <DemographicTargeting classes={classes} title="Age" dataType="age" />
            <DemographicTargeting
              classes={classes}
              title="Household Income"
              dataType="householdIncome"
            />
            <CustomSegments classes={classes} />
            <Interests classes={classes} />
            <LookalikeAudience classes={classes} />
            <Grid container item md={12} justifyContent="center" alignItems="center">
              <Grid
                container
                item
                md={12}
                style={{ position: 'relative', padding: '0 15px 15px 0' }}
              >
                <Typography variant="h5">Files</Typography>
                {formik.errors.files && <FieldError text={formik.errors.files} withPosition />}
              </Grid>
              <Grid container item md={6} justifyContent="center" alignItems="center">
                <FormControlLabel
                  checked={generatedImages}
                  classes={{ label: classes.checkBoxLabel }}
                  control={<Checkbox color="primary" />}
                  onChange={() => formik.setFieldValue('creatingImagesWay', 'generate')}
                  label="Generate Images"
                  labelPlacement="start"
                />
              </Grid>
              <Grid container item md={6} justifyContent="center" alignItems="center">
                <FormControlLabel
                  checked={formik.values.creatingImagesWay === 'custom'}
                  classes={{ label: classes.checkBoxLabel }}
                  control={<Checkbox color="primary" />}
                  onChange={() => formik.setFieldValue('creatingImagesWay', 'custom')}
                  label="Custom Images"
                  labelPlacement="end"
                />
              </Grid>
              {formik.values.creatingImagesWay === 'custom' && (
                <Grid container item md={12} className={classes.inputSection} alignItems="center">
                  <Grid
                    {...getRootProps()}
                    className={classes.dropZone}
                    container
                    item
                    md={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className="images-input-block">
                      <label className="file-label">
                        {isDragActive
                          ? 'Drop the files here'
                          : 'Drop your images or click here to upload jpg, png, gif, svg, webp. Max 3 files. Max 10 MB'}
                      </label>
                    </div>
                    <input
                      {...getInputProps()}
                      type="file"
                      className="custom-file"
                      multiple
                      onChange={(e) => onDrop(e.currentTarget.files)}
                      accept={MIME_TYPES}
                    />
                  </Grid>
                </Grid>
              )}
              {formik.values.creatingImagesWay === 'generate' && (
                <Grid container item md={12} className={classes.wrapper}>
                  {imageUrl ? (
                    renderImages()
                  ) : (
                    <Typography variant="h6">Select an episode.</Typography>
                  )}
                </Grid>
              )}
            </Grid>
            {!type && (
              <Grid
                container
                item
                md={12}
                className={classes.inputSection}
                justifyContent="flex-end"
              >
                <FormControlLabel
                  checked={retargetingCampaign}
                  classes={{ label: classes.checkBoxLabel }}
                  control={<Checkbox color="primary" />}
                  onChange={() => handleCheckBox('retargetingCampaign', !retargetingCampaign)}
                  label="Create Retargeting Campaign?"
                  labelPlacement="start"
                />
              </Grid>
            )}
            {!generatedImages && (
              <Grid
                container
                item
                md={12}
                className={classes.wrapper}
                justifyContent="center"
                alignItems="center"
              >
                {formik.values.files.map((file, i) => (
                  <Grid
                    key={i}
                    className={classes.preview}
                    container
                    item
                    md={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton onClick={() => deleteFile(i)}>
                      <DeleteIcon className={classes.deleteBtn} fontSize="small" />
                    </IconButton>
                    <img src={getImgUrl(file)} width="100%" alt={`img${i}`} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            className={classes.btnSection}
            item
            md={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              className={cx(classes.button, classes.gray)}
              onClick={handleClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              className={cx(classes.button)}
            >
              {loading ? <CircularProgress color="info" size={20} /> : 'SAVE'}
            </Button>
          </Grid>
        </form>
      </FormikProvider>
      <SummaryModal
        open={isModalOpen}
        clickUrl={clickUrl}
        handleClose={handleModalClose}
        campaignFor={`${advertiser?.name} (ID: ${advertiserId})`}
        imageUrl={imageUrl}
        renderImages={renderImages}
        podcast={podcast}
        campaignGroupId={campaignGroup?.id}
        values={formik.values}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
        <div className={classes.backdropText}>CREATING CAMPAIGN. DO NOT LEAVE PAGE.</div>
      </Backdrop>
    </>
  );
}
