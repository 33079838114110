import React from 'react';
import cx from 'classnames';

export default function ProgressBar({ parentStep }) {
  return (
    <div className="progressbar-block">
      <div className="progressbar-block-row">
        <div className="progressbar-block-title">
          Create Account
        </div>
        <div className="progressbar">
          <>
            <div className={cx('progressbar-step', { active: parentStep === 0, done: parentStep > 0 })}>
              Company Details
            </div>
            <div className={cx('progressbar-step', { active: parentStep === 1, done: parentStep > 1 })}>
              User Details
            </div>
            <div className={cx('progressbar-step', { active: parentStep === 2, done: parentStep > 2 })}>
              Billing Details
            </div>
            <div className={cx('progressbar-step', { active: parentStep === 3, done: parentStep > 3 })}>
              First podcast!
            </div>
            <div className={cx('progressbar-label', { active: parentStep === 3 })}>
              DOWNLOAD
            </div>
            <div className={cx('progressbar-step remove-line', { active: parentStep === 4, done: parentStep > 4 })}>
              Download our latest W9
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
