import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import { selectStats } from '../../redux/slices/statsSlice';

const PodcastsCharts = () => {
  const [expanded, setExpanded] = useState(false);
  const {
    topPodcasts: { data, loading },
  } = useSelector(selectStats);

  const getDiffRank = (ranks) => {
    if (ranks.length < 2) return '';
    const [prev, curr] = ranks.slice(-2);
    const diffRank = +prev.rank - +curr.rank;
    if (diffRank === 0) return '';
    return diffRank < 0 ? `(down from #${prev.rank})` : `(up from #${prev.rank})`;
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const renderCharts = useCallback(() => {
    const sortedClone = structuredClone(data).sort((a, b) => {
      if (a.country === 'United States' && b.country !== 'United States') return -1;
      if (a.country !== 'United States' && b.country === 'United States') return 1;

      // If countries are the same, sort by the rank of the last element in historyRank
      const rankA = a.historyRank[a.historyRank.length - 1].rank;
      const rankB = b.historyRank[b.historyRank.length - 1].rank;
      return rankA - rankB;
    });
    const firstFive = sortedClone.slice(0, 5); // First 5 elements
    const rest = sortedClone.slice(5);

    const renderBox = (item) => (
      <Box bgcolor="#2b2b2b" mb="10px" key={item._id}>
        <Typography>
          #{item.rank} in {item.genre} in {item.country} {getDiffRank(item.historyRank)}
        </Typography>
      </Box>
    );

    const visible = firstFive.map(renderBox);
    const hidden = rest.map(renderBox);
    return (
      <>
        <Grid container direction="column" item md={12}>
          {visible}
        </Grid>
        {hidden.length > 0 && (
          <Grid container direction="column" item md={12}>
            <Collapse in={expanded}>
              <Grid container direction="column" item md={12}>
                {hidden}
              </Grid>
            </Collapse>
            <Button onClick={handleChange}>{expanded ? 'Hide' : 'Show More'}</Button>
          </Grid>
        )}
      </>
    );
  }, [data, expanded]);

  return (
    <>
      {loading ? (
        <Grid container item md={12} justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container item md={12}>
          {renderCharts()}
        </Grid>
      )}
    </>
  );
};

export default PodcastsCharts;
