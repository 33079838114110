import axios from 'axios';

const axiosInterceptor = axios.create();

const publickPages = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/confirm-email',
  '/register',
];

axiosInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const currentPath = window.location.pathname;

      if (!publickPages.includes(currentPath)) {
        window.location = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
