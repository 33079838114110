import React, {
  useContext, useEffect, useState,
} from 'react';
import { NoSsr } from '@mui/material';
import { Field, FormikContext } from 'formik';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { CountriesState } from '../../../../api/CountriesState';
import { CountriesAndCodes } from '../../../../api/CountriesAndCodes';
import { selectLoginedUser } from '../../../../redux/slices/loginedUserSlice';

export default function CompanyDetails({ renderError }) {
  const classes = useStyles();
  const formik = useContext(FormikContext);
  const { isAdmin } = useSelector(selectLoginedUser);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [codesOfCountries, setCodesOfCountries] = useState([]);
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneMask, setPhoneMask] = useState(`${countryCode}-999-999-9999`);
  useEffect(() => {
    let country = CountriesState.find((item) => item.iso3.startsWith(formik.values.country) || item.name === formik.values.country);
    if (!country) {
      country = CountriesState.find((item) => item.iso3 === 'USA');
    }
    formik.setFieldValue('country', country.name);
    if (!countries.length) setCountries(CountriesState);
    setStates(country.states);
    setCodesOfCountries(CountriesAndCodes);
  }, []);

  useEffect(() => {
    if (countries.length) {
      const country = countries.find((item) => item.iso3.startsWith(formik.values.country) || item.name === formik.values.country);
      const code = codesOfCountries.find((item) => item.name === country.name);
      formik.setFieldValue('phone', formik.values.phone.replace(`${countryCode}`, `${(code && code.dial_code) || '+1'}`));
      setCountryCode((code && code.dial_code.replace(/9/g, '\\9')) || '+1');
      setStates(country.states);
      formik.setFieldValue('region', country.states.length ? country.states[0].name : '');
    }
  }, [formik.values.country, countries]);

  useEffect(() => {
    if (countryCode.length === 4) {
      setPhoneMask(`${countryCode}-999-999-999`);
    } else {
      setPhoneMask(`${countryCode}-999-999-9999`);
    }
  }, [countryCode]);

  const reCaptcha = (value) => {
    formik.setFieldValue('reCaptcha', !!value);
  };

  return (
    <>
      <div className="register-step-title">
        Company Details
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.company })}>
          <div className="label">
            <label>Company Name</label>
          </div>
          <Field
            disabled={isAdmin}
            type="text"
            name="company"
            onBlur={() => formik.validateField('company')}
            className="register-form-input"
            placeholder="Company name here"
          />
          {formik.errors.company && renderError(formik.errors.company)}
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.address1 })}>
          <div className="label">
            <label>Address 1</label>
          </div>
          <Field
            disabled={isAdmin}
            type="text"
            name="address1"
            className="register-form-input"
            onBlur={() => formik.validateField('address1')}
            placeholder="Your address here"
          />
          {formik.errors.address1 && renderError(formik.errors.address1)}
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.address2 })}>
          <div className="label">
            <label>Address 2</label>
          </div>
          <Field
            disabled={isAdmin}
            type="text"
            name="address2"
            onBlur={() => formik.validateField('address2')}
            className="register-form-input"
            placeholder="Your address here"
          />
          {formik.errors.address2 && renderError(formik.errors.address2)}
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.city })}>
          <div className="label">
            <label>City</label>
          </div>
          <Field
            disabled={isAdmin}
            type="text"
            name="city"
            onBlur={() => formik.validateField('city')}
            className="register-form-input"
            placeholder="New York"
          />
          {formik.errors.city && renderError(formik.errors.city)}
        </div>
        <div className={cx('register-form-block', { error: formik.errors.region })}>
          <div className="label">
            <label>State</label>
          </div>
          <NoSsr>
            <Select
              closeMenuOnSelect
              options={states.map((item) => ({ label: item.name, value: item.name }))}
              className={classes.select}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: 15,
                  height: 50,
                }),
                menu: (styles) => ({
                  ...styles,
                  zIndex: 1501,
                }),
              }}
              value={{ label: formik.values.region, value: formik.values.region }}
              onChange={({ value }) => formik.setFieldValue('region', value)}
              isSearchable
              noOptionsMessage={() => 'No states'}
            />
          </NoSsr>
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.zipcode })}>
          <div className="label">
            <label>Zip Code</label>
          </div>
          <Field
            disabled={isAdmin}
            type="text"
            name="zipcode"
            onBlur={() => formik.validateField('zipcode')}
            className="register-form-input"
            placeholder="00000"
          />
          {formik.errors.zipcode && renderError(formik.errors.zipcode)}
        </div>
        <div className={cx('register-form-block', { error: formik.errors.country })}>
          <div className="label">
            <label>Country</label>
          </div>
          <NoSsr>
            <Select
              closeMenuOnSelect
              options={countries.map((item) => ({ label: item.name, value: item.name }))}
              className={classes.select}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: 15,
                  height: 50,
                }),
                menu: (styles) => ({
                  ...styles,
                  zIndex: 1501,
                }),
              }}
              defaultValue={{ label: 'United States', value: 'United States' }}
              onChange={({ value }) => formik.setFieldValue('country', value)}
              isSearchable
            />
          </NoSsr>
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.phone })}>
          <div className="label">
            <label>Phone</label>
          </div>
          <InputMask
            disabled={isAdmin}
            mask={phoneMask}
            name="phone"
            alwaysShowMask={false}
            onChange={(e) => {
              formik.setFieldValue('phone', e.currentTarget.value);
            }}
            onBlur={() => formik.validateField('phone')}
            value={formik.values.phone}
            className="register-form-input"
            placeholder={`${countryCode}-543-175-4301`}
          />
          {formik.errors.phone && renderError(formik.errors.phone)}
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block')}>
          <ReCAPTCHA
            sitekey="6LfZOWcgAAAAAB0_xFw_RDaz5Y1r9eO5zYslsIDd"
            onChange={reCaptcha}
            theme="dark"
          />
          <div className={cx('register-form-block', { [classes.error]: formik.errors.reCaptcha })}>
            {formik.errors.reCaptcha}
          </div>
        </div>
      </div>
    </>
  );
}
