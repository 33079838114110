export function duplicateCampaignReducer(state, action) {
  switch (action.type) {
    case 'SET_DUPLICATE_CAMPAIGN_GROUP':
      return {
        ...state,
        duplicateCampaignGroup: action.data,
      };
    case 'SET_DUPLICATE_CAMPAIGN':
      return {
        ...state,
        duplicateCampaign: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
