import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  filesList: {
    display: 'flex',
    alignItems: 'center',
  },
  fileImg: {
    height: 48,
    marginRight: 30,
  },
  inputSection: {
    position: 'relative',
  },
  errorIcon: {
    position: 'absolute',
    top: 13,
    right: 15,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
    position: 'absolute',
    right: 0,
    bottom: 56,
  },
}));
