import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { FormikContext } from 'formik';
import React, { useContext } from 'react';
import * as demographicTargeting from '../../pages/Campaigns/const';

const DemographicTargeting = ({ classes, title, dataType }) => {
  const { values, setFieldValue } = useContext(FormikContext);

  const handleDemos = (e) => {
    const { value, checked } = e.target;
    const demos = [...values.demos];
    if (checked) {
      return setFieldValue('demos', [...demos, value]);
    }
    const index = demos.indexOf(value);
    if (index > -1) {
      demos.splice(index, 1);
    }
    return setFieldValue('demos', demos);
  };

  return (
    <>
      <Grid container item md={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid container className={classes.inputSection} item md={12}>
        {demographicTargeting[dataType].map((props, i) => (
          <Grid key={`${props.value}${i}`} container item md={3}>
            <FormControlLabel
              {...props}
              checked={values.demos.includes(props.value)}
              classes={{ label: classes.checkBoxLabel }}
              control={<Checkbox color="primary" />}
              onChange={handleDemos}
              labelPlacement="end"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DemographicTargeting;
