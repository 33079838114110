/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

export const LoadingImage = ({ alt, width, height, imageUrl, type, title, size, withCustom }) => {
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
  }, []);

  const [debounced, setDebounced] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounced(true);
      setLoading(true);
    }, 500);

    return () => {
      clearTimeout(timer);
      setDebounced(false);
    };
  }, [title]);

  const getGeneratedImageUrl = (size, title) =>
    withCustom
      ? imageUrl
      : `/api/campaigns/generate-image?imageUrl=${encodeURIComponent(
          imageUrl
        )}&size=${size}&title=${title}&type=${type || ''}`;

  return (
    <>
      <img
        style={{ display: loading ? 'none' : 'block' }}
        alt={alt}
        src={debounced ? getGeneratedImageUrl(size, title) : ''}
        width={width}
        height={height}
        onLoad={imageLoaded}
      />
      {loading && <CircularProgress />}
    </>
  );
};
