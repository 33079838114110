import { Button, Grid, NoSsr, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import { useHistory, useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import {
  getAdvertiserById,
  updaveAdvertiser,
  updaveAdvertiserByAdmin,
} from '../../api/advertisers';
import { useCountries } from '../../contexts/Countries';
import { useStyle } from './styles';
import { initialValuesCompanyDetails } from '../register/steps/CompanyDetails/initialValues';
import { validationSchema } from './validationSchema';
import FieldError from '../FieldError';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';

export default function AdvertiserInfo() {
  const classes = useStyle();
  const history = useHistory();
  const { user } = useSelector(selectLoginedUser);
  const { countries, codesOfCountries, loading } = useCountries();
  const [isLoaded, setIsLoaded] = useState(false);
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneMask, setPhoneMask] = useState(`${countryCode}-999-999-9999`);
  const { id } = useParams();
  const formik = useFormik({
    initialValues: initialValuesCompanyDetails,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (id) return updaveAdvertiserByAdmin(id, values).then(() => history.push(`/users/${id}`));
      updaveAdvertiser({ ...values, id }).then(() => history.push('/user'));
    },
  });

  useEffect(() => {
    getAdvertiserById(id || user._id).then((result) => {
      const country = countries.find(
        (item) =>
          item.iso3.startsWith(formik.values.country) || item.name === result.Advertiser.country
      );
      formik.setValues({
        company: result.Advertiser.company || '',
        address1: result.Advertiser.address1 || '',
        address2: result.Advertiser.address2 || '',
        city: result.Advertiser.city || '',
        country: country ? country.name : 'United States',
        zipcode: result.Advertiser.zipcode || '',
        region: result.Advertiser.region || '',
        phone: result.Advertiser.phone || '',
      });
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    const country = countries.find(
      (item) => item.iso3.startsWith(formik.values.country) || item.name === formik.values.country
    );
    const code = codesOfCountries.find((item) => item.name === country.name);
    formik.setFieldValue(
      'phone',
      formik.values.phone.replace(`${countryCode}`, `${(code && code.dial_code) || '+1'}`)
    );
    setCountryCode((code && code.dial_code) || '+1');
    if (!country.states.length) {
      formik.setFieldValue('region', '');
    } else {
      const state = country.states.find((item) => formik.values.region === item.name);
      formik.setFieldValue('region', state ? state.name : country.states[0].name);
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (countryCode.length === 4) {
      setPhoneMask(`${countryCode}-999-999-999`);
    } else {
      setPhoneMask(`${countryCode}-999-999-9999`);
    }
  }, [countryCode]);

  const getStates = () => {
    const country = countries.find((item) => item.name === formik.values.country);
    return country ? country.states.map((item) => ({ label: item.name, value: item.name })) : [];
  };

  return (
    isLoaded &&
    !loading && (
      <FormikProvider value={formik}>
        <Grid container direction="column" item md={12} justifyContent="center">
          <form onSubmit={formik.handleSubmit} className="register-form-container">
            <Grid className={classes.titleSection} container item md={12}>
              <Typography className={classes.title}>Advertiser Details</Typography>
            </Grid>
            <Grid
              className={classes.dataSection}
              container
              item
              md={12}
              justifyContent="space-between"
            >
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Company
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <Field
                    type="text"
                    name="company"
                    onBlur={() => formik.validateField('company')}
                    className="register-form-input"
                    placeholder="Company name here"
                  />
                  {formik.errors.company && <FieldError text={formik.errors.company} />}
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Address 1
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <Field
                    type="text"
                    name="address1"
                    className="register-form-input"
                    onBlur={() => formik.validateField('address1')}
                    placeholder="Your address here"
                  />
                  {formik.errors.address1 && <FieldError text={formik.errors.address1} />}
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Address 2
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <Field
                    type="text"
                    name="address2"
                    onBlur={() => formik.validateField('address2')}
                    className="register-form-input"
                    placeholder="Your address here"
                  />
                  {formik.errors.address2 && <FieldError text={formik.errors.address2} />}
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    City
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <Field
                    type="text"
                    name="city"
                    onBlur={() => formik.validateField('city')}
                    className="register-form-input"
                    placeholder="New York"
                  />
                  {formik.errors.city && <FieldError text={formik.errors.city} />}
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    State
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <NoSsr>
                    <Select
                      closeMenuOnSelect
                      hideSelectedOptions
                      options={getStates()}
                      className={classes.select}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 15,
                          height: 50,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          zIndex: 1501,
                        }),
                      }}
                      value={{ label: formik.values.region, value: formik.values.region }}
                      defaultValue={{ label: formik.values.region, value: formik.values.region }}
                      onChange={({ value }) => formik.setFieldValue('region', value)}
                      isSearchable
                      noOptionsMessage={() => 'No states'}
                    />
                  </NoSsr>
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Zip Code
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <Field
                    type="text"
                    name="zipcode"
                    onBlur={() => formik.validateField('zipcode')}
                    className="register-form-input"
                    placeholder="00000"
                  />
                  {formik.errors.zipcode && <FieldError text={formik.errors.zipcode} />}
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Country
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <NoSsr>
                    <Select
                      closeMenuOnSelect
                      hideSelectedOptions
                      options={countries.map((item) => ({ label: item.name, value: item.name }))}
                      className={classes.select}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 15,
                          height: 50,
                        }),
                        menu: (styles) => ({
                          ...styles,
                          zIndex: 1501,
                        }),
                      }}
                      value={{ label: formik.values.country, value: formik.values.country }}
                      defaultValue={{ label: 'United States', value: 'United States' }}
                      onChange={({ value }) => formik.setFieldValue('country', value)}
                      isSearchable
                    />
                  </NoSsr>
                </Grid>
              </Grid>
              <Grid container item md={6}>
                <Grid container item md={12}>
                  <Typography className={classes.fieldTitle} variant="h6">
                    Phone
                  </Typography>
                </Grid>
                <Grid className={classes.inputSection} container item md={10}>
                  <InputMask
                    mask={phoneMask}
                    name="phone"
                    alwaysShowMask={false}
                    onChange={(e) => {
                      formik.setFieldValue('phone', e.currentTarget.value);
                    }}
                    onBlur={() => formik.validateField('phone')}
                    value={formik.values.phone}
                    className="register-form-input"
                    placeholder={`${countryCode}-543-175-4301`}
                  />
                  {formik.errors.phone && <FieldError text={formik.errors.phone} />}
                </Grid>
              </Grid>
              <Grid
                className={classes.btnSection}
                container
                item
                md={11}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  className={cx('button register-form-button', classes.btn)}
                  fullWidth
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </FormikProvider>
    )
  );
}
