import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import Campaign from './Campaigns/Campaign';
import AddCampaignGroup from './CampaignGroup/Add';

export default function CreationCampaignGroup() {
  const match = useRouteMatch();

  return (
    <>
      <Route
        component={Campaign}
        key="add-campaign"
        path={`${match.path}/advertiser/:advertiserId/campaign-group/:campaignGroupId/add/:type?`}
        exact
      />
      <Route
        component={AddCampaignGroup}
        key="add-campaign-group"
        path={`${match.path}/advertiser/:advertiserId/campaign-group/add/:type?`}
        exact
      />
    </>
  );
}
