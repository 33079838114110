import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouteMatch, useLocation, Link, Route } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import UserInfo from '../components/UserInfo';
import Pricing from '../components/Pricing';
import AdvertiserInfo from '../components/AdvertiserInfo';
import { selectLoginedUser } from '../redux/slices/loginedUserSlice';
import BillingInfo from '../components/BillingInfo';

export const useStyle = makeStyles((theme) => ({
  wrapper: {
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
    },
  },
  titleSection: {
    paddingBottom: 25,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  boxTitle: {
    width: '100%',
  },
  active: {
    borderBottom: '#fff solid 3px',
    color: '#1599c4',
  },
}));

export default function Info() {
  const classes = useStyle();
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const goTo = (dist) => `${match.url}/${dist}`;
  return (
    <>
      <Grid className={classes.wrapper} container direction="column" item md={12}>
        <Grid className={classes.titleSection} container item md={12} justifyContent="center">
          <Grid container item md={3} justifyContent="center">
            <Link to={goTo('edit')}>
              <Typography
                className={cx(classes.title, { [classes.active]: pathname.includes('edit') })}
              >
                User Details
              </Typography>
            </Link>
          </Grid>
          <Grid container item md={3} justifyContent="center">
            <Link to={goTo('billing')}>
              <Typography
                className={cx(classes.title, { [classes.active]: pathname.includes('billing') })}
              >
                Billing
              </Typography>
            </Link>
          </Grid>
          <Grid container item md={3} justifyContent="center">
            <Link to={goTo('advertiser')}>
              <Typography
                className={cx(classes.title, { [classes.active]: pathname.includes('advertiser') })}
              >
                Advertiser
              </Typography>
            </Link>
          </Grid>
          <Grid container item md={3} justifyContent="center">
            <Link to={goTo('pricing')}>
              <Typography
                className={cx(classes.title, { [classes.active]: pathname.includes('pricing') })}
              >
                Pricing
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Route component={UserInfo} path={`${match.path}/edit`} exact />
        <Route component={AdvertiserInfo} path={`${match.path}/advertiser`} exact />
        <Route component={BillingInfo} path={`${match.path}/billing`} exact />
        <Route component={Pricing} path={`${match.path}/pricing`} exact />
      </Grid>
    </>
  );
}
