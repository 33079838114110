import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { Button, CircularProgress, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { selectStats, setDateRange } from '../../redux/slices/statsSlice';
import { getSetupTopPodcastsChart } from '../../utils/charts';

import { useStyles } from './styles';

const options = {
  responsive: true,
  interaction: {
    mode: 'x',
    intersect: false,
  },
  plugins: {
    legend: { labels: { color: '#b4b4b4' } },
  },
  stacked: false,
  scales: {
    x: {
      ticks: {
        color: '#b4b4b4',
      },
      grid: {
        drawBorder: false,
        color: () => '#232222',
      },
    },
    y: {
      reverse: true,
      max: 200,
      min: 1,
      ticks: {
        color: '#b4b4b4',
      },
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        drawBorder: false,
        color: () => '#232222',
      },
    },
  },
};

const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};

const dateRageButtons = [
  {
    label: '7d',
    value: '7d',
    dateRange: {
      startDate: moment().subtract(7, 'days').format(),
      endDate: moment().format(),
    }
  },
  {
    label: '30d',
    value: '30d',
    dateRange: {
      startDate: moment().subtract(30, 'days').format(),
      endDate: moment().format(),
    }
  },
  {
    label: '6mo',
    value: '6mo',
    dateRange: {
      startDate: moment().subtract(6, 'months').format(),
      endDate: moment().format(),
    }
  },
  {
    label: '12mo',
    value: '12mo',
    dateRange: {
      startDate: moment().subtract(12, 'months').format(),
      endDate: moment().format(),
    }
  },
];

const PodcastsLineCharts = () => {
  const classes = useStyles();
  const [chartData, setChartData] = useState({ ...initialState });
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [chosenCountry, setChosenCountry] = useState('United States');
  const {
    topPodcasts: { data, loading },
  } = useSelector(selectStats);
  const chart = useRef(null);

  const [activeDateRangeBtn, setActiveDateRangeBtn] = useState('7d');

  useEffect(() => {
    if (!data?.length) return;
    const countries = [...new Set(data.map((item) => item.country))];

    const sortedCountries = countries.includes('United States')
      ? ['United States', ...countries.filter((country) => country !== 'United States')]
      : countries;
    setChosenCountry(sortedCountries[0]);
    setCountries(sortedCountries);
    const setup = getSetupTopPodcastsChart(data, sortedCountries[0]);
    options.plugins.legend.display = setup.datasets.length !== 1;
    setChartData(setup);
  }, [data]);

  const handleChangeCountry = (event) => {
    setChosenCountry(event.target.value);
    const setup = getSetupTopPodcastsChart(data, event.target.value);
    options.plugins.legend.display = setup.datasets.length !== 1;
    setChartData(setup);
  };

  const changeDateRange = ({ dateRange, value }) => {
    dispatch(setDateRange(dateRange));
    setActiveDateRangeBtn(value);
  };

  const renderChart = () =>
    (loading ? (
      <Grid container item md={12} justifyContent="center">
        <CircularProgress />
      </Grid>
    ) : (
      <>
        <Grid container item md={12}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120, maxWidth: 120 }}>
            <Select
              defaultValue={chosenCountry}
              value={chosenCountry}
              onChange={handleChangeCountry}
            >
              {countries.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {dateRageButtons.map((date) => (
            <Button
              key={date.value}
              className={cx(classes.dateRageBtn, { active: (activeDateRangeBtn === date.value) })}
              type="button"
              onClick={() => changeDateRange(date)}
            >
              {date.label}
            </Button>))}
        </Grid>
        <Grid container item md={12}>
          <Line ref={chart} data={chartData} options={options} />
        </Grid>
      </>
    ));

  const renderNoData = () => (
    <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
      No Charting Data For This Podcast
    </Typography>
  );

  return !data.length && !loading ? renderNoData() : renderChart();
};

export default PodcastsLineCharts;
