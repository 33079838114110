const sitebarItems = [
  {
    label: 'All Users',
    isOpen: true,
    getRoute: () => '/users',
    role: ['admin'],
    subItems: [],
  },
  {
    label: 'User',
    isOpen: false,
    hasArrow: false,
    isUserPicked: true,
    role: ['admin'],
    getRoute: ({ id }) => `/users/${id}`,
    subItems: [],
  },
  {
    label: 'Podcasts',
    isOpen: true,
    role: [],
    getRoute: ({ isAdmin, id }) => (isAdmin ? `/users/${id}/podcasts` : '/podcasts'),
    subItems: [
      {
        label: '+ Add Podcast',
        route: '/podcasts/add',
        role: [],
      },
    ],
  },
];

export default sitebarItems;
