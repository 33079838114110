import React from 'react';
import { Grid, Typography } from '@mui/material';
import Content from './Content';

import { useStyles } from './styles';

export default function TermConditions() {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} container direction="row" item md={12} justifyContent="space-between" alignItems="flex-start">
      <Grid container direction="row" item md={12} justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h3">Terms of Use</Typography>
      </Grid>
      <Grid className={classes.content} container direction="row" item md={12} justifyContent="space-between" alignItems="flex-start">
        <Content />
      </Grid>
    </Grid>
  );
}
