import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { selectStack } from '../../../redux/slices/stackAdaptCampaignsSlice';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../pages/Users/styles';
import { formatDateToYYYYMMDD } from '../../../utils/utils';

const headCells = [
  {
    numeric: false,
    label: 'Campaign',
  },
  {
    numeric: false,
    label: 'Created on',
  },
  {
    numeric: true,
    label: 'Impressions',
  },
  {
    numeric: true,
    label: 'Clicks',
  },
  {
    numeric: true,
    label: 'Conversions',
  },
];
const CampaignList = () => {
  const classes = useStyles();
  const { campaignGroups } = useSelector(selectStack);
  const nameCellRef = useRef(null);
  const totalCellRef = useRef(null);
  const [cellWidth, setCellWidth] = useState(0);

  useEffect(() => {
    if (nameCellRef.current) {
      const elementWidth = nameCellRef.current.getBoundingClientRect().width;
      setCellWidth(elementWidth);
    }
  }, [nameCellRef.current]);

  useEffect(() => {
    if (totalCellRef.current) {
      totalCellRef.current.style.width = `${cellWidth}px`;
    }
  }, [cellWidth]);

  return (
    campaignGroups &&
    !!campaignGroups.length && (
      <>
        <TableContainer className={classes.tTableContainer} style={{ maxHeight: 400 }} component={Paper}>
          <Table stickyHeader className={classes.tTable}>
            <TableHead className={classes.tHead}>
              <TableRow style={{ border: 'none' }}>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.label}
                    align={headCell.numeric ? 'right' : 'left'}
                    className={classes.tHeader}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignGroups.map((item, i) => (
                <StyledTableRow key={`${item.name}-${i}`}>
                  <StyledTableCell component="td" scope="row" className={classes.tBodyItem} ref={nameCellRef}>
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.createdOn)}>
                    {formatDateToYYYYMMDD(item.flights.nodes[0].startTime)}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.tBodyItem}>
                    {item.deliveryStats.total.impressions}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.tBodyItem}>
                    {item.deliveryStats.total.clicks}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={classes.tBodyItem}>
                    {item.deliveryStats.total.conversions}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer style={{ overflowY: 'scroll' }}>
          <Table>
            <TableFooter>
              <StyledTableRow>
                <StyledTableCell
                  component="td"
                  scope="row"
                  className={classes.tBodyItem}
                  ref={totalCellRef}
                />
                <StyledTableCell
                  align="right"
                  className={cx(classes.tBodyItem, classes.totalImpressions, classes.createdOn)}
                />
                <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.totalImpressions)}>
                  {campaignGroups[0].advertiser.deliveryStats.total.impressions}
                </StyledTableCell>
                <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.totalClicks)}>
                  {campaignGroups[0].advertiser.deliveryStats.total.clicks}
                </StyledTableCell>
                <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.totalImpressions)}>
                  {campaignGroups[0].advertiser.deliveryStats.total.conversions}
                </StyledTableCell>
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    )
  );
};

export default CampaignList;
