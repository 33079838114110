import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: '#1c1b1b',
  },
  card: {
    backgroundColor: '#232222',
    margin: '0 10px 25px',
    borderRadius: 11,
    width: 228,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  cardContent: {
    paddingBottom: 5,
    marginTop: 'auto',
  },
  cardActionsContainer: {
    margin: 'auto 0 10px',
  },
  cardHeader: {
    position: 'relative',
    height: 30,
  },
  sectionStatus: {
    backgroundColor: '#0e0e0e',
    borderRadius: 10,
  },
  greenCircle: {
    color: '#7ed321',
    fontSize: 14,
  },
  status: {
    color: '#fff',
    fontSize: 10,
  },
  title: {
    color: '#fff',
    fontSize: 15,
    fontWeight: 'bold',
  },
  imgWrapper: {
    height: 219,

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  img: {
    width: '100%',
    maxHeight: 219,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
    },
  },
  btn: {
    color: '#fff',
    border: '1px solid #3f3f3f',
    textTransform: 'none',
    borderRadius: 3,
    padding: '9px 30px',
    fontSize: 10,
    margin: '0 10px 10px',
  },
  addPodcastCard: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    border: '1px dotted #fff',
    borderRadius: 17,
    padding: '118px 50px',
    fontWeight: 'bold',
    fontSize: 16,
  },
  bannerHolder: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 16,
    padding: '90px 0 89px',
  },
  menuBtn: {
    position: 'absolute',
    right: 0,
    color: '#fff',
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  imgHolder: {
    width: 150,
    opacity: '0.4',
  },
}));
