/* eslint-disable no-nested-ternary */
import _ from 'lodash';

const compareValues = (a, b) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return b - a;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return b.localeCompare(a);
  }
  if (typeof a === 'boolean' && typeof b === 'boolean') {
    return b === a ? 0 : a ? 1 : -1;
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (!orderBy) {
    return;
  }

  const getCampaignGroupLength = (campaignGroup) => campaignGroup?.campaigns?.length || 0;
  const getCampaignGroupStatus = (campaignGroup) => campaignGroup.campaignGroupStatus.status;

  let valueA;
  let valueB;

  switch (orderBy) {
    case 'campaigns':
      valueA = getCampaignGroupLength(a);
      valueB = getCampaignGroupLength(b);
      break;
    case 'status':
      valueA = getCampaignGroupStatus(a);
      valueB = getCampaignGroupStatus(b);
      break;
    default:
      valueA = _.get(a, orderBy, 0);
      valueB = _.get(b, orderBy, 0);
      break;
  }

  return compareValues(valueA, valueB);
};

export const getComparator = (order, orderBy) =>
  (order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const containsSearchValue = (item, fields, searchValue) =>
  fields.map((field) => _.get(item, field, '').toLowerCase().includes(searchValue)).some((i) => i === true);
