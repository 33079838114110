import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, FormikProvider, useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { resetPassword } from '../../api/auth';

export const useStyle = makeStyles({
  errorIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'center',
    width: 24,
    borderRadius: '50%',
    border: '#f00 solid 2px',
    color: '#f00',
    fontWeight: 'bold',
  },
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
  noMaxWidth: {
    backgroundColor: '#0000003b',
    maxWidth: 'none',
    margin: 0,
  },
});

export default function ForgotPasswordForm() {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const [isReseted, setIsReseted] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const queryParams = new URLSearchParams(location.search);
      resetPassword({ ...values, token: queryParams.get('token') })
        .then(() => {
          setIsReseted(true);
          setTimeout(() => {
            history.push('/login');
          }, 2000);
        })
        .catch((err) => {
          const errors = {};
          if (err.response.data) {
            err.response.data.forEach((e) => {
              errors[e.param] = e.msg;
            });
            formik.setErrors(errors);
          }
        });
    },
  });

  const renderError = (text) => (<Tooltip placement="bottom" classes={{ tooltip: classes.noMaxWidth }} open={!!text} title={(<Typography variant="caption" className={classes.error}>{text}</Typography>)}><div className={classes.errorIcon}>!</div></Tooltip>);

  return (
    <FormikProvider value={formik}>
      <div className="login-container">
        <div className="login-row">
          <div className="login-block">
            <Typography>Reset Password</Typography>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="login-form">
              <div className="login-form-row">
                <div className="login-form-block">
                  <Field type="password" placeholder="*********" name="password" className="login-form-input" required />
                  {formik.errors.password && renderError(formik.errors.password)}
                </div>
              </div>
              <div className="login-form-row">
                <div className="login-form-block">
                  <Field type="password" placeholder="*********" name="confirmPassword" className="login-form-input" required />
                  {formik.errors.confirmPassword && renderError(formik.errors.confirmPassword)}
                </div>
              </div>
              <div className="login-form-row">
                {isReseted && <Typography>Successful</Typography>}
              </div>
              <div className="login-form-row centered">
                <div className="login-form-block">
                  <button className="button" type="submit">
                    Submit
                  </button>
                  <Grid container direction="row" item md={12} justifyContent="center" alignItems="center">
                    <Button onClick={() => history.push('/login')}><Typography display="block" style={{ marginLeft: '10px' }} align="right">Back to Login</Typography></Button>
                  </Grid>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
}
