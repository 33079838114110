import { Button, Grid, Typography } from '@mui/material';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { updateUser, updateUserByAdmin } from '../../api/users';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

import { useStyle } from './styles';
import { usePodcasts } from '../../contexts/PodcastsContext';
import { useGettingData } from '../../hooks/useGettingData';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { selectUsers } from '../../redux/slices/usersSlice';

export default function BillingInfo() {
  const classes = useStyle();
  // const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const { pickedUser } = useSelector(selectUsers);
  const { isAdmin, user } = useSelector(selectLoginedUser);
  const { setShow } = usePodcasts();
  const { id } = useParams();
  const { handleGettingUser } = useGettingData();

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (isAdmin) {
        return updateUserByAdmin(id || user._id, { billingDetails: values })
          .then(async () => {
            await handleGettingUser(id);
            history.push(`/users/${id || ''}`);
          })
          .catch((err) => {
            const { data } = err.response;
            if (Array.isArray(data)) {
              data.forEach((item) => toast.error(item.msg));
            }
          });
      }
      return updateUser({ billingDetails: values }).then(async () => {
        await handleGettingUser(user._id);
        history.push('/user');
      });
    },
  });

  useEffect(() => {
    if (pickedUser) {
      formik.setValues({
        billingName: pickedUser?.billingDetails?.billingName,
        billingEmail: pickedUser?.billingDetails?.billingEmail,
        billingPhone: pickedUser?.billingDetails?.billingPhone,
        emailForInvoices: pickedUser?.billingDetails?.emailForInvoices,
      });
    }
  }, [pickedUser]);

  useEffect(() => {
    setShow(true);
  }, []);

  const renderError = (text) => (
    <>
      <Typography variant="caption" className={classes.error}>
        {text}
      </Typography>
      <div className={classes.errorIcon}>!</div>
    </>
  );

  return (
    pickedUser && (
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className="register-form-container">
          <Grid className={classes.titleSection} container item md={12}>
            <Typography className={classes.title}>Billing Details</Typography>
          </Grid>
          <Grid
            className={classes.dataSection}
            container
            item
            md={12}
            justifyContent="space-between"
          >
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Email
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="email"
                  name="billingEmail"
                  autoComplete="email"
                  onBlur={() => formik.validateField('billingEmail')}
                  className="register-form-input"
                  placeholder="Your email here"
                />
                {formik.errors.billingEmail && renderError(formik.errors.billingEmail)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Finance Contact Name
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="text"
                  name="billingName"
                  onBlur={() => formik.validateField('billingName')}
                  className="register-form-input"
                  placeholder="Joe"
                />
                {formik.errors.billingName && renderError(formik.errors.billingName)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Phone
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <InputMask
                  mask="+99-999-999-9999"
                  maskChar={null}
                  name="billingPhone"
                  alwaysShowMask={false}
                  onChange={(e) => {
                    formik.setFieldValue('billingPhone', e.currentTarget.value);
                  }}
                  onBlur={() => formik.validateField('billingPhone')}
                  value={formik.values.billingPhone}
                  className="register-form-input"
                  placeholder="+1-543-175-4301"
                />
                {formik.errors.billingPhone && renderError(formik.errors.billingPhone)}
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Grid container item md={12}>
                <Typography className={classes.fieldTitle} variant="h6">
                  Email for invoices
                </Typography>
              </Grid>
              <Grid className={classes.inputSection} container item md={10}>
                <Field
                  type="email"
                  name="emailForInvoices"
                  autoComplete="email"
                  onBlur={() => formik.validateField('emailForInvoices')}
                  className="register-form-input"
                  placeholder="Your email here"
                />
                {formik.errors.emailForInvoices && renderError(formik.errors.emailForInvoices)}
              </Grid>
            </Grid>
            <Grid
              className={classes.btnSection}
              container
              item
              md={11}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                className={cx('button register-form-button', classes.btn)}
                fullWidth
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    )
  );
}
