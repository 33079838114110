import * as yup from 'yup';

const rePhoneNumber = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{3,4})(( x| ext)\d{1,5}){0,1}$/;
const reCity = /^[aA-zZ\s]{2,}((-){1,}?[aA-zZ\s]{1,}?)*$/;
export const validationSchemaCompanyDetails = yup.object().shape({
  company: yup.string().max(80, 'Too long').min(3, 'Too short').required('Required'),
  address1: yup.string().required('Required'),
  address2: yup.string().default(''),
  city: yup.string().min(2, 'Too short')
    .matches(reCity, 'Incorrect city name')
    .required('Required'),
  region: yup.string(),
  zipcode: yup.string().required('Required'),
  phone: yup.string().required('Required').matches(rePhoneNumber, 'Phone number is not valid'),
  country: yup.string().required('Required'),
  reCaptcha: yup.boolean().oneOf([true], "The reCaptcha must be accepted."),
});
