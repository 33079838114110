import axios from 'axios';
import axiosInterceptor from './axiosInterceptor/index';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const pullOfferByName = async (name) => {
  if (cancelTokenSource.pullOfferByName) {
    cancelTokenSource.pullOfferByName.cancel('canceled');
  }
  const headers = getHeaders();
  cancelTokenSource.pullOfferByName = axios.CancelToken.source();
  const res = await axiosInterceptor.get('/api/tune/get-offer-by-name', {
    params: { name },
    headers,
    cancelToken: cancelTokenSource.pullOfferByName.token,
  });
  return res.data;
};
