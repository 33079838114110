/* eslint-disable no-bitwise */
import moment from 'moment';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');

    bodyLines.forEach((title, i) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;

      const colors = tooltip.labelColors[i];
      const div = document.createElement('div');
      div.innerHTML = title;
      div.style.color = colors.backgroundColor;
      div.style.fontSize = '16px';
      th.appendChild(div);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    titleLines.forEach((body) => {
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};

export const prepareDataChart = (statsData) => {
  const clicks = {
    data: [],
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  };
  const impressions = {
    data: [],
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  };
  const conversions = {
    data: [],
    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  };
  statsData.data.forEach((item) => {
    clicks.data.push({ x: item.Stat.date, y: item.Stat.clicks });
    impressions.data.push({ x: item.Stat.date, y: item.Stat.impressions });
    conversions.data.push({ x: item.Stat.date, y: item.Stat.conversions });
  });
  return { datasets: [{ ...clicks }, { ...impressions }, { ...conversions }] };
};

function getRandomBrightHexColor() {
  const r = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for red
  const g = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for green
  const b = Math.floor(Math.random() * 156) + 100; // Minimum value of 100 for blue
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export const getSetupTopPodcastsChart = (data, chosenCountry) => {
  const filteredData = data.filter(({ country }) => country === chosenCountry);
  const labels = [
    ...new Set([
      ...filteredData
        .map(({ historyRank }) => historyRank)
        .flat()
        .sort((a, b) => moment(a.date).diff(b.date))
        .map(({ date }) => moment(date).format('MMM DD')),
    ]),
  ];

  return filteredData.reduce(
    (accum, item) => {
      const color = getRandomBrightHexColor();
      accum.datasets.push({
        label: item.genre,
        data: item.historyRank.map(({ rank, date }) => ({
          x: moment(date).format('MMM DD'),
          y: rank,
        })),
        backgroundColor: color,
        borderColor: color,
      });
      return accum;
    },
    {
      labels,
      datasets: [],
    }
  );
};
