import { Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { addCampaignGroup } from '../../../api/stats';
import bgBtn from '../../../assets/images/button.png';
import bgBtnGray from '../../../assets/images/button-gray.png';
import { usePodcasts } from '../../../contexts/PodcastsContext';

import { useStyles } from './styles';
import { useGettingData } from '../../../hooks/useGettingData';
import { selectLoginedUser } from '../../../redux/slices/loginedUserSlice';
import { setDuplicateCampaignGroup } from '../../../redux/slices/rootSlice';
import { selectUsers } from '../../../redux/slices/usersSlice';
import { selectRssFeed } from '../../../redux/slices/rssSlice';

export default function AddCampaignGroup() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const dispatch = useDispatch();
  const { pickedUser } = useSelector(selectUsers);
  const { handleGettingRssFeed } = useGettingData();
  const { feed } = useSelector(selectRssFeed);
  const history = useHistory();
  const { isAdmin } = useSelector(selectLoginedUser);
  const { id, advertiserId, podcastId, type } = useParams();
  const { setShow } = usePodcasts();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Add a Campaign Group');

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      setLoading(true);
      const data = { ...formik.values };
      if (type) {
        data.pace_evenly = true;
        data.scaleBasedBudget = true;
      }
      return addCampaignGroup({ ...data, podcastId, userId: id })
        .then(({ campaignGroup }) => {
          history.push(
            `/users/${id}/podcasts/${podcastId}/advertiser/${advertiserId}/campaign-group/${
              campaignGroup.id
            }/add/${type || ''}`
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message);
        })
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    if (feed) {
      if (type) setTitle(`Add Apple Sub Campaign Group for ${feed.title}`);
      formik.setFieldValue('name', `${feed.title}${type ? ' : Apple Sub' : ''}`);
    }
  }, [type, feed]);

  useEffect(() => {
    localStorage.removeItem('duplicateCampaignGroup');
    if (pickedUser) {
      const podcast = pickedUser.podcasts.find((item) => item._id === podcastId);
      if (!feed) {
        handleGettingRssFeed(podcast.podcastUrl);
      }
    }
    formik.setFieldValue('advertiser_id', advertiserId);
    setShow(true);
  }, [pickedUser]);

  const handleBudget = (e) => {
    const { value } = e.target;
    formik.setFieldValue('budget', value.replace(/[^0-9]/g, ''));
  };

  const renderError = (text, isBudget) => (
    <>
      <Typography variant="caption" className={cx(classes.error, { [classes.budgetError]: isBudget })}>
        {text}
      </Typography>
      <div className={cx(classes.errorIcon, { [classes.budgetErrorIcon]: isBudget })}>!</div>
    </>
  );

  useEffect(
    () => () => {
      dispatch(setDuplicateCampaignGroup(null));
      formik.setFieldValue('name', '');
    },
    []
  );

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} autoComplete="off" className={cx(classes.podcastForm)}>
          <Grid container item md={12} className={classes.wrapper} alignItems="center">
            <Grid className={classes.inputSection} container item md={12}>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid className={cx(classes.inputSection, classes.inputSectionGroupName)} container item md={8}>
              <Grid className={classes.label} container item md={12} alignItems="center">
                <label style={{ paddingRight: 20 }}>
                  Campaign Group name <span style={{ color: 'red' }}>*</span>
                </label>
                <Tooltip title="The name of the Campaign Group. (Required)" placement="right">
                  <InfoOutlinedIcon />
                </Tooltip>
              </Grid>
              <Field
                type="text"
                name="name"
                className="register-form-input"
                placeholder="Campaign Group name here"
              />
              {formik.errors.name && renderError(formik.errors.name)}
            </Grid>
            <Grid className={classes.inputSection} container item md={4}>
              <Grid className={classes.label} container item md={12} alignItems="center">
                <label style={{ paddingRight: 20 }}>
                  Budget <span style={{ color: 'red' }}>*</span>
                </label>
                <Tooltip title="The budget of the Campaign Group. (Required)" placement="right">
                  <InfoOutlinedIcon />
                </Tooltip>
                {formik.errors.budget && renderError(formik.errors.budget, true)}
              </Grid>
              <Field
                type="text"
                name="budget"
                onChange={handleBudget}
                className="register-form-input"
                placeholder="The budget of the Campaign Group."
              />
            </Grid>
            <Grid
              container
              className={classes.btnSection}
              item
              md={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                className={cx(classes.button, classes.gray)}
                onClick={() => history.goBack()}
                type="button"
              >
                Cancel
              </Button>
              {isAdmin && (
                <Button variant="contained" type="submit" className={cx(classes.button)} disabled={loading}>
                  {loading ? <CircularProgress color="info" size={20} /> : 'SAVE'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
}
