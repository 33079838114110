export const initialValuesCompanyDetails = {
  company: '',
  address1: '',
  address2: '',
  city: '',
  region: 'Alabama',
  zipcode: '',
  phone: '',
  country: 'United States',
  reCaptcha: false,
};
