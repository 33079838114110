import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  origBtn: {
    fontSize: 12,
    padding: '0 10px',
    marginBottom: 2,
    '&:hover': {
      background: '#f56302',
    },
  },
  spinner: {
    padding: '0 10px 0 0',
  },
  origBtnLabel: {
    paddingTop: '0 !important',
    textAlign: 'center !important',
    minWidth: '0 !important',
    color: '#000',
  },
  btn: {
    backgroundColor: '#f8a11a',
    padding: 8,
  },
  active: {
    backgroundColor: '#f56302',
  },
  iconColor: {
    color: '#f8a11a',
  },
  disabledIconColor: {
    color: '#c6c6c67d',
  },
  activeIcon: {
    color: '#f56302',
  },
  marginBottom: {
    marginBottom: '0 !important',
  },
  overwrite: {
    paddingTop: '0 !important',
    textAlign: 'center !important',
    fontSize: '10px !important',
    fontWeight: 700,
    paddingLeft: 6,
    // alignSelf: 'flex-start',
  },
  question: {
    fontSize: 14,
  },
  disabled: {
    backgroundColor: '#c6c6c67d !important',
  },
  refreshButton: {
    minWidth: 30,
  },
}));
