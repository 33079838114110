import React, { useContext, useEffect, useState } from 'react';
import { CountriesState } from '../api/CountriesState';
import { CountriesAndCodes } from '../api/CountriesAndCodes';

const CountriesContext = React.createContext();
export const useCountries = () => useContext(CountriesContext);

export const CountriesProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [codesOfCountries, setCodesOfCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(false);
    setCodesOfCountries(CountriesAndCodes);
    setCountries(CountriesState);
  }, []);

  return (
    <CountriesContext.Provider value={{
      countries,
      codesOfCountries,
      loading,
    }}
    >
      {!loading && children}
    </CountriesContext.Provider>
  );
};
