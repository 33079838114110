import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Grid, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './styles';
import { isHS } from '../../utils/utils';
import Logo from '../../assets/images/logo.svg';
import HSLogo from '../../assets/images/HS.png';
import sitebarItems from './sitebarItems';
import { logout } from '../../api/auth';
import { usePodcasts } from '../../contexts/PodcastsContext';
import { removeloginedUser, selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { selectUsers } from '../../redux/slices/usersSlice';

import rainbow from '../../assets/images/rainbow.png';
import rainbowAdmin from '../../assets/images/admin.svg';

export default function Sidebar() {
  const location = useLocation();
  const { user, isAdmin } = useSelector(selectLoginedUser);
  const classes = useStyles({ rainbow: isAdmin ? rainbowAdmin : rainbow });
  const history = useHistory();
  const dispatch = useDispatch();
  const { pickedUser } = useSelector(selectUsers);
  const { show } = usePodcasts();
  const [currPodcasts, setCurrPodcasts] = useState([]);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setCurrPodcasts(pickedUser?.podcasts || []);
  }, [pickedUser]);

  const goToProfile = () => {
    setAnchorEl(null);
    history.push('/user');
  };

  const logOut = async () => {
    await logout();
    dispatch(removeloginedUser());
    setAnchorEl(null);
    history.push('/login');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () =>
    sitebarItems.map((item, i) => {
      if (!isAdmin && item.role.includes('admin')) return;
      if (item.label === 'Podcasts' && !show) return;
      if (item.isUserPicked && !pickedUser) return;
      const subItems = item.subItems.filter(
        (subItem) => !(subItem.role.length && !subItem.role.includes(user.role))
      );
      if (item.label === 'Podcasts') {
        subItems[0].route = `${isAdmin ? `/users/${pickedUser?._id}` : ''}/podcasts/add`;
        subItems.push(
          ...currPodcasts.map((podcast) => ({
            label: podcast.podcastName,
            route: `${isAdmin ? `/users/${pickedUser?._id}` : ''}/podcasts/${podcast._id}/edit`,
            role: ['user', 'admin'],
          }))
        );
      }
      const allUsersLinkIsNotActive = item.label === 'All Users' && location.pathname !== '/users';
      const companyName = pickedUser?.companyDetails?.company;
      const isUserLink = item.label === 'User';
      const userLinkText = `User: ${companyName}`;
      return (
        <li className="main-item" key={i}>
          <div className={cx('main-link')}>
            <NavLink to={item.getRoute({ id: pickedUser?._id, isAdmin })} activeClassName="active" exact>
              {allUsersLinkIsNotActive && <span>&lt; </span>}
              {isUserLink ? userLinkText : item.label}
            </NavLink>
          </div>
          <div className={cx({ [classes.podcasts]: item.label === 'Podcasts' })}>
            <ul className="sub" style={{ display: 'block' }}>
              {subItems.map((subItem, index) => (
                <li className="sub-item" style={{ marginBottom: 10 }} key={index}>
                  <NavLink to={subItem.route} activeClassName="active" className={cx(classes.subLink)}>
                    {subItem.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </li>
      );
    });

  const renderSidebarUser = (location) =>
    user && (
      <div className={cx('sidebar-user', { [location]: location })}>
        <span onClick={handleClick} className={classes.name}>
          <div className="sidebar-user-row">
            <div className="user-hello">
              <div className="hello-emoji" />
            </div>
            <div className="user-name">
              <span>Hey, {user.userDetails.firstName}</span>
            </div>
          </div>
        </span>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={goToProfile}>Profile</MenuItem>
          <MenuItem onClick={logOut}>Logout</MenuItem>
        </Menu>
      </div>
    );

  return (
    <div className={cx('sidebar', classes.sidebar)}>
      <Grid className="sidebar-row" container direction="column" item md={12} alignItems="center">
        <div className="sidebar-logo">
          <NavLink to={isAdmin ? '/users' : '/podcasts'}>
            <img src={Logo} className={classes.mainLogo} alt="mowpod-logo" />
            {isHS() && <img className={classes.hsLogo} src={HSLogo} alt="hs-logo" />}
          </NavLink>
        </div>
        <div onClick={() => setOpenMobileMenu(!openMobileMenu)} className="sidebar-nav-icon" />
        <div className={cx('sidebar-nav', { action: openMobileMenu })}>
          <div onClick={() => setOpenMobileMenu(!openMobileMenu)} className="sidebar-nav-icon-close" />
          <ul className="main">{renderMenu()}</ul>
          {renderSidebarUser('mob')}
          <ul className="main">
            <li className="main-item">
              <NavLink to="/terms-of-use" activeClassName="active" className={cx(classes.subLink)}>
                Terms of Use
              </NavLink>
            </li>
            <li className="main-item">
              <NavLink to="/brand-safety" activeClassName="active" className={cx(classes.subLink)}>
                Brand Safety
              </NavLink>
            </li>
          </ul>
          {renderSidebarUser()}
        </div>
      </Grid>
    </div>
  );
}
