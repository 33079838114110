import React, { useEffect } from 'react';
import { useRouteMatch, Route, useParams } from 'react-router-dom';
import { DuplicateCampaignProvide } from '../contexts/duplicateCampaignContext';
import CampaignGroupList from './CampaignGroup/List';
import CreationCampaignGroup from './CreationCampaignGroup';
import { SuggestionProvide } from '../contexts/SuggestionContext';
import Info from './Info';
import Podcast from './Podcast/Podcast';
import Podcasts from './Podcasts';
import User from './User';
import PodacastStats from '../components/PodcastStats';
import { AddCampaign } from './AddCampaign';
import { useGettingData } from '../hooks/useGettingData';

export default function Admin() {
  const match = useRouteMatch();
  const { id } = useParams();
  const { handleGettingUser } = useGettingData();
  useEffect(async () => {
    if (id) await handleGettingUser(id);
  }, []);

  return (
    <>
      <Route component={User} key="admin" path={`${match.path}`} exact />
      <Route component={Info} path={`${match.path}/info`} />
      <Route component={Podcasts} key="admin_podcasts" path={`${match.path}/podcasts`} exact />
      <Route component={Podcast} key="add-podcast-admin" path={`${match.path}/podcasts/add`} exact />
      <Route
        component={Podcast}
        key="admin-podcast"
        path={`${match.path}/podcasts/:podcastId/edit`}
        exact
      />
      <Route component={PodacastStats} path={`${match.path}/podcasts/:podcastId/advertiser/:advertiserId/stats`} exact />
      <Route component={AddCampaign} path={`${match.path}/podcasts/:podcastId/add-campaign`} exact />
      <Route
        component={CampaignGroupList}
        key="campaign-list"
        path={`${match.path}/advertiser/:advertiserId/campaign-group/list`}
        exact
      />
      <SuggestionProvide>
        <DuplicateCampaignProvide>
          <Route component={CreationCampaignGroup} path={`${match.path}/podcasts/:podcastId`} />
        </DuplicateCampaignProvide>
      </SuggestionProvide>
    </>
  );
}
