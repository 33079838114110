import React, { useEffect, useRef } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { usePodcasts } from '../../contexts/PodcastsContext';
import PodcastCard from '../../components/PodcastCard';
import { selectUsers } from '../../redux/slices/usersSlice';

export default function Podcasts() {
  const classes = useStyles();
  const { pickedUser } = useSelector(selectUsers);
  const { setShow } = usePodcasts();
  useEffect(() => {
    setShow(true);
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const updateCardsHeight = () => {
      if (containerRef.current) {
        const cards = containerRef.current.querySelectorAll('.card');
        let maxHeight = 0;

        cards.forEach((card) => {
          const height = card.clientHeight;
          maxHeight = Math.max(maxHeight, height);
        });

        cards.forEach((card) => {
          card.style.minHeight = `${maxHeight}px`;
        });
      }
    };

    updateCardsHeight();
  }, [pickedUser]);

  return (
    <Grid container item md={12} justifyContent="space-around">
      <Grid container item md={12}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          YOUR PODCASTS
        </Typography>
      </Grid>
      {pickedUser && (
        <Grid className={classes.wrapper} container item md={12} ref={containerRef}>
          {pickedUser.podcasts.map((podcast, i) => (
            <PodcastCard
              key={`${i}-${podcast.podcastName}`}
              podcast={podcast}
            />
          ))}
          <PodcastCard userId={pickedUser._id} />
        </Grid>
      )}
    </Grid>
  );
}
