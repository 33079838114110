/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import cx from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { editPodcast } from '../../api/users';
import earbud from '../../assets/images/earbud.png';
import { selectUsers } from '../../redux/slices/usersSlice';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { getRssFeed } from '../../api/rss';
import { useGettingData } from '../../hooks/useGettingData';

export default function PodcastCard({ podcast }) {
  const classes = useStyles();
  const history = useHistory();
  const { handleGettingUser } = useGettingData();
  const {
    pickedUser: { _id: userId, stackAdaptAdvertiser },
  } = useSelector(selectUsers);
  const { isAdmin } = useSelector(selectLoginedUser);
  const [image, setImage] = useState(null);
  const [advertiserMenu, setAdvertiserMenu] = React.useState(null);
  const [mousePosition, setMousePostion] = useState({
    mouseX: null,
    mouseY: null,
  });
  const goTo = (path) => {
    const route = `${isAdmin ? `/users/${userId}` : ''}${path}`;
    history.push(route);
  };

  const getImgFromRssFeed = async (url) => {
    const feed = await getRssFeed(url, true);
    return { Location: feed.image.url, name: feed.title };
  };

  useEffect(async () => {
    if (podcast) {
      const img =
        podcast.podcastFiles.find((item) => item.main) ||
        podcast.podcastFiles[0] ||
        podcast?.feedData?.image ||
        (await getImgFromRssFeed(podcast.podcastUrl));
      setImage(img);
    }
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    setMousePostion({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setMousePostion({
      mouseX: null,
      mouseY: null,
    });
  };

  const hendleChangeImg = (index) => {
    podcast.podcastFiles.forEach((val, i) => {
      podcast.podcastFiles[i].main = false;
    });
    podcast.podcastFiles[index].main = true;
    setImage(podcast.podcastFiles[index]);
    const formData = new FormData();

    for (let key in podcast) {
      if (key === 'podcastFiles') {
        formData.append(key, JSON.stringify(podcast[key]));
      } else {
        formData.append(key, podcast[key]);
      }
    }
    editPodcast({ podcastId: podcast._id, data: formData });
    setMousePostion({
      mouseX: null,
      mouseY: null,
    });
  };

  const handleCloseMenu = (advertiser) => async () => {
    setAdvertiserMenu(null);
    const formData = new FormData();
    formData.append('advertiser', JSON.stringify(advertiser));
    await editPodcast({ userId, podcastId: podcast._id, data: formData });
    await handleGettingUser(userId);
    const path = `/podcasts/${podcast._id}/advertiser/${advertiser.id}/stats`;
    goTo(path);
  };

  const renderStatsButton = () => {
    const buttonComponent = [];
    const advertiserId = podcast?.advertiser?.id || stackAdaptAdvertiser[0]?.id;
    const path = `/podcasts/${podcast._id}/advertiser/${advertiserId}/stats`;
    let handleClick = () => goTo(path);
    let menu;
    if (isAdmin && !podcast.advertiser && stackAdaptAdvertiser.length > 1) {
      handleClick = (event) => setAdvertiserMenu(event.currentTarget);
      menu = (
        <Menu
          key="stats-menu"
          id="basic-menu"
          anchorEl={advertiserMenu}
          open={!!advertiserMenu}
          onClose={() => setAdvertiserMenu(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {stackAdaptAdvertiser.map((item) => (<MenuItem key={item.id} onClick={handleCloseMenu(item)}>{item.name}</MenuItem>))}
        </Menu>
      );
    }
    if (stackAdaptAdvertiser && !!stackAdaptAdvertiser.length) {
      buttonComponent.push(
        <Button key="stats" className={classes.btn} onClick={handleClick}>
          Stats
        </Button>,
        menu
      );
    }
    return buttonComponent;
  };

  return (
    <Grid container direction="row" item md={3} justifyContent="center" alignItems="center">
      <Card className={cx(classes.card, 'card')}>
        {podcast ? (
          <>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                direction="row"
                item
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.title} variant="h6">
                  {podcast.podcastName}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.imgWrapper}
                item
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                {image ? (
                  <div style={{ width: '100%' }} onClick={isAdmin ? () => {} : handleClick}>
                    <img className={classes.img} src={image.Location} alt={image.name} />
                  </div>
                ) : (
                  <img className={classes.imgHolder} src={earbud} alt="earbud" />
                )}
                {!isAdmin && (
                  <Menu
                    keepMounted
                    open={mousePosition.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                      mousePosition.mouseY !== null && mousePosition.mouseX !== null
                        ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
                        : undefined
                    }
                  >
                    {podcast.podcastFiles.map(
                      (item, i) =>
                        item.main !== true && (
                          <MenuItem key={i} onClick={() => hendleChangeImg(i)}>
                            <img
                              style={{ width: 20, marginRight: 10 }}
                              src={item.Location}
                              alt="test"
                            />
                            {item.name}
                          </MenuItem>
                        )
                    )}
                  </Menu>
                )}
              </Grid>
            </CardContent>
            <CardActions className={classes.cardActionsContainer}>
              <Grid container item md={12} justifyContent="center" alignItems="center">
                <Grid container item md={12} justifyContent="center" alignItems="center">
                  <Button
                    className={classes.btn}
                    onClick={() => goTo(`/podcasts/${podcast._id}/add-campaign`)}
                  >
                    Add Campaign
                  </Button>
                </Grid>
                <Grid container item md={12} justifyContent="center" alignItems="center">
                  <Button
                    className={classes.btn}
                    onClick={() => goTo(`/podcasts/${podcast._id}/edit`)}
                  >
                    Edit
                  </Button>
                  {renderStatsButton()}
                </Grid>
              </Grid>
            </CardActions>
          </>
        ) : (
          <CardContent>
            <NavLink
              to={`${isAdmin ? `/users/${userId}` : ''}/podcasts/add`}
              className={classes.addPodcastCard}
            >
              +<br />
              ADD A<br />
              PODCAST
            </NavLink>
          </CardContent>
        )}
      </Card>
    </Grid>
  );
}
