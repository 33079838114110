import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { FormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { getLookalikePixels } from '../../api/stats';
import { getLookalikeSuggestions, selectSuggestion, setCorrectList } from '../../redux/slices/suggestionSlice';
import { selectRssFeed } from '../../redux/slices/rssSlice';

const audienceIdsByDefault = ['142979', '159187', '186803'];

const LookalikeAudience = ({ classes }) => {
  const { values, setFieldValue } = useContext(FormikContext);
  const { lookalikeSuggestions } = useSelector(selectSuggestion);
  const dispatch = useDispatch();
  const { feed } = useSelector(selectRssFeed);
  const [lookalike, setLookalike] = useState([]);
  const [lookalikeDiscriptions, setLookalikeDiscriptions] = useState();

  const renderForSuggestions = (accum, item) => {
    if (item.description) {
      accum.push(
        <Box key={item.term}>
          <Typography variant="body2">
            <b>{item.term}:</b> {item.description}
          </Typography>
        </Box>
      );
    }
    return accum;
  };

  useEffect(() => {
    getLookalikePixels()
      .then((response) => {
        setLookalike(response);
        const prePopulatedAudience = response.filter((item) =>
          audienceIdsByDefault.includes(item.id)
        );
        setFieldValue('lookalike', prePopulatedAudience);
      })
      .catch((err) => {
        const { data } = err.response;
        if (Array.isArray(data)) {
          data.forEach((item) => toast.error(item.msg));
        }
      });
  }, []);

  useEffect(() => {
    if (lookalikeSuggestions.data) {
      const {
        options: { terms },
      } = lookalikeSuggestions.data;
      const { suggestions, filteredTerms } = terms.reduce(
        (accum, item, i, array) => {
          const term = lookalike.find((i) => i.name.toLowerCase() === item?.term?.toLowerCase());
          if (!term) return accum;
          accum.suggestions.push(term);
          accum.filteredTerms.push(array[i]);
          return accum;
        },
        {
          suggestions: [],
          filteredTerms: [],
        }
      );
      setFieldValue('lookalike', suggestions);
      dispatch(
        setCorrectList({ type: 'lookalikeSuggestions', data: filteredTerms })
      );
      setLookalikeDiscriptions(filteredTerms.reduce(renderForSuggestions, []));
    }
  }, [lookalikeSuggestions.data]);

  const handleLookalike = (values) => {
    setFieldValue('lookalike', values);
  };

  const handleSuggestions = () => {
    const podcast = {
      name: feed.title,
      description: feed.description,
      categories: `"${feed?.itunes?.categories?.join(', ')}"`,
    };
    const episodeData = {};
    if (values.episode) {
      episodeData.description = values.episode.subtitle;
    }
    dispatch(getLookalikeSuggestions({ podcast, episode: episodeData }));
  };

  const Option = (props) => (
    <Grid container item md={12} justifyContent="space-between">
      <Box>
        <components.Option {...props} />
      </Box>
      <Box>{props.data.size}</Box>
    </Grid>
  );

  return (
    <>
      <Grid container className={classes.inputSection} item md={12}>
        <Typography variant="h5">Lookalike</Typography>
      </Grid>
      <Grid container className={classes.inputSection} item md={12} alignItems="center">
        <Grid container className={classes.inputSection} item md={9}>
          <Select
            className={classes.select}
            isMulti
            backspaceRemovesValue={false}
            options={lookalike}
            isClearable={false}
            getOptionValue={({ id }) => id}
            value={values.lookalike}
            onChange={handleLookalike}
            components={{ Option }}
            getOptionLabel={({ name }) => name}
            closeMenuOnSelect={false}
          />
        </Grid>
        <Grid container className={classes.inputSection} justifyContent="center" item md={1}>
          {lookalikeSuggestions.loading && <CircularProgress />}
        </Grid>
        <Grid container className={classes.inputSection} item md={2}>
          <Button
            className={classes.btn}
            variant="contained"
            disabled={lookalikeSuggestions.loading || !lookalike.length}
            classes={{
              label: classes.origBtnLabel,
              disabled: classes.disabled,
            }}
            onClick={() => handleSuggestions()}
          >
            AI SUGGESTIONS
          </Button>
        </Grid>
        {lookalikeDiscriptions}
      </Grid>
    </>
  );
};

export default LookalikeAudience;
