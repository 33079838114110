import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { getInterests } from '../../api/stats';
import { getInterestSuggestions, selectSuggestion, setCorrectList } from '../../redux/slices/suggestionSlice';
import { selectRssFeed } from '../../redux/slices/rssSlice';

const Interests = ({ classes }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useContext(FormikContext);
  const { interestSuggestions } = useSelector(selectSuggestion);
  const { feed } = useSelector(selectRssFeed);
  const [interestsOptions, setInterestsOptions] = useState([]);
  const [interestDiscriptions, setInterestDiscriptions] = useState();

  const renderForSuggestions = (accum, item) => {
    if (item.description) {
      accum.push(
        <Box key={item.term}>
          <Typography variant="body2">
            <b>{item.term}:</b> {item.description}
          </Typography>
        </Box>
      );
    }
    return accum;
  };

  useEffect(() => {
    getInterests()
      .then((response) => {
        setInterestsOptions(response);
      })
      .catch((err) => {
        const { data } = err.response;
        if (Array.isArray(data)) {
          data.forEach((item) => toast.error(item.msg));
        }
      });
  }, []);

  useEffect(() => {
    if (interestSuggestions.data) {
      const {
        options: { terms },
      } = interestSuggestions.data;
      const { suggestions, filteredTerms } = terms.reduce(
        (accum, item, i, array) => {
          const term = interestsOptions.find(
            (i) => i.displayName.toLowerCase() === item?.term?.toLowerCase()
          );
          if (!term) return accum;
          accum.suggestions.push(term);
          accum.filteredTerms.push(array[i]);
          return accum;
        },
        {
          suggestions: [],
          filteredTerms: [],
        }
      );
      setFieldValue('interests', suggestions);
      dispatch(
        setCorrectList({ type: 'interestSuggestions', data: filteredTerms })
      );
      setInterestDiscriptions(filteredTerms.reduce(renderForSuggestions, []));
    }
  }, [interestSuggestions.data]);

  const handleInterests = (values) => {
    setFieldValue('interests', values);
  };

  const handleSuggestions = () => {
    const podcast = {
      name: feed.title,
      description: feed.description,
      categories: `"${feed.itunes.categories.join(', ')}"`,
    };
    const episodeData = {};
    if (values.episode) {
      episodeData.description = values.episode.subtitle;
    }
    dispatch(getInterestSuggestions({ podcast, episode: episodeData }));
  };

  const filterOptionsInterest = (option, inputValue) => {
    if (!values.interests.some((item) => option.data.parents.includes(item.id))) {
      if (inputValue) {
        return option.label.toLowerCase().includes(inputValue);
      }
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container className={classes.inputSection} item md={12}>
        <Typography variant="h5">Interest and intent</Typography>
        <Tooltip
          title={
            <Typography>
              If a parent category is selected (i.e App Categories) all of the children are
              automatically included and you do not need to manually add them (i.e App Categories{' '}
              {'>'} Business, App Categories {'>'} Games {'>'} Casual). Additionally, if a sub
              category is chosen (i.e App Categories {'>'} Games), all its children are
              automatically selected as well.
            </Typography>
          }
          placement="right"
        >
          <Button color="primary">
            <HelpOutlineIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Grid container className={classes.inputSection} item md={12} alignItems="center">
        <Grid container className={classes.inputSection} item md={9}>
          <Select
            className={classes.select}
            isMulti
            backspaceRemovesValue={false}
            isClearable={false}
            options={interestsOptions}
            value={values.interests}
            getOptionValue={({ id: optionId }) => `${optionId}`}
            onChange={handleInterests}
            getOptionLabel={({ displayName }) => displayName}
            filterOption={filterOptionsInterest}
            closeMenuOnSelect={false}
          />
        </Grid>
        <Grid container className={classes.inputSection} justifyContent="center" item md={1}>
          {interestSuggestions.loading && <CircularProgress />}
        </Grid>
        <Grid container className={classes.inputSection} item md={2}>
          <Button
            className={classes.btn}
            variant="contained"
            disabled={interestSuggestions.loading || !interestsOptions.length}
            classes={{
              label: classes.origBtnLabel,
              disabled: classes.disabled,
            }}
            onClick={() => handleSuggestions()}
          >
            AI SUGGESTIONS
          </Button>
        </Grid>
        {interestDiscriptions}
      </Grid>
    </>
  );
};

export default Interests;
