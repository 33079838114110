import React from 'react';
import LoginForm from '../../components/login/LoginForm';

export default function Login() {
  return (
    <div className="login-container">
      <div className="login-row">
        <div className="login-block">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}
