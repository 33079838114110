import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api/stats';

export const getCampaignGroups = createAsyncThunk('get/campaign-groups', async (params) =>
  api.getCampaignGroups(params)
);
export const getCampaignGroupsWithClickUrl = createAsyncThunk(
  'get/campaign-groups-with-click-url',
  async (params) => api.getCampaignGroupsWithClickUrl(params)
);
export const getCampaignsByFilter = createAsyncThunk('get/campaigns-by-filter', async (params) =>
  api.getCampaignsByFilter(params)
);

const stackAdaptCampaignsSlice = createSlice({
  name: 'stack',
  initialState: {
    campaignGroups: null,
    error: null,
    campaignGroup: null,
    campaignGroupsWithClickUrl: null,
    campaings: null,
    loading: false,
  },
  reducers: {
    setCampaignGroup: (state, action) => {
      state.campaignGroup = action.payload;
    },
    resetCampaignGroups: (state) => {
      state.campaignGroups = null;
      return state;
    },
    resetCampaignGroupsWithClickUrl: (state) => {
      state.campaignGroupsWithClickUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignGroups = action.payload;
      })
      .addCase(getCampaignGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCampaignGroupsWithClickUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignGroupsWithClickUrl = action.payload;
      })
      .addCase(getCampaignGroupsWithClickUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignGroupsWithClickUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCampaignsByFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.campaign = action.payload;
      })
      .addCase(getCampaignsByFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignsByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const selectStack = (state) => state.stack;
export const { setCampaignGroup, resetCampaignGroups, resetCampaignGroupsWithClickUrl } =
  stackAdaptCampaignsSlice.actions;
export default stackAdaptCampaignsSlice.reducer;
