import React from 'react';
import { TableCell, TableHead, TableRow, Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import cx from 'classnames';
import { useStyles } from './styles';

const headCells = [
  {
    id: '#',
    numeric: false,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: true,
    label: 'Created on',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Campaign Group Name',
  },
  {
    id: 'campaigns',
    numeric: true,
    disablePadding: false,
    label: 'Campaigns',
  },
  {
    id: 'landing-page',
    numeric: true,
    disablePadding: true,
    label: 'Landing Page',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];

export const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tHead}>
      <TableRow style={{ border: 'none' }}>
        {headCells.map((headCell) => {
          if (
            headCell.id === '#' ||
            headCell.id === 'landing-page' ||
            headCell.id === 'id' ||
            headCell.id === 'created'
          ) {
            return (
              <TableCell
                key={headCell.id}
                className={classes.tHeader}
                align={headCell.id === 'landing-page' ? 'right' : 'left'}
              >
                {headCell.label}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.id}
              align={headCell.id === 'campaigns' || headCell.id === 'status' ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={cx(
                classes.tHeader,
                headCell.id === 'campaigns' && classes.tHeadCampaigns,
                headCell.id === 'status' && classes.tHeadStatus
              )}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tHeadSortLabel}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
