import * as yup from 'yup';
import { validateEmail } from '../../../../api/auth';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

function comparePassToEmail(ref, msg) {
  return this.test('comparePassToEmail', msg, function (value) {
    return !(this.resolve(ref) && this.resolve(ref).split('@')[0] === value);
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);
yup.addMethod(yup.string, 'comparePassToEmail', comparePassToEmail);

const reName = /^[aA-zZ\s]{2,}((-|'){1,}?[aA-zZ\s]{1,}?)*$/;

export const validationSchemaUserDetails = yup.object().shape({
  email: yup.string().email('Value must be a valid email').required('Required')
    .test('validate email', 'Email is invalid', (value) => {
      if (value) {
        return validateEmail(value)
          .then(() => true)
          .catch(() => false);
      }
      return true;
    }),
  password: yup.string()
    .min(8, 'Must Contain 8 Characters')
    .max(32)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    )
    .comparePassToEmail(yup.ref('email'), 'Password should not contain part of your email')
    .required(),
  confirmPassword: yup.string().equalTo(yup.ref('password'), 'Passwords do not match'),
  firstName: yup.string()
    .matches(reName, 'First Name can have letters, -, \'')
    .required('Required'),
  lastName: yup.string()
    .matches(reName, 'Last Name can have letters, -, \'')
    .required('Required'),
  title: yup.string(),
  terms: yup.bool().oneOf([true], 'Field must be checked').required('Required'),
});
