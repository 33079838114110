import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/slices/rootSlice';
import { getUser, getUsers } from '../redux/slices/usersSlice';
import { getRss } from '../redux/slices/rssSlice';
import { getCampaignGroups, getCampaignGroupsWithClickUrl, getCampaignsByFilter } from '../redux/slices/stackAdaptCampaignsSlice';
import { getSuggestions } from '../redux/slices/suggestionSlice';
import { getCampaignGroupsForStats, getCampaignsStats, getTopOS, getTopPodcasts, getTopStates } from '../redux/slices/statsSlice';
import { getLoginedUser } from '../redux/slices/loginedUserSlice';

export const useGettingData = () => {
  const dispatch = useDispatch();

  const requestWithLoader = async (callback, data) => {
    dispatch(setLoading(true));
    await dispatch(callback(data));
    dispatch(setLoading(false));
  };

  const handleGettingUser = (data) => dispatch(getUser(data));

  const handleGettingLoginedUser = () => dispatch(getLoginedUser());

  const handleGettingUsers = (data) => dispatch(getUsers(data));

  const handleGettingRssFeed = (url) => requestWithLoader(getRss, url);

  const handleGettingCampaingGroups = (data) => requestWithLoader(getCampaignGroups, data);

  const handleGettingSuggestions = (data) => requestWithLoader(getSuggestions, data);

  const handleGettingCampaignGroupsWithClickUrl = (data) => dispatch(getCampaignGroupsWithClickUrl(data));

  const handleGettingCampaignsByFilter = (data) => requestWithLoader(getCampaignsByFilter, data);

  const handleGettingCampaignGroupsForStats = (data) => requestWithLoader(getCampaignGroupsForStats, data);

  const handleGettingStats = (data) => requestWithLoader(getCampaignsStats, data);

  const handleGettingTopStates = (data) => dispatch(getTopStates(data));

  const handleGettingTopPodcasts = (data) => dispatch(getTopPodcasts(data));

  const handleGettingTopOS = (data) => dispatch(getTopOS(data));

  return {
    handleGettingUser,
    handleGettingLoginedUser,
    handleGettingUsers,
    handleGettingRssFeed,
    handleGettingCampaingGroups,
    handleGettingSuggestions,
    handleGettingCampaignGroupsWithClickUrl,
    handleGettingCampaignsByFilter,
    handleGettingCampaignGroupsForStats,
    handleGettingStats,
    handleGettingTopStates,
    handleGettingTopOS,
    handleGettingTopPodcasts
  };
};
