import React, { useContext, useReducer } from 'react';
import { duplicateCampaignReducer } from '../reducers/duplicateCampaignReducer';

const DuplicateCampaignContext = React.createContext();
export const useDuplicateCampaign = () => useContext(DuplicateCampaignContext);

const initialState = {
  duplicateCampaignGroup: null,
  duplicateCampaign: null,
};

const actions = {
  SET_DUPLICATE_CAMPAIGN_GROUP: 'SET_DUPLICATE_CAMPAIGN_GROUP',
  SET_DUPLICATE_CAMPAIGN: 'SET_DUPLICATE_CAMPAIGN',
};

export const DuplicateCampaignProvide = ({ children }) => {
  const [state, dispatch] = useReducer(duplicateCampaignReducer, initialState);
  const value = {
    duplicateCampaignGroup: state.duplicateCampaignGroup,
    duplicateCampaign: state.duplicateCampaign,
    setDuplicateCampaignGroup: (data) => {
      dispatch({ type: actions.SET_DUPLICATE_CAMPAIGN_GROUP, data });
    },
    setDuplicateCampaign: (data) => {
      dispatch({ type: actions.SET_DUPLICATE_CAMPAIGN, data });
    },
  };

  return (
    <DuplicateCampaignContext.Provider value={value}>{children}</DuplicateCampaignContext.Provider>
  );
};
