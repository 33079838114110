import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePodcasts } from '../contexts/PodcastsContext';
import UserCard from '../components/UserCard';
import { getAdvertiserById } from '../api/advertisers';
import { selectLoginedUser } from '../redux/slices/loginedUserSlice';
import { selectUsers } from '../redux/slices/usersSlice';
import StackAdvertiserStats from '../components/StackAdvertiserStats';

export default function User() {
  const { user, isAdmin } = useSelector(selectLoginedUser);
  const { pickedUser } = useSelector(selectUsers);
  const { setShow } = usePodcasts();
  const { id } = useParams();
  const [advertiserData, setAdvertiserData] = useState([]);
  const [showAdvertiser, setShowAdvertiser] = useState(true);

  useEffect(() => {
    setShow(true);
    if (!isAdmin || id) {
      getAdvertiserById(id || user._id)
        .then((result) => {
          setAdvertiserData([
            { label: 'Company', value: result.Advertiser.company || '' },
            { label: 'Address 1', value: result.Advertiser.address1 || '' },
            { label: 'Address 2', value: result.Advertiser.address2 || '' },
            { label: 'City', value: result.Advertiser.city || '' },
            { label: 'State', value: result.Advertiser.region || '' },
            { label: 'Zip Code', value: result.Advertiser.zipcode || '' },
            { label: 'Country', value: result.Advertiser.country || '' },
            { label: 'Phone', value: result.Advertiser.phone || '' },
          ]);
        })
        .catch((e) => {
          console.log('e :>> ', e);
          setShowAdvertiser(false);
        });
    } else {
      setShowAdvertiser(false);
    }
  }, [id]);

  const formatUserData = (data) => [
    { label: 'Email address', value: data.email },
    { label: 'First name', value: data.userDetails.firstName },
    { label: 'Last name', value: data.userDetails.lastName },
    { label: 'Title', value: data.userDetails.title },
    { label: 'Salesperson', value: data.salesperson },
    { label: 'MowDSP Advertisers', value: data?.stackAdaptAdvertiser.map((i) => i.id).join(', ') },
  ];

  const formatBillingData = (data) => [
    { label: 'Finance Contact Name', value: data?.billingDetails?.billingName || '' },
    { label: 'Email', value: data?.billingDetails?.billingEmail || '' },
    { label: 'Phone', value: data?.billingDetails?.billingPhone || '' },
    { label: 'Email for invoices', value: data?.billingDetails?.emailForInvoices || '' },
  ];

  const getRoure = (type) => {
    switch (type) {
      case 'user':
        if (id) return `/users/${id}/info/edit`;
        return '/user/edit';
      case 'advertiser':
        if (id) return `/users/${id}/info/advertiser`;
        return '/advertiser/edit';
      case 'billing':
        if (id) return `/users/${id}/info/billing`;
        return '/billing/edit';
      default:
        break;
    }
  };

  return (
    pickedUser && (
      <Grid container item md={12} justifyContent="space-between" alignItems="flex-start">
        <UserCard title="User Details" data={formatUserData(pickedUser)} route={getRoure('user')} />
        {showAdvertiser && (
          <UserCard
            title="Advertiser Details"
            data={advertiserData}
            route={getRoure('advertiser')}
          />
        )}
        <UserCard title="Billing Details" data={formatBillingData(pickedUser)} route={getRoure('billing')} />
        {isAdmin && <StackAdvertiserStats />}
      </Grid>
    )
  );
}
