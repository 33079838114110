import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { selectUsers } from '../../redux/slices/usersSlice';
import { getCampaignGroups, resetCampaignGroups, selectStack } from '../../redux/slices/stackAdaptCampaignsSlice';

import { useStyles } from './styles';
import CampaignList from './CampaignList';

const StackAdvertiserStats = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pickedUser } = useSelector(selectUsers);
  const { campaignGroups, loading } = useSelector(selectStack);
  const [total, setTotal] = useState(0);
  const [activeCampaigns, setActiveCampaigns] = useState(0);
  const [totalEstimatedRevenue, setTotalEstimatedRevenue] = useState(0);

  useEffect(() => {
    if (pickedUser.stackAdaptAdvertiser.length) {
      dispatch(getCampaignGroups({ advertiserIds: [pickedUser.stackAdaptAdvertiser[0].id] }));
    }
    return () => {
      dispatch(resetCampaignGroups());
    };
  }, [pickedUser.stackAdaptAdvertiser]);

  useEffect(() => {
    if (!campaignGroups) return;
    setTotal(campaignGroups.length);
    setActiveCampaigns(
      campaignGroups.filter(({ campaignGroupStatus }) => campaignGroupStatus.status === 'ACTIVE')
        .length
    );
    setTotalEstimatedRevenue(
      campaignGroups[0]?.advertiser.deliveryStats.total.conversions * pickedUser.pricing.boost
    );
  }, [campaignGroups]);

  const renderGrid = () => {
    const tiles = [
      {
        lable: 'Total campaigns',
        value: total,
      },
      {
        lable: 'Active campaigns',
        value: activeCampaigns,
      },
      {
        lable: 'Total estimated revenue',
        value: `${totalEstimatedRevenue}$`,
        suffix: '$',
      },
    ];
    return tiles.map(({ lable, value, suffix }) => (
      <Grid
        key={lable}
        container
        className={classes.card}
        spacing={3}
        direction="column"
        item
        md={4}
        alignItems="center"
      >
        <Typography className={classes.subTitle} align="center">
          {lable}
        </Typography>
        <Box>
          <Typography className={classes.subTitle}>
            {loading ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              <CountUp end={value} duration={2} suffix={suffix} separator="," />
            )}
          </Typography>
        </Box>
      </Grid>
    ));
  };

  return (
    // campaignGroups && (
    <Grid container item md={12} justifyContent="space-between" style={{ padding: '0 25px' }}>
      <Grid container item md={12}>
        <Typography className={classes.title}>Advertiser&apos;s Stats</Typography>
      </Grid>
      {renderGrid()}
      <CampaignList />
    </Grid>
    // )
  );
};

export default StackAdvertiserStats;
