import React, { useEffect, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, Button, Grid, Typography } from '@mui/material';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import ColorPicker from 'react-best-gradient-color-picker';

import { resize } from '../../utils/utils';
import { selectRssFeed } from '../../redux/slices/rssSlice';
import Preview from '../Preview';

export default function AdjustingLandingPage({ classes }) {
  const [activeBtn, setActiveBtn] = useState('bgColor');
  const [selectedEpisode, setSelectedEpisode] = useState(0);

  const { feed } = useSelector(selectRssFeed);
  const formik = useFormikContext();
  const textAreaRef = useRef(null);

  const {
    enterpriseBoost,
    enterpriseBoostSettings,
    enterpriseBoostSettings: { mainImage, bgColor, mainTextColor, mainText },
    subtitle,
  } = formik.values;

  useEffect(() => {
    if (feed) formik.setFieldValue('enterpriseBoostSettings.episode', feed.items[0]);
  }, [feed]);

  useEffect(() => {
    if (textAreaRef.current) {
      resize(textAreaRef.current);
    }
  }, [textAreaRef, formik.values.enterpriseBoostSettings.mainText]);

  useEffect(() => {
    if (!mainText) formik.setFieldValue('enterpriseBoostSettings.mainText', subtitle);
  }, [enterpriseBoost]);

  const handleClickButton = (type) => setActiveBtn(type);

  const hendleColor = (color) => {
    formik.setFieldValue(`enterpriseBoostSettings.${activeBtn}`, color);
  };

  const handleEpisode = (e) => {
    const { value: i } = e.target;
    setSelectedEpisode(i);
    formik.setFieldValue('enterpriseBoostSettings.episode', feed.items[i]);
  };

  return enterpriseBoost && (
    <>
      <Grid container item md={12}>
        <Typography variant="h5">Main Text</Typography>
        <Grid container item md={12} className={classes.inputSection}>
          <Field
            as="textarea"
            name="enterpriseBoostSettings.mainText"
            innerRef={textAreaRef}
            className={cx(classes.textarea, 'register-form-input')}
          />
        </Grid>
      </Grid>
      <Grid container item md={12}>
        <Typography variant="h5">Destination URL</Typography>
        <Grid container item md={12} className={classes.inputSection}>
          <Field
            type="text"
            name="enterpriseBoostSettings.destinationURL"
            className={cx(classes.textarea, 'register-form-input')}
          />
        </Grid>
      </Grid>
      <Grid container item md={12} alignItems="center">
        <Typography variant="h5">Episodes</Typography>
        <Grid container item md={12} className={classes.inputSection}>
          <Field
            as="select"
            value={selectedEpisode}
            name="enterpriseBoostSettings.episode"
            className="register-form-input register-form-select"
            onChange={handleEpisode}
          >
            <option value="" disabled>
              Choose from episode
            </option>
            {feed?.items?.map((item, i) => (
              <option key={i} value={i}>
                {item.title}
              </option>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid container item md={12}>
        <Grid container direction="row" item md={3} alignContent="flex-start" st="true">
          {mainImage && <img src={mainImage} alt={feed.title} className={classes.img} />}
          <Box pl={1} pb={1} pt={1}>
            <Button
              variant="contained"
              style={{ background: bgColor }}
              onClick={() => handleClickButton('bgColor')}
              className={classes.EBButton}
            >
              Background <div className={cx({ [classes.active]: activeBtn === 'bgColor' })} />
            </Button>
          </Box>
          <Box pl={1} pb={1} pt={1}>
            <Button
              variant="contained"
              style={{ background: mainTextColor }}
              onClick={() => handleClickButton('mainTextColor')}
              className={classes.EBButton}
            >
              Main Text <div className={cx({ [classes.active]: activeBtn === 'mainTextColor' })} />
            </Button>
          </Box>
        </Grid>
        <Grid container item md={3} className={classes.inputSection}>
          <Box pl={1}>
            {/* {showColorPicker && ( */}
            <ColorPicker
              hidePresets
              hideAdvancedSliders
              hideInputType
              hideColorGuide
              width={250}
              height={250}
              value={enterpriseBoostSettings[activeBtn]}
              onChange={hendleColor}
            />
            {/* )} */}
          </Box>
        </Grid>
        <Grid container item md={6} className={classes.inputSection}>
          <Preview />
        </Grid>
      </Grid>
    </>
  );
}
