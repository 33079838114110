import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { validationSchema } from './validationSchema';

import { useStyles } from './styles';
import { sendEmailForResetPassword } from '../../api/auth';

export default function ForgotPasswordForm() {
  const classes = useStyles();
  const history = useHistory();
  const [isSubmited, setIsSubmited] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      sendEmailForResetPassword(values)
        .then(() => setIsSubmited(true))
        .catch((err) => {
          const errors = {};
          if (err.response.data) {
            err.response.data.forEach((e) => {
              errors[e.param] = e.msg;
            });
            formik.setErrors(errors);
          }
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <div className="login-container">
        <div className="login-row">
          <div className="login-block">
            <Typography>Forgot Password</Typography>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="login-form">
              <div className="login-form-row">
                <div className="login-form-block">
                  <Field type="email" placeholder="email@example.com" name="email" className="login-form-input" required />
                  {formik.errors.email && <Typography className={classes.error}>{formik.errors.email}</Typography>}
                </div>
              </div>
              <div className="login-form-row centered">
                {isSubmited && <Typography>Check your Email</Typography>}
              </div>
              <div className="login-form-row centered">
                <div className="login-form-block">
                  <button className="button" type="submit">
                    Submit
                  </button>
                  <Grid container direction="row" item md={12} justifyContent="center" alignItems="center">
                    <Button onClick={() => history.push('/login')}><Typography display="block" style={{ marginLeft: '10px' }} align="right">Back to Login</Typography></Button>
                  </Grid>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
}
