import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPodcasts } from '../api/users';
import { selectLoginedUser } from '../redux/slices/loginedUserSlice';

const PodcastsContext = React.createContext();
export const usePodcasts = () => useContext(PodcastsContext);

export const PodcastsProvider = ({ children }) => {
  const [podcasts, setPodcasts] = useState([]);
  const { user, isAdmin } = useSelector(selectLoginedUser);
  const [userId, setUserId] = useState(user._id || '');
  const [show, setShow] = useState(!isAdmin);

  const loadPodcasts = () => getPodcasts(userId)
    .then((response) => {
      setPodcasts(response);
    })
    .catch((e) => {
      console.error(e);
      setPodcasts([]);
    });

  useEffect(() => {
    loadPodcasts();
  }, [userId]);

  return (
    <PodcastsContext.Provider value={{
      podcasts,
      setPodcasts,
      userId,
      setUserId,
      show,
      setShow,
      loadPodcasts,
    }}
    >
      {children}
    </PodcastsContext.Provider>
  );
};
