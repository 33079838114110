import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxContainer: {
    display: 'inline-block',
    position: 'relative',
    width: 32,
    height: 32,
    border: '1px solid #fff',
    borderRadius: 6,
    cursor: 'pointer',
    verticalAlign: 'middle',
    flexShrink: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 0,
      height: 0,
      backgroundColor: '#f59a00',
      borderRadius: 4,
      transform: 'translate(-50%, -50%)',
      transition: 'width 0.2s ease, height 0.2s ease',
    },
  },
  defaulCheckbox: {
    display: 'none',
    '&:checked + $checkboxContainer:before': {
      width: 23,
      height: 23,
    },
  },
  checkboxLabel: {
    verticalAlign: 'middle',
    marginLeft: 10,
    fontSize: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
