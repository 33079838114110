import moment from 'moment-timezone';

export const initialValues = {
  advertiser_id: '',
  name: "",
  budget: '',
  budget_type: "",
  revenue_type: "",
  revenue_value: '',
  state: "",
  start_date: moment.tz('America/New_York').startOf('day').format(),
  end_date: moment.tz('America/New_York').add(4, 'days').endOf('day').format(),
  daily_cap: '',
  black_list_options: "",
  purchase_order_number: '',
};
