import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  wrapper: { padding: 0 },
  modalContent: {
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    width: 820,
    backgroundColor: '#1c1b1b',
    borderRadius: 15,
    border: 'none',
    padding: 20,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '94vh',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '65vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: '85vh',
      overflowY: 'scroll',
    },
    '& span': {
      fontWeight: 700,
    },
    '& p': {
      paddingBottom: 10,
    },
  },
  interests: {
    maxHeight: 135,
    overflowY: 'auto',
    minHeight: 80,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 220
    },
  },
  button: {
    cursor: 'pointer',
    width: 150,
    height: 50,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 14,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    alignSelf: 'center',
  },
}));
