import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  mainLogo: {
    width: '100%',
  },
  hsLogo: {
    width: '60%',
  },
  subLink: {
    padding: 0,
    fontSize: 12,
    fontWeight: '500',

    color: '#fff',
    lineHeight: '24px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&.active': {
      color: '#48b9e8',
      fontWeight: '700',
    },
  },
  podcasts: {
    borderRadius: 14,
    backgroundColor: '#202020',
    maxHeight: 160,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(141, 141, 141, 0.3) transparent',

    '&::-webkit-scrollbar-track': {
      background: 'none',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',

      borderRight: '6px solid rgba(0, 0, 0, 0.2)',

      transition: '0.2s ease',

      '&:hover': {
        borderRight: '6px solid rgba(0, 0, 0, 0.3)',
      },

      '&:focus': {
        borderRight: '6px solid rgba(0, 0, 0, 0.6)',
      },
    },
  },
  name: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  sidebar: {
    '&::before': {
      backgroundImage: ({ rainbow }) => `url(${rainbow})`,
    },
  },
});
