import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Route, useHistory, useLocation, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar/Sidebar';
import PodacastStats from '../components/PodcastStats';
import { PodcastsProvider } from '../contexts/PodcastsContext';
import { CountriesProvider } from '../contexts/Countries';
import Podcast from './Podcast/Podcast';
import Podcasts from './Podcasts';
import User from './User';
import Users from './Users';
import { BrandSafety } from '../components/BrandSafety';
import Admin from './Admin';
import AdvertiserInfo from '../components/AdvertiserInfo';
import TermsOfUse from '../components/TermConditions';
import { AdminRoute } from '../components/AdminRoute/AdminRoute';
import UserInfo from '../components/UserInfo';
import { AddCampaign } from './AddCampaign';
import { selectRoot } from '../redux/slices/rootSlice';
import { useGettingData } from '../hooks/useGettingData';
import { selectLoginedUser } from '../redux/slices/loginedUserSlice';
import BillingInfo from '../components/BillingInfo';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
}));

export function Main() {
  const history = useHistory();
  const classes = useStyles();
  const { loading } = useSelector(selectRoot);
  const { user, isAdmin } = useSelector(selectLoginedUser);
  const location = useLocation();
  const { handleGettingUser } = useGettingData();
  useEffect(() => {
    if (location.pathname === '/') {
      if (isAdmin) {
        history.push('/users');
      } else {
        history.push('/user');
      }
    }
  }, []);

  useEffect(() => {
    if (!isAdmin && user) handleGettingUser(user._id);
  }, [user]);

  return (
    user && (
      <div className="double-container main">
        <div className="double-container-row">
          <PodcastsProvider>
            <CountriesProvider>
              <div className="left-container main">
                <Sidebar />
              </div>
              <div className="right-container main">
                <Switch>
                  <Route component={PodacastStats} path="/podcasts/:podcastId/advertiser/:advertiserId/stats" />
                  <Route component={AddCampaign} path="/podcasts/:podcastId/add-campaign" />
                  <Route
                    component={Podcast}
                    key="user-podcast"
                    path="/podcasts/:podcastId/edit"
                    exact
                  />
                  <Route component={User} key="user" path="/user" exact />
                  <Route component={UserInfo} path="/user/edit" exact />
                  <Route component={AdvertiserInfo} path="/advertiser/edit" exact />
                  <Route component={BillingInfo} path="/billing/edit" exact />
                  <Route component={Podcast} key="add-podcast" path="/podcasts/add" exact />
                  <Route component={Podcasts} key="podcasts" path="/podcasts" exact />
                  <Route component={TermsOfUse} path="/terms-of-use" exact />
                  <Route component={BrandSafety} path="/brand-safety" exact />
                  {/* Admin routes */}
                  <AdminRoute component={Users} path="/users" exact />
                  <AdminRoute component={Admin} key="admin-podcast" path="/users/:id" />
                </Switch>
              </div>
            </CountriesProvider>
          </PodcastsProvider>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    )
  );
}
