import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser } from '../../api/auth';

export const getLoginedUser = createAsyncThunk('get/user', async () => getUser());

const loginedUserSlice = createSlice({
  name: 'loginedUser',
  initialState: { user: null, isAdmin: null, loading: false },
  reducers: {
    removeloginedUser: (state) => {
      state.user = null;
      state.isAdmin = null;
      return state;
    },
    setLoginedUser: (state, action) => {
      state.user = action.payload;
      state.isAdmin = action.payload.role === 'admin';
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginedUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginedUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAdmin = action.payload.role === 'admin';
        state.loading = false;
      })
      .addCase(getLoginedUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectLoginedUser = (state) => state.loginedUser;
export const { removeloginedUser, setLoginedUser } = loginedUserSlice.actions;
export default loginedUserSlice.reducer;
