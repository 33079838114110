import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  rainbow: {
    background: (props) => `url("${props.raindbowRight}") no-repeat`,
    backgroundSize: 'cover',
    height: 420,
    width: '100%',
    top: '-70px',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      top: '-120px',
    },
  },
  casette: {
    zIndex: 3,
    width: '24%',
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  thankYou: {
    maxWidth: 960,
    margin: '0 auto 100px',
    position: 'static',
    padding: '0 30px',
  },
  logo: {
    maxWidth: 200,
    width: '100%',
    marginRight: 'auto',
  },
  title: {
    textAlign: 'center',
    fontSize: 46,
    lineHeight: 1,
    fontWeight: 900,
    maxWidth: 450,
    margin: '0 auto',
    textTransform: 'uppercase',
    fontStyle: 'italic',
    textShadow:
      '-4px -4px #221f20, 0px -4px #221f20, 4px -4px #221f20, -5px 0px #221f20, 1px 0px #221f20, 5px 0px #221f20, -4px 4px #221f20, 0px 4px #221f20, 4px 4px #221f20, -2px -2px #221f20, 2px -2px #221f20, 6px -2px #221f20, -2px 6px #221f20, 2px 6px #221f20, 6px 6px #221f20, 0px 0px #221f20, 4px 0px #221f20, 8px 0px #221f20, 0px 8px #221f20, 4px 8px #221f20, 8px 8px #221f20, 2px 2px #221f20, 6px 2px #221f20, 10px 2px #221f20, 2px 10px #221f20, 6px 10px #221f20, 10px 10px #221f20, 4px 4px #221f20, 8px 4px #221f20, 12px 4px #221f20, 4px 12px #221f20, 8px 12px #221f20, 12px 12px #221f20, 6px 6px #221f20, 10px 6px #221f20, 14px 6px #221f20, 6px 14px #221f20, 10px 14px #221f20, 14px 14px #221f20, 8px 8px #221f20, 12px 8px #221f20, 16px 8px #221f20, 8px 16px #221f20, 12px 16px #221f20, 16px 16px #221f20, 10px 10px #221f20, 14px 10px #221f20, 18px 10px #221f20, 10px 18px #221f20, 14px 18px #221f20, 18px 18px #221f20, 12px 12px #221f20, 16px 12px #221f20, 20px 12px #221f20, 12px 20px #221f20, 16px 20px #221f20, 20px 20px #221f20, 14px 14px #221f20, 18px 14px #221f20, 22px 14px #221f20, 14px 22px #221f20, 18px 22px #221f20, 22px 22px #221f20, 16px 16px #221f20, 20px 16px #221f20, 24px 16px #221f20, 16px 24px #221f20, 20px 24px #221f20, 24px 24px #221f20, 18px 18px #221f20, 22px 18px #221f20, 26px 18px #221f20, 18px 24px #221f20, 22px 24px #221f20, 26px 24px #221f20, 20px 20px #221f20, 24px 20px #221f20, 28px 20px #221f20, 20px 26px #221f20, 24px 26px #221f20, 28px 26px #221f20, 22px 22px #221f20, 26px 22px #221f20, 30px 22px #221f20, 22px 28px #221f20, 26px 28px #221f20, 30px 28px #221f20',
    paddingBottom: 30,
    paddingTop: 30,
    transform: 'rotate(-5deg)',
    [theme.breakpoints.down('md')]: {
      fontSize: 39,
    },
  },
  thankYouContent: {
    backgroundColor: 'rgba(20, 20, 20, 0.9)',
    borderRadius: 15,
    padding: '35px',
    margin: '50px 0 20px',
    backdropFilter: 'blur(5px)',
    width: '100%',
    position: 'relative',
  },
  airpodRight: {
    top: -50,
    right: 0,
    left: 'unset',
    width: 160,
    zIndex: -1,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: '"Montserrat", sans-serif',
    color: '#f59a00',
    fontWeight: 800,
    marginBottom: 15,
    textAlign: 'center',
  },
  button: {
    cursor: 'pointer',
    width: 170,
    height: 70,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 10,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 18,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '& a': {
      '&:hover': {
        color: 'white',
      },
    },
  },
}));
