import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import $ from 'jquery';

import { isHS } from '../../utils/utils';
import Logo from '../../assets/images/logo.svg';
import HSLogo from '../../assets/images/HS.png';

const useStyles = makeStyles({
  mainLogo: {
    width: '100%',
  },
  hsLogo: {
    width: '60%',
  },
});

export default function Sidebar({ parentStep }) {
  const classes = useStyles();
  const [isOpenMainMenu, setIsOpenMainMenu] = useState({
    createAccount: true,
    billingDetails: true,
  });
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const toggleMainMenu = (e, menu) => {
    setIsOpenMainMenu((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
    $(e.currentTarget).closest('li.main-item').children('ul.sub').slideToggle(200);
  };

  return (
    <div className="sidebar">
      <div className="sidebar-row">
        <div className="sidebar-logo">
          <NavLink to="/login">
            <img src={Logo} className={classes.mainLogo} alt="mowpod-logo" />
            {isHS() && <img className={classes.hsLogo} src={HSLogo} alt="hs-logo" />}
          </NavLink>
        </div>
        <div onClick={() => setOpenMobileMenu(!openMobileMenu)} className="sidebar-nav-icon" />
        <div className={cx('sidebar-nav', { action: openMobileMenu })}>
          <div onClick={() => setOpenMobileMenu(!openMobileMenu)} className="sidebar-nav-icon-close" />
          <ul className="main">
            <li className="main-item">
              <div onClick={(e) => toggleMainMenu(e, 'createAccount')} className={cx('main-link', { active: isOpenMainMenu.createAccount })}>
                Create Account
              </div>
              <ul className="sub" style={{ display: 'block' }}>
                <li className="sub-item">
                  <a href="#" className={cx('sub-link', { active: parentStep === 0 })}>
                    Company Details
                  </a>
                </li>
                <li className="sub-item">
                  <a href="#" className={cx('sub-link', { active: parentStep === 1 })}>
                    User Details
                  </a>
                </li>
                <li className="sub-item">
                  <a href="#" className={cx('sub-link', { active: parentStep === 2 })}>
                    Billing Details
                  </a>
                </li>
                <li className="sub-item">
                  <a href="#" className={cx('sub-link', { active: parentStep === 3 })}>
                    Add a Podcast
                  </a>
                </li>
              </ul>
            </li>
            <li className="main-item">
              <ul className="sub" style={{ display: 'block' }}>
                <li className="sub-item">
                  <a href="#" className={cx('sub-link', { active: parentStep === 4 })}>
                    Download a W9
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
