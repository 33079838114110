import {
  CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { selectStats } from '../../redux/slices/statsSlice';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}))(TableRow);

export default function StatsTable({ loading }) {
  const classes = useStyles();
  const {
    totalStats = {},
    sidecar,
  } = useSelector(selectStats);
  const header = () => (sidecar ? (
    <>
      <TableCell className={classes.tHeader} align="left">Impressions</TableCell>
      <TableCell className={classes.tHeader} align="left">Clicks</TableCell>
      <TableCell className={classes.tHeader} align="left">CTR</TableCell>
    </>
  ) : (
    <>
      <TableCell className={classes.tHeader} align="left">Impressions</TableCell>
      <TableCell className={classes.tHeader} align="left">Plays</TableCell>
      <TableCell className={classes.tHeader} align="left">Play Rate</TableCell>
      <TableCell className={classes.tHeader} align="left">IAB Downloads</TableCell>
    </>
  ));

  const body = (item) => (sidecar ? (
    <>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.impressions} duration={2} separator="," />
      </StyledTableCell>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.clicks} duration={2} separator="," />
      </StyledTableCell>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        {item.ctr}
      </StyledTableCell>
    </>
  ) : (
    <>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.impressions} duration={2} separator="," />
      </StyledTableCell>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.clicks} duration={2} separator="," />
      </StyledTableCell>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.ctr} duration={2} separator="," decimals={3} decimal="." />
      </StyledTableCell>
      <StyledTableCell align="left" className={classes.tBodyItem}>
        <CountUp end={item.clickConversions} duration={2} separator="," />
      </StyledTableCell>
    </>
  ));
  return (
    <>
      <TableContainer className={classes.tTableContainer} style={{ maxHeight: 400 }}>
        <Table className={classes.tTable}>
          <TableHead className={classes.tHead}>
            <TableRow style={{ border: 'none' }}>
              {header()}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {' '}
                  (<CircularProgress />){' '}
                </TableCell>
              </TableRow>
            ) : (
              <StyledTableRow>{body(totalStats)}</StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
