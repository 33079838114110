import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Sidebar from '../components/register/Sidebar';
import ProgressBar from '../components/register/ProgressBar';
import RegisterForm from '../components/register/RegisterForm/RegisterForm';

export default function RegisterPage() {
  const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [parentStep, setParentStep] = useState(0);

  useEffect(() => {
    if (queryParams.has('canceled') || queryParams.has('success')) {
      queryParams.delete('success');
      queryParams.delete('canceled');
      const step = queryParams.get('step');
      queryParams.delete('step');

      history.replace({
        search: queryParams.toString(),
      });
      setParentStep(+step || 0);
    } else {
      setParentStep(0);
    }
  }, []);

  const sendDataToParent = (index) => {
    setParentStep(index);
  };

  return (
    <div className="double-container register">
      <div className="double-container-row">
        <div className="left-container">
          <Sidebar parentStep={parentStep} sendDataToParent={sendDataToParent} />
          <ProgressBar parentStep={parentStep} sendDataToParent={sendDataToParent} />
        </div>
        <div className="right-container">
          <RegisterForm
            parentStep={parentStep}
            sendDataToParent={sendDataToParent}
          />
        </div>
      </div>
    </div>
  );
}
