import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    backgroundColor: '#1c1b1b',
    padding: 25,
    marginRight: 30,
    borderRadius: 18,
  },
  content: {
    padding: 15,
  },
});
