import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api/rss';

export const getRss = createAsyncThunk('get/rss', async (url) => api.getRssFeed(url));

const initialState = { feed: null, error: null, loading: false };

const rssSlice = createSlice({
  name: 'rss',
  initialState,
  reducers: {
    removeFeed: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRss.fulfilled, (state, action) => {
        state.feed = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getRss.rejected, (state, action) => {
        state.error = action.error;
        state.loading = false;
      })
      .addCase(getRss.pending, (state) => {
        state.loading = true;
      });
  },
});
export const selectRssFeed = state => state.rss;
export const { removeFeed } = rssSlice.actions;
export default rssSlice.reducer;
