/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as api from '../../api/openai';

export const getSuggestions = createAsyncThunk('get/suggestion', async (data) =>
  api.getCompressText(data)
);
export const getAudienceSuggestions = createAsyncThunk('get/audience-suggestion', async (data) =>
  api.getSuggestions({ ...data, type: 'audience' })
);
export const getInterestSuggestions = createAsyncThunk('get/interest-suggestion', async (data) =>
  api.getSuggestions({ ...data, type: 'interests' })
);
export const getLookalikeSuggestions = createAsyncThunk('get/lookalike-suggestion', async (data) =>
  api.getSuggestions({ ...data, type: 'lookalike' })
);

const initialState = {
  titles: null,
  subtitles: null,
  audienceSuggestions: {
    data: null,
    correctList: [],
    loading: false,
  },
  interestSuggestions: {
    data: null,
    correctList: [],
    loading: false,
  },
  lookalikeSuggestions: {
    data: null,
    correctList: [],
    loading: false,
  },
  loading: false,
  error: false,
  compressTextType: null,
  compressTextReload: false,
};

const suggestionSlice = createSlice({
  name: 'suggestion',
  initialState,
  reducers: {
    resetSuggestions: () => initialState,
    setCompprestTextReload: (state, action) => {
      state.compressTextType = action.payload;
      state.compressTextReload = true;
    },
    setCorrectList: (state, { payload: { type, data } }) => {
      state[type].correctList = data;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuggestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSuggestions.fulfilled, (state, action) => {
        if (state.compressTextReload) {
          state.compressTextType === 'title'
            ? (state.titles = action.payload.options.titles)
            : (state.subtitles = action.payload.options.descriptions);
        } else {
          state.subtitles = action.payload.options.descriptions;
          state.titles = action.payload.options.titles;
        }

        state.loading = false;
        state.error = null;
        state.compressTextType = null;
        state.compressTextReload = false;
      })
      .addCase(getSuggestions.rejected, (state, action) => {
        state.error = action.error;
        state.titles = null;
        state.subtitles = null;
        state.loading = false;
        toast.error(action.error.response?.data?.statusText);
      })
      .addCase(getAudienceSuggestions.pending, (state) => {
        state.audienceSuggestions.loading = true;
      })
      .addCase(getAudienceSuggestions.fulfilled, (state, action) => {
        state.audienceSuggestions.data = action.payload;
        state.audienceSuggestions.loading = false;
        state.error = null;
      })
      .addCase(getAudienceSuggestions.rejected, (state, action) => {
        state.error = action.error;
        state.audienceSuggestions.data = null;
        state.audienceSuggestions.loading = false;
        toast.error(action.error.response?.data?.statusText);
      })
      .addCase(getInterestSuggestions.pending, (state) => {
        state.interestSuggestions.loading = true;
      })
      .addCase(getInterestSuggestions.fulfilled, (state, action) => {
        state.interestSuggestions.data = action.payload;
        state.interestSuggestions.loading = false;
        state.error = null;
      })
      .addCase(getInterestSuggestions.rejected, (state, action) => {
        state.error = action.error;
        state.interestSuggestions.data = null;
        state.interestSuggestions.loading = false;
        toast.error(action.error.response?.data?.statusText);
      })
      .addCase(getLookalikeSuggestions.pending, (state) => {
        state.lookalikeSuggestions.loading = true;
      })
      .addCase(getLookalikeSuggestions.fulfilled, (state, action) => {
        state.lookalikeSuggestions.data = action.payload;
        state.lookalikeSuggestions.loading = false;
        state.error = null;
      })
      .addCase(getLookalikeSuggestions.rejected, (state, action) => {
        state.error = action.error;
        state.lookalikeSuggestions.data = null;
        state.lookalikeSuggestions.loading = false;
        toast.error(action.error.response?.data?.statusText);
      });
  },
});
export const selectSuggestion = (state) => state.suggestion;
export const { resetSuggestions, setCompprestTextReload, setCorrectList } = suggestionSlice.actions;
export default suggestionSlice.reducer;
