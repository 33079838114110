import moment from 'moment';

export const types = ['total', 'daily', 'hourly'];

export const periods = [
  {
    label: 'Today',
    value: 'today',
    getTimeInterval: () => ({
      start: moment().startOf('day'),
      end: moment(),
    }),
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'days').startOf('day'),
      end: moment().subtract(1, 'days').endOf('day'),
    }),
  },
  {
    label: 'Last 7 days',
    value: 'week',
    getTimeInterval: () => ({
      start: moment().subtract(7, 'days').startOf('day'),
      end: moment().endOf('day'),
    }),
  },
  {
    label: 'Month to date',
    value: 'currentMonth',
    getTimeInterval: () => ({
      start: moment().startOf('month'),
      end: moment().endOf('day'),
    }),
  },
  {
    label: 'Last month',
    value: 'lastMonth',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'month').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month'),
    }),
  },
  {
    label: 'Last 6 months',
    value: 'lastSixMonth',
    getTimeInterval: () => ({
      start: moment().subtract(6, 'month').add(1, 'days').startOf('day'),
      end: moment().endOf('day'),
    }),
  },
];
