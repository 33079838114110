import { TableCell, TableRow } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
    textTransform: 'none',
    padding: '6px 0',
    justifyContent: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'unset',
    },
    '& span': {
      fontWeight: 400,
    },
  },
  email: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  tHead: {
    fontWeight: 'bold',
    backgroundColor: theme,
  },
  tTableContainer: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tHeader: {
    color: 'grey !important',
    border: 'none',
  },
  tTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  },
  tTablePagination: {
    color: '#fff',
    border: 'none',
  },
  tFirstItem: {
    border: 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  tBodyItem: {
    border: 'none',
  },
  wrapper: {
    paddingRight: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
    },
  },
  title: {
    marginTop: 30,
  },
  iconButton: {
    color: '#fff',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  marcking: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: 'grey',
  },
  redDot: {
    borderRadius: 50,
    backgroundColor: 'red',
  },
  marginBottom: {
    marginBottom: 50,
  },
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  totalImpressions: {
    minWidth: 118,
    maxWidth: 207,
  },
  totalClicks: {
    minWidth: 74,
    maxWidth: 129,
  },
  createdOn: {
    minWidth: 115,
    textAlign: 'left',
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}))(TableRow);
