export const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  title: '',
  salesperson: '',
  stackAdaptAdvertiser: [],
};
