import { createSlice } from '@reduxjs/toolkit';

const rootSlice = createSlice({
  name: 'root',
  initialState: { loading: false, duplicateCampaignGroup: null, duplicateCampaign: null },
  reducers: {
    setDuplicateCampaignGroup: (state, action) => {
      state.duplicateCampaignGroup = action.payload;
    },
    setDuplicateCampaign: (state, action) => {
      state.duplicateCampaign = action.payload;
    },
    removeDuplicateCampaignGroup: (state) => {
      state.duplicateCampaignGroup = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
});

export const selectRoot = state => state.root;

export const {
  setLoading,
  removeCampaignGroups,
  setDuplicateCampaignGroup,
  removeDuplicateCampaignGroup,
  setDuplicateCampaign
} = rootSlice.actions;

export default rootSlice.reducer;
