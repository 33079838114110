import React, { useContext, useState } from 'react';
import { Field, FormikContext } from 'formik';
import cx from 'classnames';
import {
  Button, Dialog, DialogContent, DialogTitle, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Content from '../../../TermConditions/Content';
import { sendVerifyEmail } from '../../../../api/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    height: 400,
    backgroundColor: '#2d2b2c',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#fff',
    overflow: 'scroll',
  },
  btnVerifyEmail: {
    color: '#000',
    backgroundColor: '#f56400',
    padding: '12px 20px',
    '&:hover': {
      backgroundColor: '#ff7414',
    },
  },
}));

export default function UserDetails({
  renderError, hiddenFields = [], disabledFields = [], userData = {},
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const formik = useContext(FormikContext);
  return (
    <>
      <div className="register-step-title">
        User Details
      </div>
      {!hiddenFields.includes('email') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.email })}>
            <div className="label">
              <label>E-mail address</label>
            </div>
            {userData.user ? (
              <Grid container direction="row" item md={12} justifyContent="space-between" alignItems="center">
                <Grid item md={userData.user.isConfirmed || userData.isAdmin ? 12 : 10}>
                  <Field type="email" name="email" disabled={disabledFields.includes('email')} autoComplete="email" onBlur={() => formik.validateField('email')} className="register-form-input" placeholder="Your email here" />
                </Grid>
                {(!userData.user.isConfirmed && !userData.isAdmin) && (
                  <Grid container direction="row" item md={2} justifyContent="flex-end">
                    <Button onClick={sendVerifyEmail} className={classes.btnVerifyEmail}>Verify email</Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Field type="email" name="email" disabled={disabledFields.includes('email')} autoComplete="email" onBlur={() => formik.validateField('email')} className="register-form-input" placeholder="Your email here" />
            )}
            {formik.errors.email && renderError(formik.errors.email)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('password') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.password })}>
            <div className="label">
              <label>Password</label>
            </div>
            <Field type="password" name="password" disabled={disabledFields.includes('password')} autoComplete="new-password" onBlur={() => formik.validateField('password')} className="register-form-input password" placeholder="•••••••••••••" />
            {formik.errors.password && renderError(formik.errors.password)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('confirmPassword') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.confirmPassword })}>
            <div className="label">
              <label>Confirm Password</label>
            </div>
            <Field type="password" name="confirmPassword" disabled={disabledFields.includes('confirmPassword')} autoComplete="new-password" onBlur={() => formik.validateField('confirmPassword')} className="register-form-input password" placeholder="•••••••••••••" />
            {formik.errors.confirmPassword && renderError(formik.errors.confirmPassword)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('firstName') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.firstName })}>
            <div className="label">
              <label>First Name</label>
            </div>
            <Field type="text" name="firstName" disabled={disabledFields.includes('firstName')} onBlur={() => formik.validateField('firstName')} className="register-form-input" placeholder="John" />
            {formik.errors.firstName && renderError(formik.errors.firstName)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('lastName') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.lastName })}>
            <div className="label">
              <label>Last Name</label>
            </div>
            <Field type="text" name="lastName" disabled={disabledFields.includes('lastName')} onBlur={() => formik.validateField('lastName')} className="register-form-input" placeholder="Doe" />
            {formik.errors.lastName && renderError(formik.errors.lastName)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('title') && (
        <div className="register-form-row">
          <div className={cx('register-form-block', { error: formik.errors.title })}>
            <div className="label">
              <label>Title</label>
            </div>
            <Field type="text" name="title" disabled={disabledFields.includes('title')} onBlur={() => formik.validateField('title')} className="register-form-input" placeholder="Your title here" />
            {formik.errors.title && renderError(formik.errors.title)}
          </div>
        </div>
      )}
      {!hiddenFields.includes('terms') && (
        <>
          <div className="register-form-row">
            <div className={cx('register-form-block', { error: formik.errors.terms })}>
              <label className="register-form-checkbox">
                I agree to mowMedia’s <a onClick={() => setOpen(true)}>Terms and Conditions</a>
                <Field
                  type="checkbox"
                  onChange={() => {
                    formik.setFieldValue('terms', !formik.values.terms)
                      .then(() => formik.validateField('terms'));
                  }}
                  name="terms"
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-title">Terms & Conditions</DialogTitle>
            <DialogContent>
              <Content />
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
}
