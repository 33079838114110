import React from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';

import { useStyles } from './styles';

const FieldError = ({ text, withPosition = false }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="caption" className={cx(classes.error, { [classes.withPositionText]: withPosition })}>
        {text}
      </Typography>
      <div className={cx(classes.errorIcon, { [classes.withPositionIcon]: withPosition })}>!</div>
    </>
  );
};

export default FieldError;
