import axiosInterceptor from './axiosInterceptor/index';
import { getHeaders } from '../utils/utils';

export const getAdvertiserById = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/advertisers/${id}`, { headers });
  return res.data;
};

export const updaveAdvertiser = async (data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.patch('/api/advertisers', data, { headers });
  return res.data;
};

export const updaveAdvertiserByAdmin = async (id, data) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.patch(`/api/advertisers/admin/${id}`, data, { headers });
  return res.data;
};

export const getUnblockedAffiliateIds = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/advertisers/${id}/unblocked-affiliate`, { headers });
  return res.data;
};

export const findAllAdvertiserTagRelations = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/advertisers/${id}/tags`, { headers });
  return res.data;
};

export const getOffers = async (id) => {
  const headers = getHeaders();
  const res = await axiosInterceptor.get(`/api/advertisers/${id}/offers`, { headers });
  return res.data;
};
