import { Box, Grid, Typography } from '@mui/material';
import { Field, FormikContext } from 'formik';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import FieldError from '../FieldError';
import CompressText from '../CompressText';
import { selectRssFeed } from '../../redux/slices/rssSlice';

const Title = ({ classes, getSubtitle }) => {
  const {
    values,
    errors,
    validateField,
    setFieldValue,
  } = useContext(FormikContext);
  const { feed } = useSelector(selectRssFeed);
  const { type } = useParams();
  return (
    <>
      <Grid container item md={12} alignItems="center">
        <Typography variant="h5">Title</Typography>
        <Box marginLeft={1}>
          <Typography
            variant="body2"
            className={cx({
              [classes.error]: values.title.length > (type ? 40 : 46),
            })}
          >
            {values.title.length}/{type ? 40 : 46}
          </Typography>
        </Box>
      </Grid>
      <Grid container item md={9} className={classes.inputSection}>
        <Field
          type="text"
          name="title"
          onBlur={() => validateField('title')}
          className="register-form-input"
        />
        {errors.title && <FieldError text={errors.title} withPosition />}
      </Grid>
      <Grid
        container
        item
        md={3}
        alignItems="center"
        justifyContent="space-around"
        className={classes.inputSection}
      >
        <CompressText
          text={getSubtitle}
          originalText={values.episode?.title || feed?.title}
          sidecar={type || ''}
          handleOption={(title) => setFieldValue('title', title)}
          type="title"
        />
      </Grid>
    </>
  );
};

export default Title;
