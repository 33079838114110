import React from 'react';
import { useStyles } from './styles';

const Checkbox = ({ name, value, labelText, checked, onChange }) => {
  const classes = useStyles();
  return (
    <label className={classes.label}>
      <input
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        className={classes.defaulCheckbox}
      />
      <div className={classes.checkboxContainer} />
      <span className={classes.checkboxLabel}>{labelText}</span>
    </label>
  );
};

export default Checkbox;
