import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectStats } from '../../redux/slices/statsSlice';
import { useGettingData } from '../../hooks/useGettingData';

export default function TopStates({ startDate, endDate, stackAdaptId }) {
  const { handleGettingTopStates } = useGettingData();
  const {
    topStates: {
      data: { records },
      loading,
      error,
    },
    sidecar,
  } = useSelector(selectStats);

  useEffect(() => {
    if (!stackAdaptId.length) return;
    handleGettingTopStates({
      ids: stackAdaptId,
      limit: 10,
      startDate,
      endDate,
      sidecar: +sidecar,
      type: 'REGION',
    });
  }, [stackAdaptId, startDate, endDate]);

  const renderTopStates = () => {
    let top;

    if (error) {
      return (
        <Grid container item md={12} justifyContent="center">
          <Button
            onClick={() =>
              handleGettingTopStates({
                ids: stackAdaptId,
                limit: 10,
                startDate,
                endDate,
                sidecar: +sidecar,
                type: 'REGION',
              })}
          >
            Try Again
          </Button>
        </Grid>
      );
    }

    if (!records.length) {
      return (
        <Grid container item md={12} justifyContent="center">
          <Typography variant="h6">No Data</Typography>
        </Grid>
      );
    }

    return records.map(([name, metrics], i) => {
      const { clickConversions, clicks } = metrics;
      const clk = sidecar ? clicks : clickConversions;
      if (i === 0) top = clk;
      const value = Math.round((clk / top) * 100);
      return (
        <Grid key={i} container item md={12} justifyContent="center" alignItems="center">
          <Grid style={{ paddingRight: 15 }} container item md={4} alignItems="center">
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</Typography>
          </Grid>
          <Grid container item md={5} alignItems="center">
            <div
              style={{
                width: `${value}%`,
                height: 10,
                backgroundColor: '#f9a119',
                borderRadius: 7,
                opacity: value / 100,
              }}
            />
          </Grid>
          <Grid container item md={3} justifyContent="flex-end" alignItems="center">
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{clk}</Typography>
          </Grid>
        </Grid>
      );
    });
  };
  return (
    <>
      <Typography style={{ fontSize: 26, color: '#f9a119', fontWeight: 'bold' }}>
        Top 10 States
      </Typography>
      {loading ? (
        <Grid container item md={12} justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        renderTopStates()
      )}
    </>
  );
}
