import { red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  error: {
    color: '#ff1b1b',
    fontWeight: 'bold',
  },
  noMaxWidth: {
    backgroundColor: '#0000003b',
    maxWidth: 'none',
  },
  wrapper: {
    marginBottom: 20,
    padding: '20px 20px 20px 0',
    backgroundColor: '#1c1b1b',
    borderRadius: 18,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
  },
  podcastForm: {
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
  },
  btnSection: {
    padding: '0 27px 25px',
  },
  adIds: {
    paddingBottom: 20,
  },
  button: {
    cursor: 'pointer',
    width: 150,
    height: 50,
    backgroundImage: (props) => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 5,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 14,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  gray: {
    background: (props) => `url("${props.bgBtnGray}") no-repeat`,
  },
  advMenu: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#f9a11a',
    '&:hover': {
      backgroundColor: '#11647f',
    },
    color: '#fff',
  },
  disabledButton: {
    backgroundColor: 'grey',
  },
  label: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
    color: '#fff',
  },
  setupLink: {
    color: 'red',

    '& img': {
      width: 15,
    },
  },
}));
