import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { confirmEmail } from '../../api/auth';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';

export default function ConfirmEmail() {
  const queryParams = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [message, setMessage] = useState('Loading');
  const classes = useStyles();
  const { user } = useSelector(selectLoginedUser);

  useEffect(() => {
    if (queryParams.has('token')) {
      confirmEmail(queryParams.get('token'))
        .then((response) => setMessage(response.message))
        .catch((e) => setMessage(e.response.data.message || e.message));
    }
  }, []);

  return (
    <div className={cx('login-container', classes.wrapper)}>
      <div className="login-row">
        <div className="login-block">
          <Grid container direction="column" item md={12} justifyContent="space-around" alignItems="flex-start">
            <Grid container item md={12} className={classes.message} justifyContent="center" alignItems="center">
              <Typography variant="h5">{message}</Typography>
            </Grid>
            <Grid container item md={12} justifyContent="center" alignItems="center">
              <Button onClick={() => history.push(`/${user ? 'user' : 'login'}`)}>
                <ArrowBackIcon style={{ color: '#fff' }} fontSize="small" />
                <Typography display="block" style={{ marginLeft: '10px' }} align="right">{user ? 'Profile' : 'Login'}</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
