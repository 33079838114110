/* eslint-disable react/button-has-type */
import React, {
  useContext, useEffect,
} from 'react';
import InputMask from 'react-input-mask';
import { Field, FormikContext } from 'formik';
import cx from 'classnames';

export default function BillingDetails({ renderError }) {
  const formik = useContext(FormikContext);

  useEffect(() => {}, []);

  return (
    <>
      <div className="register-step-title">Billing Details</div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.billingName })}>
          <div className="label">
            <label>Finance Contact Name</label>
            {formik.errors.billingName && renderError(formik.errors.billingName)}
          </div>
          <Field
            type="text"
            className="register-form-input"
            onBlur={() => formik.validateField('billingName')}
            name="billingName"
            placeholder="Joe"
            id="firstname"
          />
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.billingEmail })}>
          <div className="label">
            <label>Email </label>
            {formik.errors.billingEmail && renderError(formik.errors.billingEmail)}
          </div>
          <Field
            type="email"
            className="register-form-input"
            name="billingEmail"
            onBlur={() => formik.validateField('billingEmail')}
            placeholder="Your email here"
          />
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.billingPhone })}>
          <div className="label">
            <label>Phone</label>
          </div>
          <InputMask
            mask="+99-999-999-9999"
            name="billingPhone"
            maskChar={null}
            alwaysShowMask={false}
            onChange={(e) => {
              formik.setFieldValue('billingPhone', e.currentTarget.value);
            }}
            onBlur={() => formik.validateField('billingPhone')}
            value={formik.values.billingPhone}
            className="register-form-input"
            placeholder="+1-543-175-4301"
          />
          {formik.errors.billingPhone && renderError(formik.errors.billingPhone)}
        </div>
      </div>
      <div className="register-form-row">
        <div className={cx('register-form-block', { error: formik.errors.emailForInvoices })}>
          <div className="label">
            <label>Email for invoices</label>
            {formik.errors.emailForInvoices && renderError(formik.errors.emailForInvoices)}
          </div>
          <Field
            type="email"
            className="register-form-input"
            name="emailForInvoices"
            onBlur={() => formik.validateField('emailForInvoices')}
            placeholder="Your email here"
          />
        </div>
      </div>
    </>
  );
}
