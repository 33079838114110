/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import bgBtn from '../../assets/images/button.png';
import { useStyles } from './styles';

const SummaryModal = ({
  open,
  handleClose,
  campaignFor,
  podcast,
  campaignGroupId,
  imageUrl,
  renderImages,
  values,
  clickUrl,
}) => {
  const classes = useStyles({ bgBtn });
  const { episode, title, subtitle, start_date, end_date, audience, interests, files } = values;
  const renderSegmentsNames = (segments) =>
    segments.map(({ displayName, name }) => name || displayName).join(', ');
  const hasCustomImages = files.length === 3;

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Typography variant="body1">
          Campaign Created for: <span>{campaignFor}</span>
        </Typography>
        <Typography variant="body1">
          Podcast: <span>{podcast?.podcastName}</span>
        </Typography>
        {episode.title && (
          <Typography variant="body1">
            Episode Title: <span>{episode.title}</span>
          </Typography>
        )}
        <Typography variant="body1">
          Campaign Group ID: <span>{campaignGroupId}</span>
        </Typography>
        <Typography variant="body1">
          Title: <span>{title}</span>
        </Typography>
        <Typography variant="body1">
          Subtitle: <span>{subtitle}</span>
        </Typography>
        {clickUrl && (
          <Typography variant="body1">
            Play Page URL:{' '}
            <a href={clickUrl} target="_blank" rel="noreferrer">
              <span>{clickUrl}</span>
            </a>
          </Typography>
        )}
        {podcast?.podcastBudget && (
          <Typography variant="body1">
            Budget: <span>{podcast?.podcastBudget}</span>
          </Typography>
        )}
        <Typography variant="body1">
          Start Date: <span>{start_date}</span>
        </Typography>
        <Typography variant="body1">
          End Date: <span>{end_date}</span>
        </Typography>
        <Typography variant="body2">
          Targeting Segments: <span>{renderSegmentsNames(audience)}</span>
        </Typography>
        <Typography variant="body2" className={classes.interests}>
          Interest Segments: <span>{renderSegmentsNames(interests)}</span>
        </Typography>
        <Box display="flex">{imageUrl && renderImages(hasCustomImages)}</Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleClose} className={classes.button}>
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SummaryModal;
